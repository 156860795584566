import React from 'react';
import { motion } from 'framer-motion';
import { Button } from './ui/Button';
import { CheckCircle } from 'lucide-react';
import AutoImageSwitcher from './AutoImageSwitcher';

const fadeUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 }
};

const images = [
  "https://nodes.geton.global/bfs/01_bfs.svg", 
  "https://nodes.geton.global/bfs/02_bfs.svg", 
  "https://nodes.geton.global/bfs/03_bfs.svg", 
  "https://nodes.geton.global/bfs/04_bfs.svg", 
  "https://nodes.geton.global/bfs/05_bfs.svg", 
  "https://nodes.geton.global/bfs/06_bfs.svg", 
  "https://nodes.geton.global/bfs/07_bfs.svg", 
  "https://nodes.geton.global/bfs/08_bfs.svg", 
  "https://nodes.geton.global/bfs/09_bfs.svg", 
  "https://nodes.geton.global/bfs/10_bfs.svg", 
  "https://nodes.geton.global/bfs/11_bfs.svg", 
  "https://nodes.geton.global/bfs/12_bfs.svg", 
  "https://nodes.geton.global/bfs/13_bfs.svg", 
  "https://nodes.geton.global/bfs/14_bfs.svg", 
  "https://nodes.geton.global/bfs/15_bfs.svg", 
  "https://nodes.geton.global/bfs/16_bfs.svg", 
];

const daoImages = [
  "https://nodes.geton.global/nodes/verdura.svg",
  "https://nodes.geton.global/nodes/harvest.svg",
  "https://nodes.geton.global/nodes/bloom.svg",
  "https://nodes.geton.global/nodes/flow.svg",
  "https://nodes.geton.global/nodes/sprout.svg",
  "https://nodes.geton.global/nodes/grove.svg",
  "https://nodes.geton.global/nodes/revive.svg",
  "https://nodes.geton.global/nodes/root.svg",
];

export default function BFSExplainer() {
  return (
    <section className="py-12 px-6 dark:bg-bggrey text-gray-800">
      <div className="max-w-6xl mx-auto text-center mb-10">
        <h2 className="text-3xl font-bold text-center mb-4 text-secondary">
          The Future of Community Growth
        </h2>
        <p className="text-center mb-8 text-gray-600 dark:text-gray-100">
          Powered by BFS Auto-Positioning &amp; DAO Governance
        </p>
      </div>

      <motion.div
        className="flex flex-col md:flex-row gap-12 items-center max-w-6xl mx-auto"
        variants={fadeUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.6 }}
      >
        {/* Wrap the image switcher in a green background container */}
        <div className="p-1 bg-baseviolet rounded">
          <AutoImageSwitcher images={images} fixedHeight="h-64 md:h-80" fixedWidth="w-full md:w-3/4 lg:w-1/2" />
        </div>
        <div className="text-center md:text-left dark:text-gray-400 max-w-6xl mx-auto" >
          <h3 className="text-2xl font-semibold text-secondary mb-2">
            🚀 How BFS Positioning Works?
          </h3>
          <p className="mt-2 text-gray-600 dark:text-gray-300 ">
            Our system <strong>automatically places</strong> new members from <strong>left to right</strong>. No referrals, no spillover dependency—just a structured, fair growth system.
          </p>
          <ul className="mt-4 space-y-2">
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span className="leading-tight">
                <strong>Guaranteed Placement</strong>
                <span className="block md:inline">
                  {" "}
                  (No competition for positions)
                </span>
              </span>
            </li>
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span className="leading-tight">
                <strong>Earnings from 6 members</strong>
                <span className="block md:inline">
                  {" "}
                  (2 levels)
                </span>
              </span>
            </li>
          </ul>
        </div>
      </motion.div>

      <motion.div
        className="flex flex-col md:flex-row gap-6 items-center mt-12 max-w-6xl mx-auto"
        variants={fadeUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.6 }}
      >
        <div className="text-center md:text-left dark:text-gray-400">
          <h3 className="text-2xl font-semibold text-secondary mb-2">
            🌍 Why This Model is Revolutionary?
          </h3>
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            Unlike traditional MLMs, our <strong>BFS system ensures structured and automated growth</strong>. No competition, no imbalanced legs, just <strong>community-driven expansion</strong>.
          </p>
          <ul className="mt-4 space-y-2">
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span className="leading-tight">
                <strong>Fully Automated Positioning</strong>
                <span className="block md:inline">
                  {" "}
                  (Through the smart contracts)
                </span>
              </span>
            </li>
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span className="leading-tight">
                <strong>Governed by DAO</strong>
                <span className="block md:inline">
                  {" "}
                  (For unlimited scalability)
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div className="bg-bgsec rounded">
          <AutoImageSwitcher images={daoImages} fixedHeight="h-64 md:h-80" fixedWidth="w-full md:w-3/4 lg:w-1/2" />
        </div>
      </motion.div>

      <div className="mt-10 text-center">
        <Button 
        size="lg">
                  <a 
          href="/dao-weighted" 
          className="px-6 py-2 text-white rounded hover:bg-secondary-dark transition-colors no-underline">
          I want to use BFS in my project
        </a></Button>        
      </div>
    </section>
  );
}
