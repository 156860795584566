import React from 'react';
import { motion } from "framer-motion";
import { Card, CardContent } from "./ui/Card";

const CommunityGrowth = () => {
  return (
    <section className="container mx-auto py-12 px-6">
      <h2 className="text-3xl font-bold text-center mb-6" style={{ color: "var(--secondary)" }}>
        Decentralized Community Growth
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {["Autonomous Expansion", "No Single Point of Control", "Scalable & Adaptive"].map((item, index) => (
          <motion.div 
            key={index} 
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <Card className="p-6 text-center">
              <CardContent>
                <h3 className="text-xl font-semibold" style={{ color: "var(--secondary)" }}>
                  {item}
                </h3>
                <p className="mt-2" style={{ color: "var(--text-color)", opacity: 0.8 }}>
                  {index === 0
                    ? "Users are algorithmically positioned for organic, unstoppable expansion."
                    : index === 1
                    ? "No central authority controls the network—growth happens naturally within the community."
                    : "Our system adapts and scales seamlessly to demand, ensuring sustained community growth."}
                </p>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default CommunityGrowth;
