import React from "react";
import { cn } from "../../lib/utils";

/**
 * A base Button that uses your theme variables
 * but can be overridden by passing `className`.
 */
export function Button({ className, children, ...props }) {
  return (
    <button
      className={cn(
        // Basic styles
        "inline-flex items-center justify-center font-semibold rounded-lg transition-colors",
        "px-4 py-2",
        // Themed background, text, hover effect
        "bg-[var(--secondary)] text-[var(--background)] hover:bg-[var(--accent)]",
        // Optionally, if you'd like a small shadow on button
        // "shadow-sm hover:shadow-md",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
