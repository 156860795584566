// src/components/dao/WeightedProposalForm.jsx
import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseUnits, parseEther } from 'ethers';
import { formatEther } from 'ethers';
import { ERC20_ABI } from "../../abis/erc20";
import WeightedVotingABI from '../../abis/WeightedVoting.json';
import daosettings from '../../config/daosettings';
import governanceImg from '../../governance.png';

const WeightedProposalForm = ({ provider, selectedAccount, isConnected, setActiveTab }) => {
  const fallbackCategory = daosettings.proposalCategoriesWeighted?.[0] || "General";
  const [subject, setSubject] = useState('');
  const [category, setCategory] = useState(fallbackCategory);
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');   // Mandatory reference material link
  const [tokenBalance, setTokenBalance] = useState("0");
  const [proposalFee, setProposalFee] = useState("0");
  const [fixedProposalTokenAmount, setFixedProposalTokenAmount] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    if (!provider) return;
    const fetchContractDetails = async () => {
      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const address = await signer.getAddress();
        const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
        const balanceBN = await tokenContract.balanceOf(address);
        setTokenBalance(balanceBN.toString());
        const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
        const feeBN = await daoContract.proposalFee();
        const propTokenBN = await daoContract.fixedProposalTokenAmount();
        setProposalFee(feeBN.toString());
        setFixedProposalTokenAmount(propTokenBN.toString());
      } catch (error) {
        console.error('Error fetching WeightedVoting details:', error);
      }
    };
    fetchContractDetails();
  }, [provider]);

  const handleApprove = async () => {
    if (!provider) return;
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
      const approvalTx = await tokenContract.approve(
        daosettings.weightedDaoContract,
        fixedProposalTokenAmount
      );
      await approvalTx.wait();
      setIsApproved(true);
    } catch (error) {
      console.error('Error approving tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!provider) return;
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const feeInWei = proposalFee;
      const tx = await daoContract.postProposal(
        subject,
        category,
        description,
        reference, // Pass the reference link
        { value: feeInWei }
      );
      await tx.wait();
      console.log('Weighted proposal submitted!');
      setSubject('');
      setCategory(fallbackCategory);
      setDescription('');
      setReference('');
      if (setActiveTab) {
        setActiveTab("list");
      }
    } catch (error) {
      console.error('Error posting Weighted proposal:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6 p-6 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-800">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">New Weighted Proposal</h2>
      <div className="space-y-2 text-sm">
      <p className="text-gray-700 dark:text-gray-300 mb-2">
          Your balance:
          <img
            src={governanceImg}
            alt="DAO Token"
            className="inline-block w-5 h-5 mx-2"
          />
          <span className="font-semibold">{tokenBalance} DAO</span>
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-2">
          Proposal Fee: <span className="font-semibold">{formatEther(proposalFee)} POL</span>
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Proposal Token Stake:
          <img
            src={governanceImg}
            alt="DAO Token"
            className="inline-block w-5 h-5 mx-2"
          />
          <span className="font-semibold">{fixedProposalTokenAmount} DAO</span>
          <br />
          <small className="text-xs text-gray-500">
            *This fee is returned upon proposal completion, plus a 20% bonus in DAO tokens.
          </small>
        </p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Proposal Title:
          </label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value.slice(0, 50))}
            placeholder="Write a clear proposal title (max 50 characters)."
            maxLength="50"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-800"
          />
        </div>
        <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Category:
          </label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-800"
          >
            {daosettings.proposalCategoriesWeighted?.length > 0 ? (
              daosettings.proposalCategoriesWeighted.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))
            ) : (
              <option value="General">General</option>
            )}
          </select>
        </div>
        <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Proposal Content:
          </label>
          <textarea
            value={description}
            placeholder="Briefly describe your proposal here (max 1000 characters)."
            maxLength="1000"
            onChange={(e) => setDescription(e.target.value)}
            required
            rows="4"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-800"
          />
        </div>
        {/* Mandatory Reference Material Link field */}
        <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Reference Material Link:
          </label>
          <input
            type="url"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            placeholder="https://..."
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-800"
          />
        </div>
        <div className="flex space-x-4">
          {!isApproved ? (
            <button
              type="button"
              onClick={handleApprove}
              disabled={isLoading}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md transition-colors"
            >
              {isLoading ? 'Approving...please wait' : 'Approve Proposal Stake'}
            </button>
          ) : (
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-md transition-colors"
            >
              {isLoading ? 'Submitting...confirm & wait' : 'Submit Proposal'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default WeightedProposalForm;
