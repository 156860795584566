// src/components/GetonWallet.js
import React, { useState } from "react";
import { useAppKitAccount } from "@reown/appkit/react";
import settings from "../config/settings";
import Wallet from "./Wallet";
import AssetTable from "./AssetTable";
import TokenPurchaseModal from "./TokenPurchaseModal";
import DualAxisChart from "./DualAxisChart";

/**
 * Displays tokens from settings.TOKENS (status='active'),
 * each with an input for how many tokens to buy.
 * Clicking "Buy Now" => opens TokenPurchaseModal for purchase logic.
 */
export default function GetonWallet() {
  const { isConnected } = useAppKitAccount(); // Reown connection status
  const [selectedToken, setSelectedToken] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Keep track of how many tokens user wants for each tokenAddress
  const [tokenAmounts, setTokenAmounts] = useState({});

  // Optional page-level loader
  const [isPageLoading, setIsPageLoading] = useState(false);

  // Filter only tokens with 'active' status
  const activeTokens = settings.TOKENS.filter((t) => t.status === "active");

  // Example supply fetch function
  async function fetchAvailableSupply(token) {
    try {
      // Demo: you'd call an API or contract method
      return "12,345,000";
    } catch (err) {
      console.error("Error fetching supply for token:", token.symbol, err);
      return "unknown";
    }
  }

  const handleOpenModal = (token) => {
    setSelectedToken(token);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedToken(null);
  };

  return (
    <section className="relative py-5 px-4 dark:bg-gray-900 text-gray-800">
      {/* Optional page-level loader */}
      {isPageLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Processing...</p>
          </div>
        </div>
      )}

      <Wallet />
      <AssetTable />
      <DualAxisChart />
    </section>
  );
}
