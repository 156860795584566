// src/components/GetonEcosystem.js
import React from "react";
import { motion } from "framer-motion";
import { FaTelegramPlane, FaExternalLinkAlt, FaChevronDown } from "react-icons/fa";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import { Card, CardContent } from "./ui/Card";

const ecosystemItems = [
  {
    title: "Geton Combat",
    description:
      "<ul><li>🔓 <strong>Completing missions</strong></li><li>💰 <strong>Treasure activities</strong></li><li>🪂 <strong>TapCoin Airdrops</strong> <a href='/roadmap-combat' target='_blank' rel='noopener noreferrer'>(Roadmap)</a></li></ul>TapCoins can be migrated and renewed, integrating you into the broader Geton ecosystem.",
    image: "https://nodes.geton.global/ecosystem/coin.png",
    telegram: "https://t.me/metacoincommunity",
    twitter: "https://x.com/GetonGlobal",
    youtube: "https://www.youtube.com/@GetonGlobal",
    more: "/roadmap-combat",
    link: "https://t.me/GetonCombat_bot/?startapp=ref7937598767",
  },
  {
    title: "BFS Structure",
    description:
      "<ul><li>✅ <strong>No Referrals Required</strong></li><li>✅ <strong>Automated Positioning</strong></li><li>✅ <strong>Earnings from 2 Levels</strong></li></ul>Experience transparent and fair earnings with a system designed to benefit every participant.",
    image: "https://nodes.geton.global/ecosystem/bfs.png",
    telegram: "https://t.me/metacoincommunity",
    twitter: "https://x.com/GetonGlobal",
    youtube: "https://www.youtube.com/@GetonGlobal",
    link: "/bfs-explainer",
  },
  {
    title: "GetOnPonzi",
    description:
      "<ul><li>🎮 <strong>Gamified Races</strong></li><li>🔮 <strong>Predict & Win</strong></li><li>😂 <strong>Humorous Engagement</strong></li></ul>Join a meme-driven launchpad where your participation turns into playful crypto adventures and rewarding outcomes.",
    image: "https://nodes.geton.global/ecosystem/ponzi.png",
    telegram: "https://t.me/GetOnPonzi",
    twitter: "https://x.com/GetOnPonzi",
    youtube: "https://www.youtube.com/@GetonGlobal",
    link: "/ponzi",
  },
  {
    title: "Geton DAO",
    description:
      "<ul><li>🗳️ <strong>Decentralized Proposals</strong></li><li>🤝 <strong>Community-Driven Voting</strong></li><li>🔍 <strong>Transparent Outcomes</strong></li></ul>Empower your voice and drive the future of the Geton ecosystem through inclusive and fair decision-making.",
    image: "https://nodes.geton.global/ecosystem/dao.png",
    telegram: "https://t.me/metacoincommunity",
    twitter: "https://x.com/GetonGlobal",
    youtube: "https://www.youtube.com/@GetonDAO",
    more: "https://geton.global/dao",
    link: "/dao",
  },
  {
    title: "GetonWallet",
    description:
      "<ul><li>🔒 <strong>Customized Smart Contracts</strong></li><li>⚡ <strong>Dynamic Reward Modeling</strong></li><li>💡 <strong>DAO-Driven Decisions</strong></li></ul>Experience secure, fair, and dynamic asset management designed with your interests in mind.",
    image: "https://nodes.geton.global/ecosystem/gwallet.png",
    telegram: "https://t.me/metacoincommunity",
    twitter: "https://x.com/GetonGlobal",
    youtube: "https://www.youtube.com/@GetonGlobal",
    link: "/wallet",
  },
  {
    title: "WaveShare",
    description:
      "</strong><ul><li>🏆 <strong>Exclusive Shareholding</strong> (100)</li><li>💸 <strong>Instant Profit Sharing</strong></li><li>🗳️ <strong>Direct Governance</strong></li></ul>Take control of your future with <strong>WaveShare tokens, shape the platform's future and secure your strategic influence.",
    image: "https://nodes.geton.global/ecosystem/wshare.svg",
    telegram: "https://t.me/waveswaps",
    twitter: "https://x.com/waveswaps",
    youtube: "https://www.youtube.com/@GetonGlobal",
    more: "https://www.youtube.com/@GetonGlobal",
    link: "/wshare-dpmc",
  },
];

const GetonEcosystem = () => {
  return (
    <section className="py-20 px-4 mb-3 container mx-auto dark:bg-bggrey text-gray-800">
      <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
        Geton Ecosystem
      </h2>
      <p className="text-center mb-10 text-gray-600">
        Step into a <b>next-generation DeFi ecosystem</b> where you can{" "}
        <b>earn, engage, and govern</b> through gamification, decentralized
        decision-making, and innovative liquidity mechanisms.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {ecosystemItems.map((item, index) => (
          <motion.div key={index} className="w-full h-full">
            <Card className="flex flex-col h-full w-full overflow-hidden bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg shadow">
              <div className="flex items-center p-2 border-b border-gray-300">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-10 h-10 object-contain mr-3"
                />
                <h5 className="text-lg font-semibold text-secondary mb-0">
                  {item.title}
                </h5>
              </div>
              <CardContent className="flex-grow p-2 text-sm text-[var(--text-color)] opacity-80 text-left">
                <div
                  className="prose prose-sm [&_ul]:pl-0"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </CardContent>
              <div className="flex items-center justify-between pt-3 px-3 border-t border-gray-300">
                <div className="flex space-x-4">
                  <a
                    href={item.telegram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300 hover:scale-110"
                  >
                    <FaTelegramPlane className="w-5 h-5" />
                  </a>
                  <a
                    href={item.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300 hover:scale-110"
                  >
                    <FaXTwitter className="w-5 h-5" />
                  </a>
                  <a
                    href={item.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300 hover:scale-110"
                  >
                    <FaYoutube className="w-5 h-5" />
                  </a>
                </div>
                <div className="flex space-x-2 items-center">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300 hover:scale-110"
                  >
                    <FaExternalLinkAlt className="w-4 h-4" />
                  </a>
                  <a
                    href={item.more}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300 hover:scale-110"
                  >
                    <FaChevronDown className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default GetonEcosystem;
