// src/services/api.js

import axios from 'axios';
import settings from '../config/settings';

const API = axios.create({
  baseURL: settings.API_BASE_URL
});

// Automatically attach Authorization: Bearer <token>
API.interceptors.request.use(
  (config) => {
    const savedUser = localStorage.getItem('dappUser');
    if (savedUser) {
      const parsedUser = JSON.parse(savedUser);
      if (parsedUser.token) {
        config.headers.Authorization = `Bearer ${parsedUser.token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/** linkWallet => For ReplaceWalletModal */
export function linkWallet(data) {
  return API.post('/users/link-wallet', data);
}

// ========== Node purchase calls ==========

/**
 * Off-chain burn DB tokens for node purchase
 * POST /nodes/prePurchase
 */
export function prePurchaseNode(payload) {
  return API.post('/nodes/prePurchase', payload);
}

/**
 * (Optional) final step in backend logic
 * POST /nodes/finalize-purchase
 */
export function finalizePurchaseNode() {
  return API.post('/nodes/finalize-purchase');
}

/**
 * Alternatively, to handle node purchase fully in backend
 * POST /nodes/purchase
 */
export function purchaseNode(payload) {
  return API.post('/nodes/purchase', payload);
}

// ========== Transactions ==========

/**
 * Log a transaction in db_transactions (purchase_node, claim_reward, etc.)
 * POST /transactions
 */
export function addTransaction(txData) {
  return API.post('/transactions', txData);
}

export function revertOffChainBurn({ nodeId, assetKey, offChainAmount }) {
  return API.post('/nodes/revert-burn', { nodeId, assetKey, offChainAmount });
}
// ========== Other existing API calls ==========

export function getDashboardData() {
  return API.get('/users/dashboard');
}

export function getUserBalances() {
  return API.get('/balances');
}

export function depositAsset({ assetKey, amount, txHash }) {
  return API.post('/balances/deposit', { assetKey, amount, txHash });
}

export function transferPoints({ assetKey, recipientEmail, amount }) {
  return API.post('/balances/transfer', { assetKey, recipientEmail, amount });
}

export function getTransactions({ page = 1, limit = 10, search = '' }) {
  return API.get('/transactions', { params: { page, limit, search } });
}

export function cancelReferralAsset({ assetKey, amount }) {
  return API.post('/referral/cancel', { assetKey, amount });
}

export function reserveReferralAsset({ assetKey, amount }) {
  return API.post('/referral/reserve', { assetKey, amount });
}

export default API;
