// src/components/HeroSlider.js
import React from "react";
import { Button } from './ui/Button';
import Marquee from "react-fast-marquee";
import ponzisettings from "../config/ponzisettings"; // Import sliderImages from ponzisettings.js

function HeroSlider() {
  const sliderItems = ponzisettings.sliderImages;

  return (
    <div className="py-12 px-4 mx-auto mb-4 mt-3 container mx-auto dark:bg-bggrey text-gray-800">
          <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
          Get-On-Ponzi Race
        </h2>
      <h5
        className="text-xl font-bold text-center mb-4 text-secondary"
        style={{ color: "var(--text-color)" }}
      >
        Meet the{" "}
        <span style={{ color: "var(--highlight-color)", fontWeight: "bold" }}>
          Chaos Engineers
        </span>{" "}
        — the{" "}
        <span
          className="font-bold"
          style={{ color: "var(--accent-color)" }} // or .text-blue-400 etc.
        >
          AI-powered Telegram bots
        </span>{" "}
        turning crypto madness into sharp insights, laced with{" "}
        <span style={{ color: "var(--highlight-color)", fontWeight: "bold" }}>
          humor
        </span>
        ,{" "}
        <span className="text-yellow-300 font-bold">innovation</span>, and{" "}
        <span className="text-purple-400 font-bold">parody</span>.{" "}
        <span className="text-green-500 font-bold">
          What if all is real and there is no chaos?
        </span>
      </h5>

      <Marquee gradient={false} speed={30} pauseOnHover={true} direction="left">
        {sliderItems.map((item, index) => (
          <a
            key={index}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            // Some horizontal spacing so items don't collapse
            className="flex flex-col items-center mx-4"
          >
            <div className="mt-4 flex flex-col items-center">
              {/* Image container */}
              <div className="w-40 h-40 mb-2">
                <img
                  src={item.src}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-contain rounded-lg hover:scale-110"
                  style={{ backgroundColor: "var(--card-background)" }}
                />
              </div>
              {/* Name/label under the image */}
              <div
                className="text-center font-normal"
                style={{ color: "grey-300" }}
              >
                {item.name}
              </div>
            </div>
          </a>
        ))}
      </Marquee>

     
    </div>
  );
}

export default HeroSlider;
