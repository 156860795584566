import React from "react";
import { cn } from "../../lib/utils";

/**
 * A base Input field styled with Tailwind.
 * Uses the `cn` utility for theme support.
 */
export function Input({ className, ...props }) {
  return (
    <input
      className={cn(
        "w-full px-4 py-2 text-gray-900 bg-white border rounded-lg shadow-sm",
        "focus:ring-2 focus:ring-[var(--secondary)] focus:outline-none",
        "dark:bg-gray-800 dark:text-white dark:border-gray-600",
        className
      )}
      {...props}
    />
  );
}

export default Input;
