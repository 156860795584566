// src/components/dao/StandardVotingForm.js
import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseEther } from 'ethers';
import { ERC20_ABI } from "../../abis/erc20";
import governanceImg from '../../governance.png';
import StandardVotingABI from '../../abis/StandardVoting.json';
import daosettings from '../../config/daosettings';
import { BiLike, BiDislike } from "react-icons/bi";
import { formatEther } from 'ethers';

const StandardVotingForm = ({ provider, proposal, onVote }) => {
  const [votingFee, setVotingFee] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [fixedVotingTokenAmount, setFixedVotingTokenAmount] = useState('0');
  const [rewardPercentage, setRewardPercentage] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [vote, setVote] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [proposer, setProposer] = useState('');
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    if (!proposal) return;
    setProposer(proposal.proposer || '0x0');
  }, [proposal]);

  useEffect(() => {
    const fetchVotingDetails = async () => {
      if (!provider || !proposal) return;
      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const userAdd = await signer.getAddress();
        setUserAddress(userAdd);

        const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
        const balance = await tokenContract.balanceOf(userAdd);
        setTokenBalance(balance.toString());

        const daoContract = new Contract(daosettings.standardDaoContract, StandardVotingABI, signer);
        const vf = await daoContract.votingFee();
        setVotingFee(vf.toString());

        const fvt = await daoContract.fixedVotingTokenAmount();
        setFixedVotingTokenAmount(fvt.toString());

        const rp = await daoContract.rewardPercentage();
        setRewardPercentage(rp.toString());

        if (daoContract.getVoterHistory && typeof proposal.index === 'number') {
          const hist = await daoContract.getVoterHistory(proposal.index);
          if (hist && hist[0]) {
            setHasVoted(hist[0].includes(userAdd));
          } else {
            setHasVoted(false);
          }
        }
      } catch (err) {
        console.error("Error fetching standard voting details:", err);
      }
    };

    fetchVotingDetails();
  }, [provider, proposal]);

  const handleApprove = async () => {
    try {
      setIsLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
      const tx = await tokenContract.approve(daosettings.standardDaoContract, fixedVotingTokenAmount);
      await tx.wait();
      setIsApproved(true);
    } catch (error) {
      console.error('Error approving tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitVote = async (e) => {
    e.preventDefault();
    if (!provider) {
      alert("No provider");
      return;
    }
    if (!vote) {
      alert("Please choose yes/no");
      return;
    }
    try {
      setIsLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.standardDaoContract, StandardVotingABI, signer);
      const feeInNative = parseEther(formatEther(votingFee.toString()));
      const voteBool = vote === 'yes';
      const tx = await daoContract.vote(proposal.index, voteBool, { value: feeInNative });
      await tx.wait();
      setHasVoted(true);
      if (onVote) onVote();
    } catch (err) {
      console.error('Error submitting vote:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const isProposer = userAddress && proposer && userAddress.toLowerCase() === proposer.toLowerCase();

  if (isProposer) {
    return <p className="text-sm text-gray-500">You are the proposal owner; you cannot vote on your own proposal.</p>;
  }
  if (hasVoted) {
    return <p className="text-sm text-gray-500">You have already voted on this proposal.</p>;
  }

  return (
    <div className="p-4 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 rounded shadow">
      <h4 className="text-lg font-semibold mb-4">Vote on Proposal</h4>
      <div className="flex items-center space-x-2 text-sm mb-2">
        <span>Your balance:</span>
        <img src={governanceImg} alt="DAO Token" className="w-5 h-5" />
        <span className="font-semibold">{tokenBalance} DAO</span>
      </div>
      <p className="text-sm mb-1">Voting Fee: {formatEther(votingFee || '0')} POL</p>
      <p className="text-sm mb-4">
        Token Lock: {fixedVotingTokenAmount} DAO (+{rewardPercentage}% bonus after completion)
      </p>
      <form onSubmit={handleSubmitVote} className="space-y-4">
        <div>
          <label className="block mb-1 text-sm font-medium">Choose your vote:</label>
          <div className="flex items-center space-x-6">
            <label className="flex items-center">
              <input type="radio" value="yes" checked={vote === 'yes'} onChange={() => setVote('yes')} className="mr-2" />
              <BiLike className="text-green-600 mr-1" /> Yes
            </label>
            <label className="flex items-center">
              <input type="radio" value="no" checked={vote === 'no'} onChange={() => setVote('no')} className="mr-2" />
              <BiDislike className="text-red-600 mr-1" /> No
            </label>
          </div>
        </div>
        {!isApproved ? (
          <button
            type="button"
            onClick={handleApprove}
            disabled={isLoading || !vote}
            className="w-full py-2 px-4 bg-gray-600 hover:bg-gray-700 text-white rounded shadow"
          >
            {isLoading ? 'Approving...' : 'Approve Lock'}
          </button>
        ) : (
          <button
            type="submit"
            disabled={isLoading || !vote}
            className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded shadow"
          >
            {isLoading ? 'Submitting...' : 'Submit Vote'}
          </button>
        )}
      </form>
    </div>
  );
};

export default StandardVotingForm;
