// src/pages/Ponzi.js
/**
 * Ponzi.js
 * A separate page for your "multi-race meme mania" environment.
 * This version automatically prompts for connection/network switching,
 * and includes interactive elements like the accordion, hero slider, and race cards.
 */

import React, { useState, useEffect } from "react";
import { BrowserProvider } from "ethers";

// Local components
import HeroSlider from "../components/HeroSlider";
import DualAxisChart from "../components/DualAxisChart";
import TokenPurchaseModal from "../components/TokenPurchaseModal";
import Chart from "../components/Chart";
import PonziRaceCard from "../components/PonziRaceCard";

// Reown / Web3 imports
import { useAppKitAccount, useAppKitProvider, getAppKit } from "@reown/appkit/react";

// ponzisettings config
import ponzisettings from "../config/ponzisettings";

// Additional styles (if needed)
import "../styles/Ponzi.css";
import "../styles/BlinkingButton.css";

function Ponzi() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Reown hooks: Are we connected? What's our wallet provider?
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  // Base Mainnet chain parameters
  const BASE_NETWORK_PARAMS = {
    chainId: "0x2105", // Base mainnet in hex (8453 decimal)
    chainName: "Base Mainnet",
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  };

  // On page load: check connection and network
  useEffect(() => {
    handlePageVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handlePageVisit() {
    try {
      if (!isConnected) {
        const appKit = getAppKit();
        if (!appKit) {
          console.error("Reown AppKit not found or not initialized.");
          return;
        }
        appKit.open();
      } else {
        await ensureBaseNetwork();
      }
    } catch (err) {
      console.error("Failed to handle page visit:", err);
    }
  }

  async function ensureBaseNetwork() {
    if (!walletProvider) {
      console.error("No walletProvider from Reown, cannot switch chain automatically.");
      return;
    }

    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      const baseChainIdDecimal = parseInt(BASE_NETWORK_PARAMS.chainId, 16);

      if (network.chainId !== baseChainIdDecimal) {
        console.log("User is not on Base mainnet, attempting chain switch...");
        try {
          await walletProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
          });
        } catch (error) {
          console.error("Failed to switch chain programmatically:", error);
          alert("Please switch to Base Mainnet in your wallet to use this page.");
        }
      } else {
        console.log("User is connected and on Base mainnet.");
      }
    } catch (err) {
      console.error("Error ensuring Base network:", err);
    }
  }

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  async function checkAndSwitchNetwork() {
    if (!isConnected) {
      const appKit = getAppKit();
      if (!appKit) {
        console.error("Reown AppKit not found or not initialized.");
        return;
      }
      appKit.open();
      return;
    }

    try {
      if (!walletProvider) {
        console.error("No walletProvider from Reown, cannot switch chain.");
        setIsModalOpen(true);
        setIsClicked(true);
        return;
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      const baseChainIdDecimal = parseInt(BASE_NETWORK_PARAMS.chainId, 16);

      if (network.chainId !== baseChainIdDecimal) {
        try {
          await walletProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
          });
        } catch (error) {
          console.error("Failed to switch chain:", error);
          alert("Please switch to Base Mainnet in your wallet.");
        }
      }
      setIsModalOpen(true);
      setIsClicked(true);
    } catch (error) {
      console.error("Error checking/switching network:", error);
      alert("Failed to open purchase flow. Please try again.");
    }
  }

  return (
    <div className="min-h-screen bg-bggrey">
      <main>
        <div className="container mx-auto py-2 px-4">
          <HeroSlider />
          <section className="text-center mb-6">
          <div className="text-center text-white">
            <a href="https://getonponzi.com/" target="_blank" rel="noopener noreferrer">
            <button 
              className="mx-2 px-3 py-2 mb-3 w-72 bg-baseviolet border-baseviolet btn-item rounded hover:bg-basegreen" >
              Visit race on GetOnPonzi.com →
          </button> </a>
          </div>
            <h2 className="text-2xl font-bold text-gray-300">
              Discover the{" "}
              <span className="font-bold" style={{ color: "#e922ff" }}>
                PonziRace
              </span>
            </h2>
          </section>

          <section className="mb-8">
            <h3 className="text-center text-xl font-semibold text-green-700 mb-4">
              <span className="font-bold">Pick your meme-fueled steed</span> and dive headfirst into the absurd!
            </h3>
            <p className="text-center text-lg text-blue-600 mb-6">
              Each token comes with its own ridiculous backstory, questionable stakes, and a pot full of chaos.
              <br />
              When the Jokers max out, fate takes the reins—winners are crowned, losers cry, and logic takes a holiday.
              <br />
              <span className="font-bold" style={{ color: "#e922ff" }}>
                Bet wildly, laugh harder, and let the meme gods decide your destiny!
              </span>
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              {ponzisettings.ponziRaces.map((raceConf, index) => (
                <PonziRaceCard key={index} raceConfig={raceConf} />
              ))}
            </div>
          </section>

          <div className="text-center my-8">
            <button
              className={`px-6 py-3 rounded font-semibold text-white transition-colors ${
                !isClicked ? "bg-blue-600 animate-pulse" : "bg-blue-700 hover:bg-blue-800"
              }`}
              onClick={checkAndSwitchNetwork}
            >
              Buy GetOnPonzi & Claim Reward 💰
            </button>
          </div>

          <TokenPurchaseModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      </main>
    </div>
  );
}

export default Ponzi;
