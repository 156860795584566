
const SETTINGS = {

    daoToken:"0x8053563b0678a2Cd900BAbc8756cC55e25685215",
    standardDaoContract:"0xc4F1ef10CE8218F62230b7f172dD4AccC4B160E2",
    weightedDaoContract:"0xa4343008A52bE99Cf387F5805a01BF19D965d666",
    daoTokenSymbol: "DAO",

    RPC_URLS: {
        // Binance Smart Chain
        56: "https://bsc-dataseed.binance.org/",
      
        // Polygon (Mainnet) - using Infura with your project ID
        137: `https://polygon-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,
      
        // Optimism (Mainnet) - using Infura with your project ID
        10: `https://optimism-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,
      
        // Arbitrum One (Mainnet) - using Infura with your project ID
        42161: `https://arbitrum-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`
      },
      
    // DAO voting
    proposalCategories: [
      "Platform Development & Innovation", 
      "Community & Outreach", 
      "Ecosystem Initiatives & Growth", 
      "Education & Capacity Building",
    ],
    proposalCategoriesWeighted: [
      "Tokenomics & Supply Management",
      "Blockchain Expansion & & BFS Integration",
      "Governance & Strategic Policies",
      "Financial Strategy & Capital Allocation",
    ],


};

export default SETTINGS;