// src/components/WSHAREPurchaseModal.js
import React, { useState, useEffect } from "react";
import { Contract, parseEther, BrowserProvider, formatEther } from "ethers";
import Modal from "./Modal"; // Your custom Tailwind-styled modal
import { Button } from "./ui/Button";
import Input from "./ui/Input";
import { contractAddress, chainId, tokenInfo } from "../config/wshare";
import wshareAbi from "../abis/wshareAbi";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import copyIcon from "../assets/copy.svg";
import metamaskIcon from "../assets/metamask.svg";

const WSHAREPurchaseModal = ({ isOpen, onClose }) => {
  const [wshareAmount, setWshareAmount] = useState("");
  const [bnbAmount, setBnbAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [transactionHash, setTransactionHash] = useState(null);

  // New state to hold on-chain global stats: current price and last purchase price
  const [globalStats, setGlobalStats] = useState({
    currentPrice: "",
    lastPurchasePrice: ""
  });

  const { walletProvider } = useAppKitProvider("eip155");
  const account = useAppKitAccount();

  // Use tokenInfo from config as fallback data
  const token = tokenInfo;

  // Fetch current global stats from the smart contract on mount or when walletProvider changes
  useEffect(() => {
    const fetchGlobalStats = async () => {
      if (!walletProvider) return;
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(contractAddress, wshareAbi, signer);
        // Assuming getGlobalStats returns an array with [currentPrice, lastPurchasePrice, ...]
        const [curPrice, lastPrice] = await contract.getGlobalStats();
        setGlobalStats({
          currentPrice: formatEther(curPrice),
          lastPurchasePrice: formatEther(lastPrice)
        });
      } catch (error) {
        console.error("Error fetching global stats:", error);
      }
    };
    fetchGlobalStats();
  }, [walletProvider]);

  // Calculate price per token using on-chain current price if available, else fallback to config
  const getPrice = () => {
    return parseFloat(globalStats.currentPrice) || parseFloat(token.stablePrice) || 0;
  };

  // Update required native token amount based on entered WaveShare amount and fetched price
  const handleWshareChange = (e) => {
    const amount = e.target.value;
    setWshareAmount(amount);
    const price = getPrice();
    setBnbAmount((parseFloat(amount) * price).toFixed(6));
  };

  // Connect wallet if not connected
  const connectWallet = async () => {
    try {
      if (walletProvider && walletProvider.connect) {
        await walletProvider.connect();
      }
    } catch (error) {
      console.error("Wallet connection error:", error);
    }
  };

  // Switch network to the required chain (Polygon in this case)
  const switchNetwork = async () => {
    if (!walletProvider) return;
    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } catch (error) {
      console.error("Error switching network:", error);
      throw error;
    }
  };

  // Add token to MetaMask
  const addToMetaMask = async () => {
    if (!window.ethereum) {
      alert("MetaMask not found. Cannot auto-add token.");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token.contractAddress,
            symbol: token.symbol,
            decimals: token.decimals || 18,
            image: token.logo || "",
          },
        },
      });
      alert(`${token.symbol} was added to MetaMask!`);
    } catch (err) {
      console.error("Error adding token:", err);
      alert("Failed to add token to MetaMask.");
    }
  };

  // Calculate total cost (in native currency) of the purchase
  const calculateTotalCost = () => {
    const qty = parseFloat(wshareAmount) || 0;
    const price = getPrice();
    return qty * price;
  };

  // Main function to handle purchase
  const purchaseWshare = async () => {
    if (!account?.address) {
      alert("Please connect your wallet first!");
      return;
    }
    if (!wshareAmount || parseFloat(wshareAmount) <= 0) {
      alert("Please enter a valid amount of WaveShare to purchase.");
      return;
    }

    try {
      setLoading(true);
      setErrorMessage("");
      setTransactionHash(null);

      if (!walletProvider) {
        throw new Error("Wallet provider is not available.");
      }

      // Create an ethers provider from walletProvider
      let ethersProvider = new BrowserProvider(walletProvider);
      let signer = await ethersProvider.getSigner();
      if (!signer) {
        throw new Error("Signer is not available.");
      }

      // Ensure the user is on the correct network
      const currentNet = await ethersProvider.getNetwork();
      const currentChainId = "0x" + currentNet.chainId.toString(16).toLowerCase();
      if (currentChainId !== chainId.toLowerCase()) {
        try {
          await switchNetwork();
          ethersProvider = new BrowserProvider(walletProvider);
          signer = await ethersProvider.getSigner();
        } catch (switchErr) {
          console.error("Chain switch error:", switchErr);
          alert("Please switch your wallet to the correct network.");
          setLoading(false);
          return;
        }
      }

      // Call the purchaseWSHARE function on the contract
      const contract = new Contract(contractAddress, wshareAbi, signer);
      const tx = await contract.purchaseWSHARE(parseEther(wshareAmount), {
        value: parseEther(bnbAmount),
      });

      await tx.wait();
      setTransactionHash(tx.hash);
      alert(`WaveShare Purchase Successful! Transaction Hash: ${tx.hash}`);
      setWshareAmount("");
      setBnbAmount("");
      onClose();
    } catch (error) {
      setErrorMessage(error.message || "Transaction failed.");
      console.error("Transaction error:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalCost = calculateTotalCost();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden w-full">
        {loading && (
          <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75">
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
              <p className="mt-4 text-white">Processing purchase...</p>
            </div>
          </div>
        )}

        <div className="p-6 space-y-4 relative">
          <h4 className="text-center text-yellow-400 font-bold">
            Buy {token.name} ({token.symbol})
          </h4>
          <p className="text-center text-sm">
            Network: <strong>{token.chainName}</strong>
          </p>
          <hr className="border-gray-300" />

          {/* Token Contract Address */}
          <div>
            <strong className="block text-sm">Token Contract Address:</strong>
            <div className="flex items-center justify-between mt-1">
              <span className="text-sm break-all w-4/5">
                {`${token.contractAddress.slice(0, 12)}..........${token.contractAddress.slice(-12)}`}
              </span>
              <div className="flex space-x-4">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(token.contractAddress);
                    alert(`Copied to clipboard: ${token.contractAddress}`);
                  }}
                  className="cursor-pointer"
                >
                  <img src={copyIcon} alt="Copy" className="w-5 h-5" />
                </button>
                <button onClick={addToMetaMask} className="cursor-pointer">
                  <img src={metamaskIcon} alt="Add to MetaMask" className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Purchase Summary */}
          <div className="text-center text-sm">
            <p>
              You want: <strong>{wshareAmount}</strong> {token.symbol}
            </p>
            <p>
              Price per token:{" "}
              <strong>
                {globalStats.currentPrice ? globalStats.currentPrice : token.stablePrice}
              </strong>{" "}
              {token.nativeSymbol}
            </p>
            {globalStats.lastPurchasePrice && (
              <p>
                Last Purchase Price:{" "}
                <strong>{globalStats.lastPurchasePrice}</strong> {token.nativeSymbol}
              </p>
            )}
            <p>
              Total cost:{" "}
              <strong>{totalCost.toFixed(4)} {token.nativeSymbol}</strong>
            </p>
          </div>

          {/* Purchase Inputs */}
          <div className="mt-4">
            <label className="text-sm text-gray-600">Enter WaveShare Amount:</label>
            <Input
              type="number"
              value={wshareAmount}
              onChange={handleWshareChange}
              placeholder="Amount of WaveShare"
              className="mt-2 w-full"
            />
          </div>

          <div className="mt-4">
            <label className="text-sm text-gray-600">
              Required {token.nativeSymbol}:
            </label>
            <Input
              type="text"
              value={bnbAmount}
              disabled
              className="mt-2 w-full bg-gray-100"
            />
          </div>

          <div className="flex items-center justify-center">
            <Button
              onClick={purchaseWshare}
              disabled={
                loading ||
                !account?.address ||
                !wshareAmount ||
                parseFloat(wshareAmount) <= 0
              }
              className="mt-6 w-full"
            >
              {loading ? "Processing..." : "Buy WaveShare"}
            </Button>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-center mt-2 break-all">{errorMessage}</p>
          )}

          {transactionHash && (
            <p className="text-xs text-center mt-2 break-all text-green-400">
              <strong>Transaction Hash:</strong>
              <br />
              {transactionHash}
            </p>
          )}

          <p className="text-xs text-center text-gray-500 mt-2">
            Your purchased tokens will arrive once the transaction confirms.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default WSHAREPurchaseModal;
