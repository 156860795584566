// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { BrowserProvider } from "ethers";  // <-- from ethers v6

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SETTINGS from "./config/daosettings";
import "./styles/tailwind.css";
import "./App.css";

// Pages
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Ecosystem from "./pages/Ecosystem";
import Transactions from "./pages/Transactions";
import NodeStore from "./pages/NodeStore";
import Ponzi from "./pages/Ponzi";
import DAO from "./pages/DAO";
import SwappingBots from "./pages/SwappingBots";

// DAO pages
import StandardVoting from "./components/dao/StandardVoting";
import WeightedVoting from "./components/dao/WeightedVoting";

// Ecosystem components
import HeroSection from "./components/HeroSection";
import BFSExplainer from "./components/BFSExplainer";
import CommunityGrowth from "./components/CommunityGrowth";
import BFSAutomation from "./components/BFSAutomation";
import Web3Transition from "./components/Web3Transition";
import FeaturesSection from "./components/FeaturesSection";
import GetonEcosystem from "./components/GetonEcosystem";
import SectorSection from "./components/SectorSection";
import FAQSection from "./components/FAQSection";
import GetonWallet from "./components/GetonWallet";
import Staking from "./components/Staking";
import CrossChain from "./components/CrossChain";
import SwapV3 from "./components/SwapV3";
import SwapV1 from "./components/SwapV1";
import Tokens from "./components/Tokens";
import Supplies from "./components/Supplies";
import StakeWaveCoin from "./components/StakeWaveCoin";
import WSharePieChart from "./components/WSharePieChart";
import WSHAREPurchaseModal from "./components/WSHAREPurchaseModal";
import ShareholdingDashboard from "./components/ShareholdingDashboard";
import RoadmapSection from "./components/RoadmapSection";

function App() {
  // EIP-1193-style provider from window.ethereum
  const [rawProvider, setRawProvider] = useState(null);

  // Basic account info
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Track chain info
  const [networkId, setNetworkId] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [nativeCurrencySymbol, setNativeCurrencySymbol] = useState("");

  // We'll create an ethers "signer" once we have a valid rawProvider & account
  const [signer, setSigner] = useState(null);

  // On component mount, check window.ethereum
  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      setRawProvider(window.ethereum);

      // Check for already connected accounts
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length > 0) {
            setSelectedAccount(accounts[0]);
            setIsConnected(true);
          }
        })
        .catch((err) => console.error("Error fetching accounts:", err));

      // Listen for account changes
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setSelectedAccount(accounts[0]);
          setIsConnected(true);
        } else {
          setSelectedAccount(null);
          setIsConnected(false);
        }
      });

      // Fetch network info
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((chainIdHex) => {
          const chainId = parseInt(chainIdHex, 16);
          setNetworkId(chainId.toString());

          const chainNames = {
            1: "Ethereum Mainnet",
            3: "Ropsten",
            4: "Rinkeby",
            5: "Goerli",
            42: "Kovan",
            56: "BNB Smart Chain",
            137: "Polygon",
            10: "Optimism",
            42161: "Arbitrum One",
          };
          setNetworkName(chainNames[chainId] || "Unknown Network");
        })
        .catch((err) => console.error("Error fetching chainId:", err));
    } else {
      console.warn("No window.ethereum found. Please install MetaMask!");
    }
  }, []);

  // Whenever we have a rawProvider + selectedAccount, create an ethers v6 Signer
  useEffect(() => {
    async function setupSigner() {
      if (rawProvider && selectedAccount) {
        try {
          const provider = new BrowserProvider(rawProvider);
          // getSigner() => The user's first account in MetaMask
          // If you want the EXACT selectedAccount, pass it to getSigner(selectedAccount) in v6
          const newSigner = await provider.getSigner();
          setSigner(newSigner);
        } catch (err) {
          console.error("Error creating signer:", err);
        }
      } else {
        setSigner(null);
      }
    }
    setupSigner();
  }, [rawProvider, selectedAccount]);

  // Function to switch network
  async function switchNetwork(chainId) {
    if (rawProvider) {
      try {
        await rawProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + parseInt(chainId).toString(16) }],
        });
        // After switching, update network info
        const chainIdHex = await rawProvider.request({ method: "eth_chainId" });
        const newChainId = parseInt(chainIdHex, 16);

        setNetworkId(newChainId.toString());
        const chainNames = {
          1: "Ethereum Mainnet",
          3: "Ropsten",
          4: "Rinkeby",
          5: "Goerli",
          42: "Kovan",
          56: "BNB Smart Chain",
          137: "Polygon",
          10: "Optimism",
          42161: "Arbitrum One",
        };
        setNetworkName(chainNames[newChainId] || "Unknown Network");
      } catch (error) {
        console.error("Error switching network:", error);
      }
    }
  }

  // Aliases for clarity
  const walletProvider = rawProvider;
  const address = selectedAccount;

  return (
    <UserProvider>
      <Router>
        <div className="relative min-h-screen">
          <Navbar />

          {/* Page content (with navbar offset) */}
          <div className="pt-16 pb-24">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/hero" element={<HeroSection />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/ecosystem" element={<Ecosystem />} />
              <Route path="/sectors" element={<SectorSection />} />
              <Route path="/features" element={<FeaturesSection />} />
              <Route path="/faq" element={<FAQSection />} />
              <Route path="/wallet" element={<GetonWallet />} />
              <Route path="/geton-ecosystem" element={<GetonEcosystem />} />
              <Route path="/bfs-explainer" element={<BFSExplainer />} />
              <Route path="/community-growth" element={<CommunityGrowth />} />
              <Route path="/bfs-automation" element={<BFSAutomation />} />
              <Route path="/web3-transition" element={<Web3Transition />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/nodes" element={<NodeStore />} />
              <Route path="/ponzi" element={<Ponzi />} />
              <Route path="/dao" element={<DAO />} />
              <Route path="/staking" element={<Staking />} />
              <Route path="/tokens" element={<Tokens />} />
              <Route path="/supplies" element={<Supplies />} />
              <Route path="/swapping" element={<SwappingBots />} />
              <Route path="/stake-wave" element={<StakeWaveCoin />} />
              <Route path="/wshare-pie" element={<WSharePieChart />} />
              <Route path="/roadmap-combat" element={<RoadmapSection />} />
              <Route
                path="/buy-wshare"
                element={<WSHAREPurchaseModal isOpen={true} onClose={() => {}} />}
              />
              
              {/* <-- The key route for your new shareholding system */}
              <Route
                path="/wshare-dpmc"
                element={<ShareholdingDashboard signer={signer} />}
              />

              {/* Cross-Chain route using Tailwind */}
              <Route
                path="/cross-chain"
                element={
                  <div className="container mx-auto pb-5">
                    <div className="mb-[300px]">
                      <CrossChain
                        isFirstSite={false}
                        provider={walletProvider}
                        account={address}
                        isConnected={isConnected}
                        networkId={networkId}
                        switchNetwork={switchNetwork}
                        networkName={networkName}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/swap-v1"
                element={
                  <div className="container mx-auto pb-5">
                    <div className="mb-[300px]">
                      <SwapV1
                        provider={walletProvider}
                        selectedAccount={address}
                        networkSymbol={nativeCurrencySymbol}
                        isConnected={isConnected}
                        networkName={networkName}
                        networkId={networkId}
                        switchNetwork={switchNetwork}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/swap-v3"
                element={
                  <div className="container mx-auto pb-5">
                    <div className="mb-[300px]">
                      <SwapV3
                        provider={walletProvider}
                        selectedAccount={address}
                        networkSymbol={nativeCurrencySymbol}
                        isConnected={isConnected}
                        networkName={networkName}
                        networkId={networkId}
                        switchNetwork={switchNetwork}
                      />
                    </div>
                  </div>
                }
              />

              {/* DAO Voting Routes */}
              <Route
                path="/dao-standard"
                element={
                  <StandardVoting
                    provider={rawProvider}
                    selectedAccount={selectedAccount}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path="/dao-weighted"
                element={
                  <WeightedVoting
                    provider={rawProvider}
                    selectedAccount={selectedAccount}
                    isConnected={isConnected}
                  />
                }
              />
            </Routes>
          </div>

          <Footer />
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
