import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  JsonRpcProvider,
} from "ethers";
import { ERC20_ABI } from "../abis/erc20";
import BridgeABI from "../abis/BridgeABI.json";
import SETTINGS from "../config/crosschain";

const CrossChain = ({
  provider,
  account,
  isConnected,
  networkName,
  networkId,
  switchNetwork,
  isFirstSite,
}) => {
  const [loading, setLoading] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const [amountToBridge, setAmountToBridge] = useState("");
  const [sourceToken, setSourceToken] = useState("");
  const [targetToken, setTargetToken] = useState("");
  const [targetNetwork, setTargetNetwork] = useState("");
  const [sourceNetworkNativeBalance, setSourceNetworkNativeBalance] = useState("");
  const [targetNetworkNativeBalance, setTargetNetworkNativeBalance] = useState("");
  const [targetTokenContractBalance, setTargetTokenContractBalance] = useState("");
  const [sourceTokenUsdtValue, setSourceTokenUsdtValue] = useState("");
  const [targetTokenAmount, setTargetTokenAmount] = useState("");
  const [txHash, setTxHash] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const [tokensWithBalances, setTokensWithBalances] = useState({});
  const [bridgeTokensWithBalances, setBridgeTokensWithBalances] = useState({});
  const [step, setStep] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const navigate = useNavigate();

  const openLink = (_link) => {
    navigate(_link);
  };

  // Fees and native symbols for various networks
  const bridgeFees = {
    10: "110000000000000",
    137: "850000000000000000",
    56: "470000000000000",
    42161: "110000000000000",
  };
  const nativeSymbols = {
    10: "ETH",
    137: "POL",
    56: "BNB",
    42161: "ETH",
  };

  // Get the account's native balance using ethers
  const getBalance = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const balanceWei = await ethersProvider.getBalance(account);
      const balanceEth = formatUnits(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  // Set the target network information and fetch token balances from that network
  const setTargetNetworkFunction = async (_networkId) => {
    setTargetNetwork(_networkId);
    const bridgeContractAddress = SETTINGS.BRIDGE_CONTRACTS[parseInt(_networkId)];
    if (!bridgeContractAddress) {
      alert("Invalid bridge contract address");
      return;
    }
    const targetRpcUrl = SETTINGS.RPC_URLS[parseInt(_networkId)];
    if (!targetRpcUrl) {
      alert("Invalid RPC URL for the target network");
      return;
    }
    const ethersProvider = new JsonRpcProvider(targetRpcUrl);
    try {
      const nativeBalance = await ethersProvider.getBalance(account);
      const formattedNativeBalance = parseFloat(formatUnits(nativeBalance, 18)).toFixed(6);
      setTargetNetworkNativeBalance(formattedNativeBalance);
    } catch (error) {
      console.error("Error fetching native balance:", error);
      setTargetNetworkNativeBalance("0.000000");
    }
    let tokensWithBalancesDict = {};
    const balancePromises = Object.keys(SETTINGS.PRICES).map(async (key) => {
      let tokenName;
      try {
        const value = SETTINGS.PRICES[key];
        tokenName = value.name;
        const tokenAddress = SETTINGS.TOKEN_ADDRESSES[tokenName][parseInt(_networkId)];
        if (!tokenAddress) {
          return;
        }
        const TokenContract = new Contract(tokenAddress, ERC20_ABI, ethersProvider);
        const [TokenBalance, TokenDecimals] = await Promise.all([
          TokenContract.balanceOf(bridgeContractAddress),
          TokenContract.decimals(),
        ]);
        const formattedBalance = parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
        tokensWithBalancesDict[tokenName] = { name: tokenName, balance: formattedBalance };
      } catch (innerError) {
        console.error(`Failed to fetch balance or decimals for ${tokenName || key} on network ${_networkId}`, innerError);
      }
    });
    await Promise.all(balancePromises);
    setBridgeTokensWithBalances(tokensWithBalancesDict);
  };

  // Set the source token information and fetch the user's token balance
  const setSourceTokenFunction = async (val) => {
    setSourceToken(val);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const sourceTokenAddress = SETTINGS.TOKEN_ADDRESSES[val][parseInt(networkId)];
    const TokenContract = new Contract(sourceTokenAddress, ERC20_ABI, signer);
    const TokenBalance = await TokenContract.balanceOf(account);
    const TokenDecimals = await TokenContract.decimals();
    setTokenBalance(parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6));
  };

  // Handle switching network via the provided switchNetwork function (passed as prop)
  const handleSwitchNetwork = async () => {
    await switchNetwork(parseInt(targetNetwork));
    setStep(4);
  };

  // Handle claiming tokens on the target network
  const handleClaimTokens = async () => {
    setLoading(true);
    if (!provider || !account) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const bridgeAddr = SETTINGS.BRIDGE_CONTRACTS[parseInt(targetNetwork)];
      const targetTokenAddress = SETTINGS.TOKEN_ADDRESSES[targetToken][parseInt(targetNetwork)];
      const tokenContract = new Contract(targetTokenAddress, ERC20_ABI, signer);
      const tokenDecimals = await tokenContract.decimals();
      const truncatedAmount = parseFloat(targetTokenAmount).toFixed(parseInt(tokenDecimals));
      const amountToClaimWei = parseUnits(truncatedAmount.toString(), tokenDecimals);
      setTxMessage("Claiming tokens");
      const contract = new Contract(bridgeAddr, BridgeABI, signer);
      const claimTx = await contract.claimBridge(targetTokenAddress, amountToClaimWei, {
        value: bridgeFees[parseInt(targetNetwork)],
        gasLimit: 700000,
      });
      const hash = claimTx.hash;
      setTxHash(hash);
      await claimTx.wait();
      setStep(5);
    } catch (error) {
      console.error("Claiming failed", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle bridging tokens from source to target network
  const handleBridge = async () => {
    setLoading(true);
    if (!provider || !account) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const bridgeAddr = SETTINGS.BRIDGE_CONTRACTS[parseInt(networkId)];
      const sourceTokenAddress = SETTINGS.TOKEN_ADDRESSES[sourceToken][parseInt(networkId)];
      const targetTokenAddress = SETTINGS.TOKEN_ADDRESSES[targetToken][parseInt(targetNetwork)];
      const tokenContract = new Contract(sourceTokenAddress, ERC20_ABI, signer);
      const tokenDecimals = await tokenContract.decimals();
      const amountToBridgeWei = parseUnits(amountToBridge.toString(), tokenDecimals);
      setTxMessage(`Approving ${sourceToken} transaction...`);
      const approveTx = await tokenContract.approve(bridgeAddr, amountToBridgeWei);
      await approveTx.wait();
      setTxMessage("Cross-Chain swap in progress");
      const contract = new Contract(bridgeAddr, BridgeABI, signer);
      const bridgeTx = await contract.bridgeSwap(
        sourceTokenAddress,
        targetTokenAddress,
        amountToBridgeWei,
        parseInt(targetNetwork),
        {
          value: bridgeFees[parseInt(networkId)],
          gasLimit: 700000,
        }
      );
      await bridgeTx.wait();
      setStep(3);
    } catch (error) {
      console.error("Swap failed", error);
    } finally {
      setLoading(false);
    }
  };

  const goToBridge = async () => {
    openLink("bridge");
  };

  const startBridge = async () => {
    await loadUserBalances();
    setStep(1);
  };

  const toStepOne = async () => {
    setStep(1);
  };

  const handlePreviewBridge = async () => {
    if (!amountToBridge || parseFloat(amountToBridge) <= 0) {
      setShowAmountError(true);
      return;
    }
    const bridgeContractAddress = SETTINGS.BRIDGE_CONTRACTS[parseInt(targetNetwork)];
    if (!bridgeContractAddress) {
      alert("Invalid bridge contract address");
      return;
    }
    const targetRpcUrl = SETTINGS.RPC_URLS[parseInt(targetNetwork)];
    if (!targetRpcUrl) {
      alert("Invalid RPC URL for the target network");
      return;
    }
    const targetTokenAddress = SETTINGS.TOKEN_ADDRESSES[targetToken][parseInt(targetNetwork)];
    const ethersProvider = new JsonRpcProvider(targetRpcUrl);
    const TokenContract = new Contract(targetTokenAddress, ERC20_ABI, ethersProvider);
    const TokenBalance = await TokenContract.balanceOf(bridgeContractAddress);
    const TokenDecimals = await TokenContract.decimals();
    const conBalTar = parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
    setTargetTokenContractBalance(conBalTar);
    setShowAmountError(false);
    const sourceTokenUsdtVal =
      parseFloat(amountToBridge) * parseFloat(SETTINGS.PRICES[sourceToken][parseInt(networkId)]);
    const targetTokenAm =
      sourceTokenUsdtVal / parseFloat(SETTINGS.PRICES[targetToken][parseInt(targetNetwork)]);
    if (parseFloat(conBalTar) <= parseFloat(targetTokenAm)) {
      alert("Not enough tokens on target network! Please adjust amount or select different token.");
      return;
    }
    setSourceTokenUsdtValue(sourceTokenUsdtVal);
    setTargetTokenAmount(targetTokenAm);
    setStep(2);
  };

  const handleCheckboxChange = (e) => {
    setIsConfirmed(e.target.checked);
  };

  const loadUserBalances = async () => {
    const sourceBal = await getBalance();
    setSourceNetworkNativeBalance(sourceBal);
    let tokensWithBalancesDict = {};
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const balancePromises = Object.keys(SETTINGS.PRICES).map(async (key) => {
      try {
        const value = SETTINGS.PRICES[key];
        const name = value.name;
        const tokenAddress = SETTINGS.TOKEN_ADDRESSES[name][parseInt(networkId)];
        if (!tokenAddress) {
          return;
        }
        const TokenContract = new Contract(tokenAddress, ERC20_ABI, signer);
        const [TokenBalance, TokenDecimals] = await Promise.all([
          TokenContract.balanceOf(account),
          TokenContract.decimals(),
        ]);
        const formattedBalance = parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
        tokensWithBalancesDict[name] = {
          name: name,
          balance: formattedBalance,
          decimals: parseInt(TokenDecimals),
        };
      } catch (error) {
        console.error("Balance load failed", error);
      }
    });
    await Promise.all(balancePromises);
    setTokensWithBalances(tokensWithBalancesDict);
  };

  const getExplorerLink = (network, hash) => {
    const explorers = {
      "137": "https://polygonscan.com/tx/",
      "56": "https://bscscan.com/tx/",
      "42161": "https://arbiscan.io/tx/",
      "10": "https://optimistic.etherscan.io/tx/",
    };
    return explorers[network] ? `${explorers[network]}${hash}` : "#";
  };

  useEffect(() => {
    if (provider) {
      if (step < 3) {
        setSourceToken("");
        setStep(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, networkId, networkName]);

  if (loading) {
    return (
      <div className="text-center mt-8">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
        <p className="mt-4">{txMessage}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-10">
      <h3 className="text-3xl font-bold text-center text-secondary mb-4">Cross-Chain Swap</h3>
      <p className="text-center text-gray-600 mb-8">
        Effortlessly swap tokens across multiple networks with real-time tracking and seamless liquidity.
      </p>
      <div className="max-w-lg mx-auto">
        <h4 className="text-xl font-semibold text-center mb-4">Create New Cross-Chain Swap</h4>
        <div className="bg-white dark:bg-gray-800 shadow rounded p-6">
          <div className="bg-blue-400 rounded px-3 py-2 mb-4">
            <span className="text-white">Step {step} of 5</span>
          </div>

          {step === 0 && (
            <div className="text-center">
              <p className="mb-4">Start Cross-Chain Swap:</p>
              {SETTINGS.IS_TEST ? (
                <p className="text-sm text-gray-500">Not available in test environment.</p>
              ) : (
                <>
                  {isFirstSite ? (
                    <button onClick={goToBridge} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">
                      Start
                    </button>
                  ) : (
                    <button onClick={startBridge} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">
                      Start
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Select Source Token:</label>
                <select
                  value={sourceToken}
                  onChange={(e) => setSourceTokenFunction(e.target.value)}
                  className="w-full border rounded px-3 py-2"
                >
                  <option disabled value="">
                    Select Source Token
                  </option>
                  {Object.values(tokensWithBalances).map((value, index) => (
                    <option key={index} value={value.name}>
                      {value.name} - {value.balance} {value.name}
                    </option>
                  ))}
                </select>
              </div>
              {sourceToken && (
                <>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Enter the Amount of Source Tokens:
                    </label>
                    <input
                      type="number"
                      value={amountToBridge}
                      onChange={(e) => setAmountToBridge(e.target.value)}
                      placeholder="Enter the Amount of Source Tokens"
                      className="w-full border rounded px-3 py-2"
                    />
                    {showAmountError && (
                      <small className="text-red-500 block mt-1">
                        <strong>Warning:</strong> Please enter an amount.
                      </small>
                    )}
                    <small className="text-gray-500 block">
                      Balance: {tokenBalance} {sourceToken}
                    </small>
                    <small className="text-gray-500 block">
                      Native Balance: {sourceNetworkNativeBalance} {nativeSymbols[parseInt(networkId)]}
                    </small>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Select Target Network:</label>
                    <select
                      value={targetNetwork}
                      onChange={(e) => setTargetNetworkFunction(e.target.value)}
                      className="w-full border rounded px-3 py-2"
                    >
                      <option disabled value="">
                        Select Target Network
                      </option>
                      {networkId !== "137" && <option value="137">Polygon</option>}
                      {networkId !== "56" && <option value="56">BNB Smart Chain</option>}
                      {networkId !== "42161" && <option value="42161">Arbitrum One</option>}
                      {networkId !== "10" && <option value="10">OP Mainnet</option>}
                    </select>
                  </div>
                </>
              )}
              {targetNetwork && (
                <div>
                  <label className="block text-sm font-medium mb-1">Select Target Token:</label>
                  <select
                    value={targetToken}
                    onChange={(e) => setTargetToken(e.target.value)}
                    className="w-full border rounded px-3 py-2"
                  >
                    <option disabled value="">
                      Select Target Token
                    </option>
                    {Object.values(bridgeTokensWithBalances).map((value, index) => (
                      <option key={index} value={value.name}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {targetToken && (
                <div className="text-center">
                  <button onClick={handlePreviewBridge} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded">
                    Preview
                  </button>
                </div>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="text-left space-y-4">
              <h4 className="text-xl font-semibold">Review Your Swap Details</h4>
              <p>Please confirm the details below before proceeding with your swap:</p>
              <ul className="list-disc pl-5">
                <li>
                  Source Token: <strong>{parseFloat(amountToBridge).toFixed(6)} {sourceToken}</strong>
                </li>
                <li>
                  Target Token: <strong>{parseFloat(targetTokenAmount).toFixed(6)} {targetToken}</strong>
                </li>
              </ul>
              <p className="text-sm text-gray-500">
                <i>Important:</i> Make sure you have at least double the network fee in your target network balance to cover gas and swap fees.
              </p>
              {parseFloat(targetNetworkNativeBalance) <
                2 * parseFloat(formatUnits(bridgeFees[targetNetwork], 18)) && (
                <p className="text-red-500 text-sm">
                  <strong>Warning:</strong> Your native balance on the target network is too low. Please ensure you have enough to cover at least twice the required fee.
                </p>
              )}
              <div className="flex items-start space-x-2">
                <input
                  type="checkbox"
                  id="confirm-terms"
                  checked={isConfirmed}
                  onChange={handleCheckboxChange}
                  className="mt-1"
                />
                <label htmlFor="confirm-terms" className="text-sm">
                  <small>
                    I confirm that I will not refresh or leave the page until the swapping process is complete, acknowledging that doing so may result in the loss of my balance and that this transaction is irreversible.
                  </small>
                </label>
              </div>
              <div className="space-y-2 text-center">
                <button
                  onClick={handleBridge}
                  disabled={
                    parseFloat(targetNetworkNativeBalance) < 2 * parseFloat(formatUnits(bridgeFees[targetNetwork], 18)) ||
                    !isConfirmed
                  }
                  className="w-full bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded disabled:opacity-50"
                >
                  Confirm Swap
                </button>
                <button onClick={toStepOne} className="w-full border border-gray-300 text-gray-700 py-2 px-4 rounded">
                  Go Back
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="text-center space-y-4">
              <h4 className="text-xl font-semibold">Step 3: Switch to Target Network</h4>
              <p>
                To continue, please switch to the{" "}
                <strong>
                  {targetNetwork === "137" && "Polygon"}
                  {targetNetwork === "56" && "BNB Smart Chain"}
                  {targetNetwork === "42161" && "Arbitrum One"}
                  {targetNetwork === "10" && "OP Mainnet"}
                </strong>{" "}
                network.
              </p>
              <button onClick={handleSwitchNetwork} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded">
                Switch Network
              </button>
              <hr />
              <p className="text-sm text-gray-500">
                Completing this step is essential to receive your {targetToken} tokens on the target network.
              </p>
            </div>
          )}

          {step === 4 && (
            <div className="text-center space-y-4">
              <h4 className="text-xl font-semibold">Step 4: Claim Your Tokens</h4>
              <p>
                Your tokens are ready to be claimed on the{" "}
                <strong>
                  {targetNetwork === "137" && "Polygon"}
                  {targetNetwork === "56" && "BNB Smart Chain"}
                  {targetNetwork === "42161" && "Arbitrum One"}
                  {targetNetwork === "10" && "OP Mainnet"}
                </strong>{" "}
                network.
              </p>
              <button onClick={handleClaimTokens} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded">
                Claim Tokens
              </button>
              <hr />
              <p className="text-sm text-gray-500">
                Click 'Claim Tokens' to complete the swap and receive {targetTokenAmount} {targetToken} on the target network.
              </p>
            </div>
          )}

          {step === 5 && (
            <div className="text-left space-y-4">
              <h4 className="text-xl font-semibold">Transaction Successful!</h4>
              <p>Congratulations! Your swap is complete. Here are the final details:</p>
              <ul className="list-disc pl-5">
                <li>
                  <strong>Swapped Source Token:</strong> {parseFloat(amountToBridge || 0).toFixed(6)} {sourceToken}
                </li>
                <li>
                  <strong>Received Target Token:</strong> {parseFloat(targetTokenAmount || 0).toFixed(6)} {targetToken}
                </li>
                <li>
                  <strong>Target Network:</strong>{" "}
                  {targetNetwork === "137" && "Polygon"}
                  {targetNetwork === "56" && "BNB Smart Chain"}
                  {targetNetwork === "42161" && "Arbitrum One"}
                  {targetNetwork === "10" && "OP Mainnet"} ({nativeSymbols[targetNetwork]})
                </li>
              </ul>
              <p className="text-sm text-gray-500">
                Your tokens are now available on the target network. Thank you for using WaveSwaps!
                <hr />
                <small>
                  <a
                    href={`${getExplorerLink(targetNetwork, txHash)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    View Transaction.
                  </a>
                </small>
              </p>
              <button onClick={() => setStep(0)} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded">
                Start Again
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrossChain;
