import React, { useEffect, useState } from 'react';
import { BiCopy } from "react-icons/bi";
import Table from 'react-bootstrap/Table';
import SETTINGS from '../config/supplies';  // Ensure the path to SETTINGS is correct

const Tokens = () => {
  const [assetData, setAssetData] = useState({});  // State to store data for assets
  const [loading, setLoading] = useState(true);    // Loading state

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    switch (network) {
      case 'Polygon Mainnet':
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'BNB Smart Chain':
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error('Unsupported network:', network);
        return null;
    }
  };

  const fetchPrice = async (asset, key) => {
    const { tokenAddress1, tokenAddress2, walletAddress, tokenDecimals1, tokenDecimals2, apiKey, network } = asset;
    const apiUrl1 = getApiUrlForNetwork(network, tokenAddress1, walletAddress, apiKey);
    const apiUrl2 = getApiUrlForNetwork(network, tokenAddress2, walletAddress, apiKey);

    if (!apiUrl1 || !apiUrl2) return { price: 'Error' };

    try {
      const [response1, response2] = await Promise.all([fetch(apiUrl1), fetch(apiUrl2)]);
      const data1 = await response1.json();
      const data2 = await response2.json();
      const balance1 = data1.result / Math.pow(10, tokenDecimals1);  
      const balance2 = data2.result / Math.pow(10, tokenDecimals2);  
      const price = (balance2 / balance1).toFixed(2);

      return { price };
    } catch (error) {
      console.error('Error fetching price data for', key, error);
      return { price: 'Error' };
    }
  };

  const fetchRevampAmount = async (asset, key) => {
    const { tokenAddress1, wallet, tokenDecimals1, apiKey, network } = asset;
    const apiUrlRevamp = getApiUrlForNetwork(network, tokenAddress1, wallet, apiKey);

    if (!apiUrlRevamp) return { revampAmount: 0, formattedRevampAmount: '0' };

    try {
      const response = await fetch(apiUrlRevamp);
      const data = await response.json();

      if (data.status !== '1' || !data.result) {
        console.error('Error fetching revamp amount:', data.message || 'Invalid data');
        return { revampAmount: 0, formattedRevampAmount: '0' };
      }

      const revampAmount = data.result / Math.pow(10, tokenDecimals1);
      const formattedRevampAmount = Math.floor(revampAmount).toLocaleString();

      return { revampAmount, formattedRevampAmount };
    } catch (error) {
      console.error('Error fetching revamp amount for', key, error);
      return { revampAmount: 0, formattedRevampAmount: '0' };
    }
  };

  const calculateCirculatingSupply = (totalSupply, revampAmount) => {
    const circulatingSupply = Math.floor(totalSupply - revampAmount);
    return circulatingSupply.toLocaleString();
  };

  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const key of Object.keys(SETTINGS.ASSETS)) {
      const asset = SETTINGS.ASSETS[key];
      const priceData = await fetchPrice(asset, key);
      const revampData = await fetchRevampAmount(asset, key);

      const circulatingSupply = calculateCirculatingSupply(asset.totalSupply, revampData.revampAmount);
      results[key] = { ...priceData, ...revampData, circulatingSupply };

      await delay(1000);  // Delay between API calls to avoid rate limits
    }

    setAssetData(results);
    setLoading(false);
  };

  const handleManualRefresh = () => {
    fetchAllData();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId, networkName, rpcUrl, currencySymbol, blockExplorerUrl) => {
    try {
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        return;
      }

      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x' + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x' + chainId.toString(16),
                  chainName: networkName,
                  rpcUrls: [rpcUrl],
                  nativeCurrency: {
                    name: currencySymbol,  
                    symbol: currencySymbol,  
                    decimals: 18,
                  },
                  blockExplorerUrls: [blockExplorerUrl],
                }],
              });
            } catch (addError) {
              console.error('Error adding network:', addError);
              return;
            }
          } else {
            console.error('Error switching network:', switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(`${tokenSymbol} successfully added to MetaMask on ${networkName}`);
      } else {
        console.log('Token addition to MetaMask failed.');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask:', error);
    }
  };

  const handleAddToken = (asset) => {
    const { 
      tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl 
    } = asset;

    addTokenFunction(tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h2 className="text-3xl font-bold text-center text-secondary mb-4">
        Tokens
      </h2>
      <p className="text-center text-gray-600 dark:text-gray-300 mb-8">
        Explore listed tokens with direct blockchain links, easy MetaMask integration,
        and live price tracking from DEX markets.
      </p>
  
      {/* Manual Refresh Button */}
      <div className="flex items-center mb-6">
        <button
          onClick={handleManualRefresh}
          disabled={loading}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded disabled:opacity-50"
        >
          {loading ? "Loading..." : "Refresh Data"}
        </button>
      </div>
  
      {/* Responsive Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr className="border-b border-gray-300">
              <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Logo
              </th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Asset Name
              </th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price ($)
              </th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Crafting
              </th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Circulating
              </th>
              <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Contract
              </th>
              <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Wallet
              </th>
              <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                DEX
              </th>
              <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Chart
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
            {Object.keys(SETTINGS.ASSETS).map((key) => {
              const asset = SETTINGS.ASSETS[key];
              const assetDataItem = assetData && assetData[key];
              return (
                <tr key={key} className="border-b border-gray-300">
                  {/* Logo & Chain Explorer Link */}
                  <td className="px-4 py-3 text-center text-sm text-gray-600 dark:text-gray-300">
                    <a
                      href={asset.chainExplorer}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={asset.assetLogo} alt={asset.logo} className="w-6 inline" />
                    </a>
                  </td>
                  {/* Asset Name */}
                  <td className="px-4 py-3 text-right text-sm text-gray-600 dark:text-gray-300">
                    <span className="font-semibold">{asset.name}</span>&nbsp;
                    <span className="text-gray-500">{asset.symbol}</span>
                  </td>
                  {/* Price */}
                  <td className="px-4 py-3 text-right text-sm text-gray-600 dark:text-gray-300">
                    {loading ? "Loading..." : assetDataItem?.price || "Fetching..."}
                  </td>
                  {/* Total Supply */}
                  <td className="px-4 py-3 text-right text-sm text-gray-600 dark:text-gray-300">
                    {asset.totalSupply.toLocaleString()}
                  </td>
                  {/* Crafting (Revamp Amount) */}
                  <td className="px-4 py-3 text-right text-sm text-gray-600 dark:text-gray-300">
                    {loading ? "Loading..." : assetDataItem?.formattedRevampAmount || "Fetching..."}
                  </td>
                  {/* Circulating Supply */}
                  <td className="px-4 py-3 text-right text-sm text-gray-600 dark:text-gray-300">
                    {loading ? "Loading..." : assetDataItem?.circulatingSupply || "Fetching..."}
                  </td>
                  {/* Copy Button */}
                  <td className="px-4 py-3 text-center">
                    <button
                      onClick={() => copyToClipboard(asset.tokenAddress1)}
                      className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white"
                    >
                      <BiCopy className="inline w-4 h-4" />
                    </button>
                  </td>
                  {/* MetaMask Button */}
                  <td className="px-4 py-3 text-center">
                    <button
                      onClick={() => handleAddToken(asset)}
                      className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white rounded p-1"
                    >
                      <img
                        src="https://tokens.waveswaps.com/images/metamask.svg"
                        alt="MetaMask"
                        className="w-5 h-5"
                      />
                    </button>
                  </td>
                  {/* DEX Links */}
                  <td className="px-4 py-3 text-center">
                    <div className="flex justify-center space-x-2">
                      <a
                        href="https://app.uniswap.org/swap"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://tokens.waveswaps.com/images/dex/uni.svg"
                          alt="Uniswap"
                          className="w-5 h-5"
                        />
                      </a>
                      <a
                        href={asset.chart1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://tokens.waveswaps.com/images/dex/dexscreener.svg"
                          alt="dexScreener"
                          className="w-5 h-5"
                        />
                      </a>
                    </div>
                  </td>
                  {/* Chart Link */}
                  <td className="px-4 py-3 text-center">
                    <a
                      href={asset.chart}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://tokens.waveswaps.com/images/cmc.svg"
                        alt="Chart"
                        className="w-5 h-5 inline"
                      />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );  
};

export default Tokens;
