import React, { useEffect } from 'react';
import BFSExplainer from "../components/BFSExplainer";
import CommunityGrowth from "../components/CommunityGrowth";
import BFSAutomation from "../components/BFSAutomation";
import Web3Transition from "../components/Web3Transition";
import HeroSlider from "../components/HeroSlider";
import WSHAREPurchaseModal from "../components/WSHAREPurchaseModal";

function Ecosystem() {
  useEffect(() => {
    const navItems = document.querySelectorAll('.dev-topic-nav li');
    navItems.forEach(li => {
      li.addEventListener('click', () => {
        navItems.forEach(x => x.classList.remove('active'));
        li.classList.add('active');
        const panels = document.querySelectorAll('.feature-panel');
        panels.forEach(panel => panel.classList.remove('show'));
        const featureId = li.getAttribute('data-feature');
        const panelEl = document.getElementById(featureId);
        if (panelEl) panelEl.classList.add('show');
      });
    });
  }, []);

  return (
    <div className="ecosystem-page">
      <section className="py-12">
        <BFSExplainer />
      </section>
      <section className="py-12">
        <CommunityGrowth />
      </section>
      <section className="py-12">
        <BFSAutomation />
      </section>
      <section className="py-12"> 
        <Web3Transition />
      </section>
      <section className="py-12">
        <WSHAREPurchaseModal />
      </section>
    </div>
  );
}

export default Ecosystem;
