// src/components/PurchaseDisclaimerModal.js
import React from "react";

const PurchaseDisclaimerModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-[99999] flex items-center justify-center bg-black bg-opacity-50 p-4"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full p-6"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold text-center mb-4 text-secondary">
          Terms and Conditions
        </h2>
        <p className="text-gray-700 dark:text-gray-300 text-sm mb-6 text-justify">
          Please read these terms and conditions carefully before purchasing tokens.
          <br /><br />
          By using this interface, you acknowledge that you are solely responsible for
          your transactions and fully aware of the risks associated with purchasing cryptocurrency.
          Purchased tokens are delivered instantly to your wallet at the stated price, and transactions are <b>final and irreversible</b>.
          However, we do not guarantee token price stability or future performance.
          It is your responsibility to securely store the transaction hash generated at the time of purchase.
          <br /><br />
          Furthermore, any income received from this purchase will be proportionally distributed
          to WaveShare governance shareholders.
        </p>
        <h3 className="text-xl font-semibold text-center mb-3 text-secondary">
        Precautionary Notice
        </h3>
        <p className="text-gray-700 dark:text-gray-300 text-sm mb-6 text-justify">
        If you are uncertain, have any doubts, or are not <b>100% sure</b> about this purchase, we strongly advise you <b>not to proceed</b>. You should only continue if you have conducted thorough research, fully understand the implications of your purchase, and accept the potential risks involved. If you hesitate or foresee any possibility of regretting this decision, it is best to leave this feature now.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDisclaimerModal;
