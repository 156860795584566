// src/components/dao/WeightedVoting.jsx

import React, { useState, useEffect } from 'react';
import WeightedProposalForm from './WeightedProposalForm';
import WeightedProposalList from './WeightedProposalList';
import ConnectButton from '../../components/ConnectButton';

async function ensurePolygonNetwork(provider) {
  if (!provider) return false;
  try {
    const chainId = await provider.request({ method: 'eth_chainId' });
    if (chainId.toLowerCase() !== '0x89') {
      // Attempt chain switch (silently fail or handle errors)
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }]
      });
    }
    return true;
  } catch (err) {
    console.error('Switch chain to Polygon failed:', err);
    return false;
  }
}

const WeightedVoting = ({ provider, selectedAccount, isConnected }) => {
  const [activeTab, setActiveTab] = useState('list');
  const [chainOk, setChainOk] = useState(false);

  useEffect(() => {
    // Attempt chain check if user is connected
    async function checkChain() {
      if (!provider || !isConnected) {
        setChainOk(false);
        return;
      }
      const ok = await ensurePolygonNetwork(provider);
      setChainOk(ok);
    }
    checkChain();
  }, [provider, isConnected]);

  return (
    <section className="min-h-screen py-16 px-4 mb-4 container mx-auto dark:bg-bggrey text-gray-800">
      {/* Top Banner if not connected or chain not correct */}
      {(!isConnected || !chainOk) && (
        <div className="mb-6 p-4 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 shadow">
          <p className="text-sm text-gray-700 dark:text-gray-200 mb-2">
            To create or vote on proposals, please connect your wallet on Polygon (chainId 0x89).
          </p>
          <div className="flex items-center space-x-3">
            <ConnectButton />
            <span className="text-xs text-gray-500 dark:text-gray-400">
              or manually switch your wallet to Polygon
            </span>
          </div>
        </div>
      )}

      <h2 className="py-2 text-3xl font-bold text-center text-secondary">
      Weighted Voting
      </h2>
      <p className="text-center mb-8 text-gray-600">
      Amplify your influence by locking DAO tokens. Your vote weight depends on the amount locked.
      </p>

      {/* Tailwind-based tab navigation */}
      <div className="flex border-b border-gray-300 dark:border-gray-700 mb-4 space-x-4">
        <button
          onClick={() => setActiveTab('form')}
          className={`pb-2 transition-colors ${
            activeTab === 'form'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-blue-500'
          }`}
        >
          Submit Proposal
        </button>
        <button
          onClick={() => setActiveTab('list')}
          className={`pb-2 transition-colors ${
            activeTab === 'list'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-blue-500'
          }`}
        >
          View Proposals
        </button>
      </div>

      {activeTab === 'form' && (
        <WeightedProposalForm
          provider={provider}
          selectedAccount={selectedAccount}
          isConnected={isConnected}
          chainOk={chainOk}
          setActiveTab={setActiveTab}
        />
      )}
      {activeTab === 'list' && (
        <WeightedProposalList
          provider={provider}
          selectedAccount={selectedAccount}
          isConnected={isConnected}
          chainOk={chainOk}
        />
      )}
    </section>
  );
};

export default WeightedVoting;