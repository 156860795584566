import React, { useEffect, useState, useRef } from "react";
import TapCoinLink from "./TapCoinLink";
import { FaTelegramPlane } from "react-icons/fa";
import { Button } from '../components/ui/Button';

const RoadmapSection = () => {
  const [subscriberCount, setSubscriberCount] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const timelineRef = useRef(null);

  useEffect(() => {
    const fetchSubscriberCount = async () => {
      try {
        const response = await fetch("https://api.geton.global/getTelegramSubscribers.php");
        const data = await response.json();
        if (data && data.result) {
          const count =
            typeof data.result === "number"
              ? data.result
              : parseInt(data.result, 10);
          if (!isNaN(count)) {
            setSubscriberCount(count);
          } else {
            setFetchError(true);
          }
        } else {
          setFetchError(true);
        }
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
        setFetchError(true);
      }
    };

    fetchSubscriberCount();
  }, []);

  useEffect(() => {
    const inProgressItem = document.querySelector(".items-list.in-progress");
    if (inProgressItem && timelineRef.current) {
      const offsetLeft =
        inProgressItem.offsetLeft -
        timelineRef.current.offsetWidth / 2 +
        inProgressItem.offsetWidth / 2;
      timelineRef.current.scrollLeft = offsetLeft;
    }
  }, []);

  const subscriberText = fetchError
    ? "Unable to load subscriber count."
    : subscriberCount !== null
    ? `Current Subscribers: ${subscriberCount.toLocaleString()}`
    : "Loading subscriber count...";

  // Define refined roadmap items with one-sentence descriptions.
  const roadmapItems = [
    {
      quarter: "500+ Followers",
      title: "TG Integration",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Experience exclusive in-game tap rewards as we integrate Geton Combat MiniApp when
          official GetonGlobal Telegram community surpasses 500 followers.
        </>
      ],
      status: "completed",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "1K+ Followers",
      title: "DEX Listing",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Celebrate the debut of <TapCoinLink /> on decentralized exchanges, enabling seamless
          trading as our community grows past 1,000 followers.
        </>
      ],
      status: "in-progress",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "5K+ Followers",
      title: "Airdrop Round 1",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Receive your <TapCoinLink /> tokens in our first airdrop round once our Telegram group
          exceeds 5,000 followers, with terms and threshold balances to be announced.
        </>
      ],
      status: "planned",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "10K+ Followers",
      title: "Airdrop Round 2",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Unlock a new <TapCoinLink /> token airdrop round when our community surpasses 10,000
          followers, with forthcoming terms and threshold balance requirements.
        </>
      ],
      status: "planned",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "25K+ Followers",
      title: "Airdrop Round 3",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Enjoy a larger <TapCoinLink /> airdrop as we hit 25,000 followers, with specific terms and
          threshold balances to be announced soon.
        </>
      ],
      status: "planned",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "50K+ Followers",
      title: "Airdrop Round 4",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Unlock an even bigger <TapCoinLink /> token airdrop round at over 50,000 followers, with
          detailed terms and threshold balance conditions coming soon.
        </>
      ],
      status: "planned",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
    {
      quarter: "100K+ Followers",
      title: "Airdrop Round 5",
      image: "https://nodes.geton.global/ecosystem/coin.png",
      description: [
        <>
          Celebrate our largest milestone with a <TapCoinLink /> token airdrop round once our Telegram
          community exceeds 100,000 followers, with terms and threshold balances to be defined.
        </>
      ],
      status: "planned",
      showReadMore: true,
      readMoreLink: "https://t.me/metacoincommunity",
    },
  ];

  // Returns the background colour for the badge based on milestone status.
  const getBadgeClass = (status) => {
    switch (status) {
      case "completed":
        return "bg-green-500"; // Past milestone
      case "in-progress":
        return "bg-yellow-500"; // Current milestone
      case "planned":
        return "bg-blue-500"; // Future milestone
      default:
        return "";
    }
  };

  return (
    <section className="py-12 px-6 dark:bg-bggrey text-gray-800">
      <div className="max-w-6xl mx-auto text-center mb-10">
        <h2 className="text-3xl font-bold text-secondary mb-4">Roadmap</h2>
        <p className="text-gray-600 dark:text-gray-100">
          Discover our milestones and upcoming features.
        </p>
      </div>

      <div className="overflow-x-auto px-4">
        <div ref={timelineRef} className="flex space-x-8">
          {roadmapItems.map((item, index) => (
            <div
              key={index}
              className={`items-list flex flex-col min-w-[250px] max-w-[300px] p-6 bg-[var(--card-background)] rounded-lg shadow-md hover:shadow-xl transition-shadow border border-gray-200 dark:border-gray-700 text-center ${
                item.status === "in-progress" ? "in-progress" : ""
              }`}
            >
              <div className="mb-4">
                <span
                  className={`inline-block px-4 py-1 text-xs font-bold text-white ${getBadgeClass(
                    item.status
                  )} rounded-full shadow-md uppercase tracking-wide`}
                >
                  {item.quarter}
                </span>
              </div>
              <div className="flex items-center p-2 border-b border-gray-300">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-10 h-10 object-contain mr-3"
                />
                <p className="text-ml font-semibold text-secondary mb-0">
                  {item.title}
                </p>
              </div>

              {/* Description Section */}
              <div className="flex-grow py-2">
                <p className="text-sm text-gray-700 text-justify dark:text-gray-300 mb-1 space-y-2">
                  {item.description.map((line, lineIndex) => (
                    <span key={lineIndex}>{line}</span>
                  ))}
                </p>
              </div>

              {/* Fixed Bottom Section */}
              <div className="mt-auto flex flex-col gap-2">
                <p className="text-sm text-gray-500">{subscriberText}</p>
                {item.showReadMore && (
                  <a
                    href={item.readMoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center px-4 py-2 bg-[#0088cc] text-white text-xs font-semibold rounded transition-colors duration-300 hover:bg-[#007ab8] hover:scale-105"
                  >
                    <FaTelegramPlane className="w-5 h-5 mr-2" />
                    <span>Join on Telegram</span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <a 
          href="/dao-weighted" 
          className="px-6 py-2 bg-secondary text-white rounded hover:bg-secondary-dark transition-colors">
          Propose Next Airdrop Round T&C
        </a>
      </div>
    </section>
    
  );
};

export default RoadmapSection;
