// src/hooks/useIdleTimer.js
import { useState, useEffect, useCallback, useRef } from 'react';
import settings from '../config/settings';

export default function useIdleTimer({
  onTimeout,
  onShowWarning,
  onHideWarning,
  isActive = false // new prop
}) {
  const { INACTIVITY_TIMEOUT_MINUTES, MODAL_WARNING_SECONDS } = settings;
  const inactivityMs = INACTIVITY_TIMEOUT_MINUTES * 60 * 1000;
  const warningMs = MODAL_WARNING_SECONDS * 1000;

  const [lastActive, setLastActive] = useState(Date.now());
  const warningShownRef = useRef(false);

  // Only reset timer if isActive = true
  const resetTimer = useCallback(() => {
    if (!isActive) return; // If user not logged in, no effect
    setLastActive(Date.now());
  }, [isActive]);

  // Listen user events => reset inactivity
  useEffect(() => {
    if (!isActive) return; // skip if not active
    const handleActivity = () => {
      resetTimer();
    };
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach(evt => window.addEventListener(evt, handleActivity));
    return () => {
      events.forEach(evt => window.removeEventListener(evt, handleActivity));
    };
  }, [resetTimer, isActive]);

  // Interval check
  useEffect(() => {
    if (!isActive) return;

    const interval = setInterval(() => {
      const now = Date.now();
      const diff = now - lastActive;
      const timeLeft = inactivityMs - diff;

      // Show warning if not shown
      if (!warningShownRef.current && timeLeft <= warningMs && timeLeft > 0) {
        warningShownRef.current = true;
        if (onShowWarning) onShowWarning(timeLeft);
      }

      // Timeout
      if (timeLeft <= 0) {
        clearInterval(interval);
        if (onTimeout) onTimeout();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastActive, onTimeout, onShowWarning, isActive, inactivityMs, warningMs]);

  // Expose a function if parent wants to manually reset
  // e.g. on "Stay Active" button
  const manualReset = useCallback(() => {
    setLastActive(Date.now());
    warningShownRef.current = false; // hide the warning
    if (onHideWarning) onHideWarning();
  }, [onHideWarning]);

  return { manualReset };
}
