// src/components/FeaturesSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent } from './ui/Card';

const featuresData = [
  {
    title: 'Renewal Rigs',
    description: 'Automated earning structures enabling liquidity unlocking and structured commissions.',
    link: '/#'
  },
  {
    title: 'Liquidity Nodes',
    description: 'Strengthening token liquidity through structured participation.',
    link: '#'
  },
  {
    title: 'Swapping Bots',
    description: 'Automated trading solutions for optimized token swaps.',
    link: '/#'
  },
  {
    title: 'DAO',
    description: 'Decentralized governance empowering users to influence platform development.',
    link: '/#'
  },
  {
    title: 'Staking',
    description: 'Passive income generation through smart contract-managed staking.',
    link: '/#'
  },
  {
    title: 'Cross-Chain Swap',
    description: 'Seamless interoperability between different blockchain networks.',
    link: '/#'
  },
];

const FeaturesSection = () => {
  return (
    <section className="py-10 px-4 mb-4 container mx-auto dark:bg-bggrey text-gray-800">
      <div className="container mx-auto">
        <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
          Features
        </h2>
        <p className="text-center mb-10 text-gray-600">
        Geton Global equips you with <b>powerful tools to maximize liquidity, automate earnings, and take control of your DeFi journey—whether staking, swapping, or governing</b>.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {featuresData.map((feature, index) => (
            <motion.div 
              key={index} 
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="h-full"
            >
              <Link to={feature.link} className="block h-full no-underline hover:no-underline">
                <Card className="bg-[var(--card-background)] h-full hover:bg-gray-200 dark:hover:bg-gray-700">
                  <CardContent className="flex flex-col justify-between h-full">
                    <div>
                      <h5 className="text-xl font-semibold mb-2 text-secondary dark:text-primary">
                        {feature.title}
                      </h5>
                      <p className="flex-grow text-start text-[var(--text-color)] opacity-80">
                        {feature.description}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
