/* global BigInt */
// src/components/Wallet.js
import React, { useState } from "react";
import { useAppKitAccount } from "@reown/appkit/react";
import settings from "../config/settings";
import TokenCard from "./TokenCard";
import TokenPurchaseModal from "./TokenPurchaseModal";
import { BrowserProvider, Contract, formatEther } from "ethers";
import dpmcABI from "../abis/dpmcABI.json";

export default function Wallet() {
  const { isConnected } = useAppKitAccount();
  const [selectedToken, setSelectedToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // Keep track of token amounts per token address
  const [tokenAmounts, setTokenAmounts] = useState({});
  // Optional page-level loader
  const [isPageLoading, setIsPageLoading] = useState(false);

  // Filter only tokens with 'active' status
  const activeTokens = settings.TOKENS.filter((t) => t.status === "active");

  // Fetch available supply from the smart contract (remaining tokens)
  async function fetchAvailableSupply(token) {
    try {
      const provider = new BrowserProvider(window.ethereum);
      const contract = new Contract(token.liquidityContract, dpmcABI, provider);
      const tokensSold = await contract.tokensSold();
      const totalTokensForSale = await contract.totalTokensForSale();
      const remainingSupply = totalTokensForSale - tokensSold;
      return remainingSupply.toString();
    } catch (err) {
      console.error("Error fetching supply for token:", token.symbol, err);
      return "unknown";
    }
  }

  // Fetch current price per token from the smart contract
  async function fetchCurrentPrice(token) {
    try {
      const provider = new BrowserProvider(window.ethereum);
      const contract = new Contract(token.liquidityContract, dpmcABI, provider);
      const tokensSold = await contract.tokensSold();
      const totalTokensForSale = await contract.totalTokensForSale();
      // Using native BigInt arithmetic for scaling: ONE = 1e18
      const ONE = BigInt("1000000000000000000");
      const x = (tokensSold * ONE) / totalTokensForSale;
      const currentPrice = await contract.getPrice(x);
      return formatEther(currentPrice);
    } catch (err) {
      console.error("Error fetching price for token:", token.symbol, err);
      return token.stablePrice; // fallback to stablePrice from settings
    }
  }

  // Validate token amount before opening the purchase modal
  const handleOpenModal = (token) => {
    const amount = tokenAmounts[token.contractAddress];
    if (!amount || parseFloat(amount) <= 0) {
      alert("Please enter the number of tokens you wish to purchase before proceeding.");
      return;
    }
    setSelectedToken(token);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedToken(null);
  };

  return (
    <section className="py-12 px-4 mx-auto mb-4 mt-3 container dark:bg-bggrey text-gray-800">
      {/* Optional page-level loader */}
      {isPageLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Processing...</p>
          </div>
        </div>
      )}
      <div className="max-w-screen-xl mx-auto">
        <h2 className="py-6 text-3xl font-bold text-center mt-2 text-secondary">
          Geton Wallet – Your Dynamic Asset Hub
        </h2>
        <p className="text-center mb-10 text-gray-600">
          Securely purchase global market assets at DPMC-controlled prices, benefit from dynamic reward modeling, and join a system where revenue is reinvested through DAO-approved allocations to WSHARE shareholders or DEX liquidity.
        </p>

        {/* Grid of active tokens */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {activeTokens.map((token) => {
            const amount = tokenAmounts[token.contractAddress] || "";
            return (
              <TokenCard
                key={token.contractAddress}
                token={token}
                connected={isConnected}
                amount={amount}
                onAmountChange={(val) =>
                  setTokenAmounts((prev) => ({
                    ...prev,
                    [token.contractAddress]: val,
                  }))
                }
                fetchAvailableSupply={fetchAvailableSupply}
                fetchCurrentPrice={fetchCurrentPrice}
                onBuy={() => handleOpenModal(token)}
              />
            );
          })}
        </div>

        {/* Token purchase modal */}
        {showModal && selectedToken && (
          <TokenPurchaseModal
            isOpen={showModal}
            onClose={handleCloseModal}
            token={selectedToken}
            tokenAmount={tokenAmounts[selectedToken.contractAddress] || "0"}
          />
        )}
      </div>
    </section>
  );
}
