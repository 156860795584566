import React, { useState, useEffect } from "react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  isAddress,
} from "ethers";

import SETTINGS from "../config/swap";
import WaweSwapsABI from "../abis/WaveV2PolygonABI.json";
import WaweSwapStorageABI from "../abis/WaveV2PolygonStorageABI.json";
import { ERC20_ABI } from "../abis/erc20";
import { BiCopy, BiArrowToBottom, BiCaretDown, BiCaretUp } from "react-icons/bi";

import ToastNotification from "./ToastNotification";
import InfoModal from "./InfoModal";
import ConnectButton from "./ConnectButton";

function SwapV1({
  provider,
  selectedAccount,
  networkSymbol,
  isConnected,
  networkName,
  networkId,
  switchNetwork,
}) {
  // state declarations
  const [stakeAmount, setStakeAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [recBalance, setRecBalance] = useState(0);
  const [ercBalance, setErcBalance] = useState(0);
  const [gblBalance, setGblBalance] = useState(0);
  const [gblAmountValue, setGblAmountValue] = useState(0);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
  const [swapTokenAmount, setSwapTokenAmount] = useState("");
  const [gblBuyAmount, setGblBuyAmount] = useState("");
  const [gblSellAmount, setGblSellAmount] = useState("");
  const [recycleAmount, setRecycleAmount] = useState("");
  const [refferalAddress, setRefferalAddress] = useState("");
  const [buyTokens, setBuyTokens] = useState([]);
  const [userSwaps, setUserSwaps] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedRecycleToken, setSelectedRecycleToken] = useState(null);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [loadingStep, setLoadingStep] = useState(1);
  const [loadingNumber, setLoadingNumber] = useState(1);
  const [showToast, setShowToast] = useState(false);
  const [toastUrl, setToastUrl] = useState(null);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("-");
  const [waveStakes, setWaveStakes] = useState([]);
  // For accordions we keep track of which items are open
  const [openWaveStakes, setOpenWaveStakes] = useState({});
  const [openSwaps, setOpenSwaps] = useState({});
  const [showTokenDropdown, setShowTokenDropdown] = useState(false);
  const [showRecycleTokenDropdown, setShowRecycleTokenDropdown] = useState(false);

  // New state for Confirmation Modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationTxHash, setConfirmationTxHash] = useState("");

  // Toast helper
  const displayToast = (msg, url = null, error = false) => {
    setToastMessage(msg);
    setToastUrl(url);
    setToastError(error);
    setShowToast(true);
  };

  // Dropdown/select handling
  const handleSelect = (token) => {
    setSelectedToken(token);
    handleTokenChange({ target: { value: token.address } });
  };

  const handleSelectRecycle = (token) => {
    setSelectedRecycleToken(token);
    handleRecycleTokenChange({ target: { value: token.address } });
  };

  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setConfirmationTxHash("");
  };

  const getWaveStakes = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const userStakes = await contract.getUserWaveStakes(userAddress);
      const stakesWithClaimable = await Promise.all(
        userStakes.map(async (stake, index) => {
          const claimableAmount = await contract.getClaimableWaveAmount(
            userAddress,
            index
          );
          return {
            amount: formatUnits(stake.amount.toString(), 18),
            startTime: new Date(parseInt(stake.startTime.toString()) * 1000).toLocaleString(),
            claimedAmount: formatUnits(stake.claimedAmount.toString(), 18),
            claimableAmount: parseFloat(formatUnits(claimableAmount.toString(), 18)).toFixed(6),
          };
        })
      );
      setWaveStakes(stakesWithClaimable);
    } catch (error) {
      console.error("Error fetching stakes:", error);
    }
  };

  const claimWaveStake = async (stakeIndex) => {
    setIsLoading(true);
    setLoadingStep(1);
    setLoadingNumber(1);
    setLoadingText("Claiming WAVE");
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claim(stakeIndex, {
        value: 1000000000000000,
      });
      await tx.wait();
      alert("Claim successful!");
      await loadUserData();
    } catch (error) {
      console.error("Error claiming stake:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeSwap = async (_swapIndex) => {
    setLoadingStep(1);
    setLoadingNumber(1);
    setLoadingText("Closing Swap");
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const tx = await contract.openWaveStake(_swapIndex, {
        value: 1000000000000000,
      });
      await tx.wait();
      await loadUserData();
      handleCloseCollectModal();
      displayToast("Swap closed.", tx.hash);
    } catch (error) {
      console.error("Swap close failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBalance = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const balanceWei = await ethersProvider.getBalance(selectedAccount);
      const balanceEth = formatUnits(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const getBalanceERC = async (addr) => {
    if (!selectedAccount) {
      console.error("selectedAccount is null, skipping balance fetch");
      return 0;
    }
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const TokenContract = new Contract(addr, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(selectedAccount);
      const TokenDecimals = await TokenContract.decimals();
      return parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };

  useEffect(() => {
    const sp = new URLSearchParams(window.location.search);
    const ref = sp.get("reff");
    setRefferalAddress(ref ?? "");
    if (selectedAccount) {
      firstLoad();
      // preset selected token
      handleSelect({ symbol: "GBL", address: SETTINGS.globalTokenAddress });
    } else {
      setGblBalance(0);
      setUserSwaps([]);
      handleSelect({ symbol: "GBL", address: SETTINGS.globalTokenAddress });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, buyTokens, networkId]);

  const firstLoad = async () => {
    await loadUserData();
    if (buyTokens.length === 0) {
      showAvailableTokens(selectedAccount);
    }
  };

  const loadUserData = async () => {
    setIsLoading(true);
    try {
      setGblBalance(await getBalanceERC(SETTINGS.globalTokenAddress));
      const swaps = await getSwapsForUser(selectedAccount);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const myRefferer = await contract.referrals(selectedAccount);
      if (myRefferer !== "0x0000000000000000000000000000000000000000") {
        setRefferalAddress(myRefferer);
      }
      await getWaveStakes(selectedAccount);
      setUserSwaps(swaps);
    } catch (error) {
      console.error("Error loading user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSwapsForUser = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const contractStorage = new Contract(
        SETTINGS.waweSwapPolygonStorageAddress,
        WaweSwapStorageABI,
        signer
      );
      const userSwaps = await contractStorage.getSwaps(userAddress);
      const userRewardAmountWei = await contractStorage.rewards(userAddress);
      let swaps = [];
      let userRewardAmount = parseFloat(formatUnits(userRewardAmountWei, "ether"));
      for (let i = 0; i < userSwaps.length; i++) {
        const mxSellAmount = parseFloat(formatUnits(userSwaps[i][2], "ether")).toFixed(4);
        const swapIndex = parseInt(userSwaps[i][5]);
        const temp = {
          uid: i,
          swapIndex: swapIndex,
          amount: parseFloat(formatUnits(userSwaps[i][0], "ether")).toFixed(2),
          sellAmount: parseFloat(formatUnits(userSwaps[i][1], "ether")).toFixed(4),
          mxSellAmount: mxSellAmount,
          maxSellAmount: parseFloat(formatUnits(userSwaps[i][2], "ether")).toFixed(4),
          soldAmount: (
            parseFloat(formatUnits(userSwaps[i][2], "ether")) -
            parseFloat(formatUnits(userSwaps[i][1], "ether"))
          ).toFixed(4),
        };
        swaps.push(temp);
      }
      setUnclaimedRewards(userRewardAmount.toFixed(4));
      let feePercentage = userRewardAmount < 100 ? 0.02 : userRewardAmount < 1000 ? 0.01 : 0.005;
      let feeAmount = userRewardAmount * feePercentage;
      let netRewardAmount = userRewardAmount - feeAmount;
      setUnclaimedRewardsFee(feeAmount.toFixed(4));
      setUnclaimedRewardsNet(netRewardAmount.toFixed(4));
      return swaps.reverse();
    } catch (error) {
      console.error("Error fetching swap data:", error);
    }
  };

  const handleTokenChange = async (e) => {
    try {
      const selectedAddress = e.target.value;
      if (selectedAddress === "") {
        setSelectedToken(null);
        setSelectDisabled(false);
      } else {
        const token = buyTokens.find((token) => token.address === selectedAddress);
        setSelectedToken(token);
        if (!token) return;
        if (token.symbol === "BNB") {
          setErcBalance(await getBalance());
        } else {
          setErcBalance(await getBalanceERC(token.address));
        }
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setGblSellAmount(0);
        setSelectDisabled(false);
      }
      setSwapAvailable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecycleTokenChange = async (e) => {
    functionRecycleTokenChange(e.target.value);
  };

  const functionRecycleTokenChange = async (selectedAddress) => {
    if (selectedAddress === "") return;
    const token = tokens.find((token) => token.address === selectedAddress);
    if (!token) return;
    setSelectedRecycleToken(token);
    setSwapAvailable(true);
  };

  const handleRefferalAddressChange = async (e) => {
    const addr = e.target.value;
    if (addr.length === 0) return;
    if (!isAddress(addr)) {
      displayToast("Wrong address type", null, true);
      setRefferalAddress("");
      return;
    }
    if (addr.toLowerCase() === selectedAccount.toLowerCase()) {
      displayToast("You can't use your own address.", null, true);
      setRefferalAddress("");
      return;
    }
    setRefferalAddress(addr);
  };

  const handleBuyAmountChange = async (e) => {
    const amount = e.target.value;
    setSwapTokenAmount(amount);
    setGblBuyAmount(0);
    setGblSellAmount(0);
    setRecycleAmount(0);
    if (amount === "") {
      setSwapAvailable(false);
    } else {
      if (parseFloat(amount) > parseFloat(ercBalance)) {
        setErrorText("Balance too low!");
        setGblBuyAmount(0);
        setGblSellAmount(0);
        setSwapAvailable(false);
      } else {
        setErrorText("");
        let feePercentage = 1.5;
        if (parseFloat(amount) < 10) feePercentage = 2;
        else if (parseFloat(amount) < 20) feePercentage = 1.9;
        else if (parseFloat(amount) < 30) feePercentage = 1.8;
        else if (parseFloat(amount) < 40) feePercentage = 1.7;
        else if (parseFloat(amount) < 50) feePercentage = 1.7;
        else if (parseFloat(amount) >= 50) feePercentage = 1.5;
        const swapBuySellAmount = parseFloat(amount) * feePercentage;
        setGblBuyAmount(swapBuySellAmount.toFixed(4));
        setGblSellAmount(swapBuySellAmount.toFixed(4));
        setSwapAvailable(true);
      }
    }
  };

  const collectIncome = async () => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const transaction = await contract.claimReward({
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      await transaction.wait();
      setStakeAmount("");
      setUnclaimedRewards(0);
      setUnclaimedRewardsNet(0);
      setUnclaimedRewardsFee(0);
    } catch (error) {
      console.error("Claim reward failed:", error);
    } finally {
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
    }
  };

  const copyRefferalLink = async () => {
    const text = "https://waveswaps.com/?v=2&reff=" + selectedAccount;
    navigator.clipboard.writeText(text);
    displayToast("Link copied!");
  };

  const handleStartBot = async () => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE();
      let refAddr = refferalAddress ? refferalAddress : SETTINGS.genessisAddress;
      setLoadingNumber(1);
      setLoadingStep(1);
      setLoadingText(`Approving ${selectedToken.symbol} transaction.`);
      const tokenContract = new Contract(
        selectedToken.address,
        ERC20_ABI,
        signer
      );
      const amountInWei = parseUnits(swapTokenAmount.toString(), "ether");
      await tokenContract
        .approve(SETTINGS.waweSwapPolygonAddress, amountInWei, {
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .then((tx) => tx.wait());
      setLoadingNumber(2);
      setLoadingStep(2);
      setLoadingText("Starting V1 GBL Bot");
      const tx = await contract.buySwap(
        selectedRecycleToken.address,
        amountInWei,
        refAddr,
        { value: fee, maxPriorityFeePerGas: null, maxFeePerGas: null }
      );
      await tx.wait();
      // Set confirmation modal state
      setConfirmationTxHash(tx.hash);
      setShowConfirmationModal(true);
      displayToast("V1 GBL bot started!", tx.hash);
    } catch (error) {
      console.error("GBL failed:", error);
    } finally {
      await loadUserData();
      setSwapTokenAmount("");
      setGblBuyAmount(0);
      setGblSellAmount(0);
      setRecycleAmount("");
      setRefferalAddress("");
      setSwapAvailable(false);
      setSelectedRecycleToken(null);
      setIsLoading(false);
    }
  };

  const handleCloseSwap = async (index) => {
    setIsLoading(true);
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Closing swap.");
    await handleCloseSwapFunction(index);
    setIsLoading(false);
    loadUserData();
  };

  const handleCloseSwapFunction = async (index) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const withdrawTx = await contract.closeSwap(index, {
        value: 0,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      await withdrawTx.wait();
      displayToast("Swap closed!", withdrawTx.hash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
  };

  const showAvailableTokens = (address) => {
    const url = `https://waweswaps.global/api/get_balances.php?address=${address}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        const tokenArr = JSON.parse(data);
        let userTokens = [];
        for (let i = 0; i < tokenArr.length; i++) {
          userTokens.push(tokenArr[i].symbol);
        }
        setTokens(
          SETTINGS.tokensDataPolygon.sort((a, b) => (a.price > b.price ? -1 : 1))
        );
        setBuyTokens([
          { name: "GBL", symbol: "GBL", address: SETTINGS.globalTokenAddress },
        ]);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const exportSwaps = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const contractStorage = new Contract(
      SETTINGS.waweSwapPolygonStorageAddress,
      WaweSwapStorageABI,
      signer
    );
    const contract = new Contract(
      SETTINGS.waweSwapPolygonAddress,
      WaweSwapsABI,
      signer
    );
    let allOpenSwaps = await contractStorage.getAllOpenSwaps();
    let csvResult = "addr;sellAmount;startValue;swapAmount;userRewardAmount\n";
    for (let i = 0; i < allOpenSwaps.length; i++) {
      try {
        let swap = allOpenSwaps[i];
        if (swap[3] === "0x0000000000000000000000000000000000000000") continue;
        const swapAmount = formatUnits(swap[0], "ether");
        const sellAmount = formatUnits(swap[1], "mwei");
        const startValue = formatUnits(swap[2], "mwei");
        const addr = swap[3];
        csvResult += `${addr};${sellAmount};${startValue};${swapAmount};\n`;
      } catch (error) {
        console.error("Error processing swap:", error);
      }
    }
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV3_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
  };

  // Network check – if not on Polygon and not in test mode, prompt a network switch
  if (networkId !== "137" && !SETTINGS.IS_TEST) {
    return (
      <div className="text-center mt-36">
        <p className="mb-4">Switch to Polygon network:</p>
        <ConnectButton />
      </div>
    );
  }

  return (
    <section className="py-12 px-4 mx-auto mb-4 mt-3 container dark:bg-bggrey text-gray-800">
      {/* Optional page-level loader */}
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Processing...</p>
          </div>
        </div>
      )}
  
      <div className="max-w-screen-xl mx-auto">
        <h2 className="py-6 text-3xl font-bold text-center mt-2 text-secondary">
          Geton Wallet – Your Dynamic Asset Hub
        </h2>
        <p className="text-center mb-10 text-gray-600">
          Securely <b>purchase assets, earn rewards, and benefit from dynamic pricing (DPMC)</b>, ensuring fair value growth and smarter asset management.
        </p>
  
        {selectedAccount &&
          selectedAccount.toLowerCase() === SETTINGS.ownerAddress.toLowerCase() && (
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4"
              onClick={exportSwaps}
            >
              Export
            </button>
          )}
  
        {/* Wave Claim Accordion */}
        {waveStakes.length > 0 && (
          <div className="mb-8">
            <h5 className="text-lg font-bold mb-2">WAVE Claim:</h5>
            <small className="text-gray-600 block mb-4">
              Your list of migrated and canceled V1 GBL Bots, releasing a fixed, proportional WAVE amount daily over 90 days.
            </small>
            {waveStakes.map((stake, index) => (
              <div key={index} className="border border-gray-300 rounded mb-2">
                <div
                  className="flex justify-between items-center bg-gray-100 px-4 py-2 cursor-pointer"
                  onClick={() =>
                    setOpenWaveStakes((prev) => ({
                      ...prev,
                      [index]: !prev[index],
                    }))
                  }
                >
                  <div className="flex items-center space-x-2">
                    <img src={SETTINGS.tokenIcons["WAVE"]} alt="WAVE" className="w-4" />
                    <span className="text-sm font-medium">{stake.amount} WAVE</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">Claimable: {stake.claimableAmount} WAVE</span>
                    {openWaveStakes[index] ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
                  </div>
                </div>
                {openWaveStakes[index] && (
                  <div className="px-4 py-2">
                    <div className="mb-2">
                      <label className="block text-gray-700 text-sm font-medium">Start Time</label>
                      <span className="text-gray-600 text-sm">{stake.startTime}</span>
                    </div>
                    <div className="mb-2">
                      <label className="block text-gray-700 text-sm font-medium">Claimed Amount</label>
                      <span className="text-gray-600 text-sm">{stake.claimedAmount} WAVE</span>
                    </div>
                    {parseFloat(stake.claimableAmount) > 0 && (
                      <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded mt-2"
                        onClick={() => claimWaveStake(index)}
                      >
                        Claim WAVE
                      </button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
  
        {/* Grid: Left - Main Form Card, Right - Claim Income & V1 GBL Bots */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column: Main Form Card */}
          <div>
            <div className="bg-white shadow rounded p-6 mb-8">
              <form>
                <div className="mb-6">
                  <InfoModal
                    link="https://docs.waveswaps.com/swaps/v1-gbl-bot"
                    message={
                      <>
                        <strong className="text-blue-900">
                          The expired V1 GBL Bot feature allowed users to leverage GBL as liquidity on selected networks.
                        </strong>{" "}
                        <br />
                        This mechanism revitalized tokens from decentralized exchanges (DEX) and allocated them to the Recycle pool. With the feature now closed, all orders—both active and pending—have been fully executed, and users are eligible to claim their earned balances.
                        <br />
                        While new V1 GBL Bot orders can no longer be initiated, the successful execution of all orders highlights the transparency and immutable nature of blockchain transactions.
                      </>
                    }
                  />
  
                  {selectedAccount && (
                    <div className="bg-blue-500 text-white py-4 px-6 rounded flex justify-between items-center mt-4">
                      <span>Earn 10% from referrals' swaps!</span>
                      <button
                        type="button"
                        className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                        onClick={copyRefferalLink}
                      >
                        <BiCopy className="mr-2" /> Copy
                      </button>
                    </div>
                  )}
  
                  <div className="mt-6">
                    <label className="block text-gray-700 font-medium mb-2">Liquidity Provision</label>
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="flex-1">
                        <input
                          id="tokenAmount"
                          type="text"
                          placeholder="0"
                          className="border border-gray-300 rounded px-3 py-2 w-full"
                          value={swapTokenAmount ?? ""}
                          onChange={handleBuyAmountChange}
                        />
                        <small className="text-gray-500">Balance: {ercBalance}</small>
                      </div>
                      <div className="w-full md:w-1/3">
                        {/* Custom Token Dropdown with logo */}
                        <div className="relative">
                          <button
                            type="button"
                            onClick={() => setShowTokenDropdown((prev) => !prev)}
                            className="flex items-center justify-between border border-gray-300 rounded px-3 py-2 w-full"
                          >
                            {selectedToken ? (
                              <div className="flex items-center space-x-2">
                                <img
                                  src={SETTINGS.tokenIcons[selectedToken.symbol]}
                                  alt={selectedToken.symbol}
                                  className="w-4 h-4"
                                />
                                <span>{selectedToken.symbol}</span>
                              </div>
                            ) : (
                              <span>Select token</span>
                            )}
                            <BiCaretDown className="ml-2" />
                          </button>
                          {showTokenDropdown && (
                            <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded">
                              {buyTokens.map((token) => (
                                <li
                                  key={token.address}
                                  onClick={() => {
                                    handleSelect(token);
                                    setShowTokenDropdown(false);
                                  }}
                                  className="cursor-pointer hover:bg-gray-100 px-3 py-2 flex items-center space-x-2"
                                >
                                  <img
                                    src={SETTINGS.tokenIcons[token.symbol]}
                                    alt={token.symbol}
                                    className="w-4 h-4"
                                  />
                                  <span>{token.symbol}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="text-center my-4">
                  <BiArrowToBottom className="inline-block text-gray-600" size={24} />
                </div>
  
                <div className="mb-6">
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex-1">
                      <label className="block text-gray-700 font-medium mb-2">
                        Referral address: <small>(Optional)</small>
                      </label>
                      <input
                        id="refferalAddress"
                        type="text"
                        placeholder="0x.. (Optional)"
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                        value={refferalAddress ?? ""}
                        onChange={handleRefferalAddressChange}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <label className="block text-gray-700 font-medium mb-2">Revamp token:</label>
                      {/* Custom Revamp Token Dropdown with logo */}
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() => setShowRecycleTokenDropdown((prev) => !prev)}
                          className="flex items-center justify-between border border-gray-300 rounded px-3 py-2 w-full"
                        >
                          {selectedRecycleToken ? (
                            <div className="flex items-center space-x-2">
                              <img
                                src={SETTINGS.tokenIcons[selectedRecycleToken.symbol]}
                                alt={selectedRecycleToken.symbol}
                                className="w-4 h-4"
                              />
                              <span>{selectedRecycleToken.symbol}</span>
                            </div>
                          ) : (
                            <span>Select token</span>
                          )}
                          <BiCaretDown className="ml-2" />
                        </button>
                        {showRecycleTokenDropdown && (
                          <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded">
                            {tokens.map((token) => (
                              <li
                                key={token.address}
                                onClick={() => {
                                  handleSelectRecycle(token);
                                  setShowRecycleTokenDropdown(false);
                                }}
                                className="cursor-pointer hover:bg-gray-100 px-3 py-2 flex items-center space-x-2"
                              >
                                <img
                                  src={SETTINGS.tokenIcons[token.symbol]}
                                  alt={token.symbol}
                                  className="w-4 h-4"
                                />
                                <span>{token.symbol}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
  
                  {selectedRecycleToken && (
                    <div className="bg-gray-100 rounded p-4 mt-4">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm text-gray-600">You send</span>
                        <span className="text-sm text-gray-600">
                          {swapTokenAmount} {selectedToken && selectedToken.symbol}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">Target exit (Income):</span>
                        <span className="text-sm text-gray-600">
                          {gblSellAmount} GBL
                        </span>
                      </div>
                    </div>
                  )}
                </div>
  
                <div className="mb-4 text-left">
                  <small className="text-gray-600">
                    The referral address used for the first time will remain permanently linked to your current wallet.
                    <br />
                    When a referral address is not linked or added, the swap will automatically use and allocate the Genesis referral address.
                    <br />
                    By clicking "Start V1 GBL Bot", you agree with WaveSwaps{" "}
                    <a
                      href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      Terms and Conditions
                    </a>.
                  </small>
                </div>
  
                <div className="text-left">
                  {swapAvailable ? (
                    <button
                      type="button"
                      className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                      onClick={handleStartBot}
                    >
                      Start V1 GBL Bot
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="w-full bg-blue-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                    >
                      Start V1 GBL Bot
                    </button>
                  )}
                  {errorText && <div className="text-red-600 text-sm mt-2">{errorText}</div>}
                  <small className="text-gray-600 block mt-2">
                    Swapping income is distributed when enough total native currency has accumulated to execute the distribution function within the V1 GBL Bot smart contract.
                  </small>
                </div>
              </form>
            </div>
          </div>
  
          {/* Right Column: Claim Income Card and V1 GBL Bots Card */}
          <div>
            {/* V1 GBL Bots Card */}
            <div className="bg-white shadow rounded p-6 mb-8">
              <h5 className="text-lg font-bold mb-2">V1 GBL Bot(s):</h5>
              <small className="text-gray-600 block mb-4">
                Your active V1 GBL Bots, designed to maximize liquidity and streamline token recycling through automated processes.
              </small>
              {userSwaps.map((swap, index) => (
                <div key={index} className="border border-gray-300 rounded mb-2">
                  <div
                    className="flex justify-between items-center bg-gray-100 px-4 py-2 cursor-pointer"
                    onClick={() =>
                      setOpenSwaps((prev) => ({ ...prev, [index]: !prev[index] }))
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <img src={SETTINGS.tokenIcons["GBL"]} alt="GBL" className="w-4" />
                      <span className="text-sm font-medium">{swap.soldAmount} GBL</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <img src={SETTINGS.tokenIcons["GBL"]} alt="GBL" className="w-4" />
                      <span className="text-sm text-gray-600">{swap.mxSellAmount} GBL</span>
                      {openSwaps[index] ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
                    </div>
                  </div>
                  {openSwaps[index] && (
                    <div className="px-4 py-2">
                      <div className="mb-2">
                        <label className="block text-gray-700 text-sm font-medium">Swapping</label>
                        <span className="text-gray-600 text-sm">{swap.amount} GBL</span>
                      </div>
                      <div className="mb-2">
                        <label className="block text-gray-700 text-sm font-medium">Outstanding</label>
                        <span className="text-gray-600 text-sm">{swap.sellAmount} GBL</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Claim Income Card */}
            <div className="bg-white shadow rounded p-6 mb-8">
              <h3 className="text-lg font-bold mb-4">Claim Income</h3>
              <p className="text-center text-gray-600 mb-4">Click below to claim your income</p>
              {unclaimedRewards > 0 && (
                <p className="text-center text-green-600 font-bold mb-4">
                  Accumulated Reward: {unclaimedRewards} GBL
                </p>
              )}
              <div className="text-center">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  onClick={() => setShowCollectModal(true)}
                >
                  Claim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* Modal for Claim Income */}
      {showCollectModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Claim income</h3>
              <button onClick={handleCloseCollectModal} className="text-gray-500 hover:text-gray-700">&times;</button>
            </div>
            <div className="mb-4 text-center">
              <small className="block text-gray-600 mb-1">Available GBL income:</small>
              <input
                type="text"
                disabled
                className="border border-gray-300 rounded px-3 py-2 w-full text-center bg-gray-100"
                value={unclaimedRewards ?? ""}
              />
              <br /><br />
              <small className="block text-gray-600 mb-1">Amount to claim:</small>
              <input
                type="text"
                disabled
                className="border border-gray-300 rounded px-3 py-2 w-full text-center bg-gray-100"
                value={unclaimedRewardsNet ?? ""}
              />
              <br /><br />
              <small className="block text-gray-600 mb-1">Claim income processing fee:</small>
              <input
                type="text"
                disabled
                className="border border-gray-300 rounded px-3 py-2 w-full text-center bg-gray-100"
                value={unclaimedRewardsFee ?? ""}
              />
              <br /><br />
              {unclaimedRewards > 0 && (
                <>
                  {isLoading ? (
                    <div className="inline-block">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500"></div>
                    </div>
                  ) : (
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={collectIncome}
                    >
                      Claim
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="mt-4 text-gray-600 text-sm text-left">
              <b>Swapping income claiming fee requests:</b>
              <ul className="list-disc list-inside">
                <li>Up to 20 GBL: 2%</li>
                <li>From 21 to 100 GBL: 1%</li>
                <li>101 GBL onward: 0.5%</li>
              </ul>
            </div>
          </div>
        </div>
      )}
  
      {/* Confirmation Modal for New V1 GBL Bot Creation */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/2">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Transaction Confirmation</h3>
              <button
                onClick={handleCloseConfirmationModal}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div className="mb-4 text-center">
              <p className="text-green-600 font-bold mb-2">Transaction Successful!</p>
              <p className="text-gray-700">Transaction Hash:</p>
              <a
                href={`https://polygonscan.com/tx/${confirmationTxHash}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline break-all"
              >
                {confirmationTxHash}
              </a>
            </div>
            <div className="text-center">
              <button
                onClick={handleCloseConfirmationModal}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default SwapV1;
