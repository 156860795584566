
const SETTINGS = {

    stakingContract:"0xD0620809A7decC0577D501f76f739743A8B80373",
    globalStakingContract:"0x518ae4F86BC83c98B63046875132fc4E2c16ECF1",
    stakeAddress: "0xb94E0e205d82D2fc339B5b9dc338b7806041A1e2",
    stakeLinkedListAddress: "0x676066F654Ba5fbDD8F05d0142f022daBa5405a2",
    stakeholdersAddress: "0xE0bda672b329b5eEe50fa5B852C6F489D9f55e6B",

    STAKES: [
        {
        id: 1,
        status: "active",
        name: "WaveCoin",
        symbol: "WAVE",
        rewardPool: "0xWalletAddrrss",
        stakingPool: "0xWalletAddrrss",
        description: "Some descriptive text about SuperToken on BSC.",
        contractAddress: "0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e",
        stake: "stake-wave",
        logo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        },
        {
        id: 2,
        status: "active",
        name: "WaveCoin",
        symbol: "WAVE",
        rewardPool: "0xWalletAddrrss",
        stakingPool: "0xWalletAddrrss",
        description: "Some descriptive text about SuperToken on BSC.",
        contractAddress: "0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e",
        stake: "stake-wave",
        logo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        },
        {
        id: 3,
        status: "active",
        name: "WaveCoin",
        symbol: "WAVE",
        rewardPool: "0xWalletAddrrss",
        stakingPool: "0xWalletAddrrss",
        description: "Some descriptive text about SuperToken on BSC.",
        contractAddress: "0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e",
        stake: "stake-wave",
        logo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        },
        // ... add more tokens as needed ...
      ],
};

export default SETTINGS;