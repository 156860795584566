// Web3Transition.js
import React from "react";
import { motion } from "framer-motion";
import { Card, CardContent } from "./ui/Card";

export default function Web3Transition() {
  const steps = [
    {
      title: "Telegram MiniApp",
      description: "Users engage via a Telegram MiniApp, earning initial balances."
    },
    {
      title: "Web2 Onboarding",
      description: "Balances are burned, and users seamlessly onboard into Web2 using email-based access."
    },
    {
      title: "Web3 Node Utilization",
      description: "Eventually, users unlock full Web3 capabilities by utilizing their own nodes."
    }
  ];

  return (
    <section className="container mx-auto py-12 px-6 text-center">
      <h2 className="text-3xl font-bold mb-4 text-[var(--secondary)]">
        Web3 Transition &amp; Node Utilization
      </h2>
      <p className="max-w-2xl mx-auto mb-10 text-[var(--text-color)] opacity-80">
        Users begin with Web2 (email-based access) and, by burning earned balances, gradually 
        transition to Web3—unlocking full decentralization and control over their assets.
      </p>

      <motion.div 
        className="grid grid-cols-1 gap-6 md:grid-cols-3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        {steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: index * 0.3, duration: 0.6 }}
          >
            <Card className="text-center hover:shadow-lg">
              <CardContent>
                <h3 className="text-xl font-semibold text-[var(--secondary)]">
                  {step.title}
                </h3>
                <p className="mt-2 text-[var(--text-color)] opacity-80">
                  {step.description}
                </p>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}
