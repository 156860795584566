// src/components/Modal.js
import React from 'react';

function Modal({ isOpen, onClose, children, hideCloseButton = false }) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75" 
      onClick={onClose}
    >
      <div 
        className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        {/* Conditionally render the close button */}
        {!hideCloseButton && (
          <button 
            onClick={onClose}
            className="mt-4 w-full bg-gray-500 hover:bg-gray-600 text-white py-2 rounded"
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
}

export default Modal;
