// src/config/settings.js
const settings = {
  // API_BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:5540/api',
  API_BASE_URL: process.env.REACT_APP_API_URL || 'https://backend-nodes.geton.global/api',
  WALLET_OTP_REQUIRED: true,
  REOWN_PROJECT_ID: process.env.REACT_APP_REOWN_PROJECT_ID,
  INACTIVITY_TIMEOUT_MINUTES: 60,   // total inactivity allowed
  MODAL_WARNING_SECONDS: 60,        // how long the modal stays

  // Ensure Lit runs in production mode
  LIT_DEV_MODE: process.env.REACT_APP_LIT_DEV_MODE === "true",

  // Prelaunch configuration – remove any extra configurations not needed now.
  LIQUIDITY_TOKEN: "GETON",
  BNB_CHAIN_ID: '0x38',
  POLYGON_FEE: '0.0',
  POLYGON_FEE_RECIPIENT: '0x6F30702C84E4F18A16aDA7aBBDadC342ae078f5d',
  BNB_ESCROW_CONTRACT: '0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D',

  COMPANY_NAME: 'GetonGlobal',
  FOOTER_TEXT: `© ${new Date().getFullYear()} GetonGlobal. All rights reserved.`,  

  // Polygon chain details
  CHAIN: {
    chainId: '0x89', // Polygon Mainnet
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },

  // Contract addresses (if using a unified contract, NODE_CONTRACT would be used)
  // For separate node contracts, use NODE_TYPES below.
  // NODE_CONTRACT: '0x8aA70FAa605888093DA8190E1DdA0FA9b12a9850', // example

  BURN_TOKEN_CONTRACT: '0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE',
  SYSTEM_ADDRESS: "0x6B9efcd5AAd98b448C2A591fD9D667eEB1926280",
  POOL_ADDRESS: "0xF0B17415ea60745bcC0268E4BE1Fe64f6A0354d6",

  // Mapping for separate node contracts (not used directly if using NODE_TYPES)
  NODE_CONTRACTS: {
    1: '0xAddressForType1',
    2: '0xAddressForType2',
    3: '0xAddressForType3',
    4: '0xAddressForType4',
    5: '0xAddressForType5',
    6: '0xAddressForType6',
    7: '0xAddressForType7',
    8: '0xAddressForType8',
  },

  // Node types – each with unique settings and its own contract address.
  NODE_TYPES: [
    {
      id: 1,
      title: 'Verdura',
      imgUrl: 'https://nodes.geton.global/node01.png',
      costMatic: 50, // price in POL
      outputPol: 64, // final output in POL
      levelOne: 16,  // reward level 1 in POL
      LevelTwo: 8,   // reward level 2 in POL
      lifespan: 32,  // in days
      dailyReward: 0.005, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0x44BF86CFEB08e651Cd6C439bDd7B40EDd63ffb2a',
      contractUrl: "https://polygonscan.com/address/0x44BF86CFEB08e651Cd6C439bDd7B40EDd63ffb2a"
    },
    {
      id: 2,
      title: 'Harvest',
      imgUrl: 'https://nodes.geton.global/node02.png',
      costMatic: 100, // price in POL
      outputPol: 136, // final output in POL
      levelOne: 28, // reward level 1 in POL
      LevelTwo: 20, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.010, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0xD13218dDD41Ab3B86b59555b2c1816252D5821Fd',
      contractUrl: "https://polygonscan.com/address/0xD13218dDD41Ab3B86b59555b2c1816252D5821Fd"
    },
    {
      id: 3,
      title: 'Bloom',
      imgUrl: 'https://nodes.geton.global/node03.png',
      costMatic: 200, // price in POL
      outputPol: 288, // final output in POL
      levelOne: 48, // reward level 1 in POL
      LevelTwo: 48, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.021, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0xB50D7911e756535441C7a946c818b5f5865DB712',
      contractUrl: "https://polygonscan.com/address/0xB50D7911e756535441C7a946c818b5f5865DB712"
    },
    {
      id: 4,
      title: 'Flow',
      imgUrl: 'https://nodes.geton.global/node04.png',
      costMatic: 400, // price in POL
      outputPol: 608, // final output in POL
      levelOne: 80, // reward level 1 in POL
      LevelTwo: 112, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.042, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0x3d166Ca4f2C7fc46856e49A43d01C8af93c36303',
      contractUrl: "https://polygonscan.com/address/0x3d166Ca4f2C7fc46856e49A43d01C8af93c36303"
    },
    {
      id: 5,
      title: 'Sprout',
      imgUrl: 'https://nodes.geton.global/node05.png',
      costMatic: 800, // price in POL
      outputPol: 1280, // final output in POL
      levelOne: 128, // reward level 1 in POL
      LevelTwo: 256, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.084, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0xC3b74152f8204CF58c114f09344FeF345434E867',
      contractUrl: "https://polygonscan.com/address/0xC3b74152f8204CF58c114f09344FeF345434E867"
    },
    {
      id: 6,
      title: 'Grove',
      imgUrl: 'https://nodes.geton.global/node07.png',
      costMatic: 1600, // price in POL
      outputPol: 2688, // final output in POL
      levelOne: 320, // reward level 1 in POL
      LevelTwo: 512, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.168, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0x16C584Fc3dBaD92E1Fbb1bd7f0E7c1faD2ebC9D2',
      contractUrl: "https://polygonscan.com/address/0x16C584Fc3dBaD92E1Fbb1bd7f0E7c1faD2ebC9D2"
    },
    {
      id: 7,
      title: 'Revive',
      imgUrl: 'https://nodes.geton.global/node08.png',
      costMatic: 3200, // price in POL
      outputPol: 6144, // final output in POL
      levelOne: 896, // reward level 1 in POL
      LevelTwo: 1152, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.333, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0xAFC3D0AbA1c03FAAf9e15753245209dcaa200FA3',
      contractUrl: "https://polygonscan.com/address/0xAFC3D0AbA1c03FAAf9e15753245209dcaa200FA3"
    },
    {
      id: 8,
      title: 'Root',
      imgUrl: 'https://nodes.geton.global/node09.png',
      costMatic: 6400, // price in POL
      outputPol: 14336, // final output in POL
      levelOne: 2048, // reward level 1 in POL
      LevelTwo: 2560, // reward level 2 in POL
      lifespan: 32, // in days
      dailyReward: 0.666, // tokens per day
      polygonFee: '0.00', // fee for native purchase (in POL)
      contractAddress: '0xd5032e3959cfded6582A10c4caCb796238072197',
      contractUrl: "https://polygonscan.com/address/0xd5032e3959cfded6582A10c4caCb796238072197"
    },
  ],

  // Example assets
  ASSETS: [
    {
      key: 'taptoken',
      name: 'TapCoin',
      symbol: 'TAP',
      logo: 'https://tokens.waveswaps.com/images/tokens/TAP.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0x44BF86CFEB08e651Cd6C439bDd7B40EDd63ffb2a',
      polygonTokenAddress: '0xA6f0BAA04FcdB4ED733990972c022a7377f35264',
      decimals: "8",
    },
    {
      key: 'grosh',
      name: 'GroshCoin',
      symbol: 'GROSH',
      logo: 'https://tokens.waveswaps.com/images/tokens/GROSH.svg',
      defaultPrice: 0.05,
      bnbTokenAddress: '0xb67B9a92D902F8beadEe9846CDde40a10F198808',
      polygonTokenAddress: '0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220',
      decimals: "8",
    },
    {
      key: 'egrosh',
      name: 'EuroGrosh',
      symbol: 'eGROSH',
      logo: 'https://tokens.waveswaps.com/images/tokens/eGROSH.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0x542e258550dc85ede56d9480bc6a68c07158ed24',
      polygonTokenAddress: '0xBe2Df1188E8372F998F4951dBDF95629B6B70197',
      decimals: "8",
    },
    {
      key: 'geton',
      name: 'GetonCoin',
      symbol: 'GETON',
      logo: 'https://tokens.waveswaps.com/images/tokens/GETON.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51',
      polygonTokenAddress: '0x1b9d6a5fc2382a97777fc56cebb109bca815c3be',
      decimals: "8",
    },
    {
      key: 'pipl',
      name: 'PiplCoin',
      symbol: 'PIPL',
      logo: 'https://tokens.waveswaps.com/images/tokens/PIPL.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xE12723848699AdcbFF466050cE17Cbf5C6156720',
      polygonTokenAddress: '0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc',
      decimals: "8",
    },
    {
      key: 'pron',
      name: 'PronCoin',
      symbol: 'PRON',
      logo: 'https://tokens.waveswaps.com/images/tokens/PRON.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393',
      polygonTokenAddress: '0x2a6af8da13b36f925632a404a3161fe344d8f469',
      decimals: "8",
    },
    {
      key: 'los',
      name: 'LifeOnScreen',
      symbol: 'LOS',
      logo: 'https://tokens.waveswaps.com/images/tokens/LOS.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xc1463935d11EB0C9a06530F387077053Ebe4500B',
      polygonTokenAddress: '0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494',
      decimals: "8",
    },
    {
      key: 'apod',
      name: 'AirPod',
      symbol: 'APOD',
      logo: 'https://tokens.waveswaps.com/images/tokens/APOD.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9',
      polygonTokenAddress: '0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae',
      decimals: "8",
    },
    {
      key: 'carb',
      name: 'CarbCoin',
      symbol: 'CARB',
      logo: 'https://tokens.waveswaps.com/images/tokens/CARB.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d',
      polygonTokenAddress: '0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC',
      decimals: "8",
    },
    {
      key: 'cbdx',
      name: 'CryptoBoard',
      symbol: 'CBDX',
      logo: 'https://tokens.waveswaps.com/images/tokens/CBDX.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E',
      polygonTokenAddress: '0x7607Cb4a4538a1a90501bc919F495889b005A63b',
      decimals: "8",
    },
    {
      key: 'meta',
      name: 'MetaCoin',
      symbol: 'META',
      logo: 'https://tokens.waveswaps.com/images/tokens/META.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xfe1e4b7515b933faaf7b32e569eb245b7542b03c',
      polygonTokenAddress: '0xd51e7065b942d5a5886915c6bebf42600d546162',
      decimals: "18",
    },
    {
      key: 'code',
      name: 'CryptoCode',
      symbol: 'CODE',
      logo: 'https://tokens.waveswaps.com/images/tokens/CODE.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xdc514814ece982fce52d5b5dfd8d22fa652fb942',
      polygonTokenAddress: '0x2d04d3f49d11c11ad99caca515d209c741c1bd9b',
      decimals: "18",
    },
    {
      key: 'gbl',
      name: 'GlobalToken',
      symbol: 'GBL',
      logo: 'https://tokens.waveswaps.com/images/tokens/GBL.svg',
      defaultPrice: 0.1,
      bnbTokenAddress: '0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6',
      polygonTokenAddress: '0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6',
      decimals: "18",
    },
  ],

  // A collection of tokens
  TOKENS: [
    {
      id: 1,
      status: "active",
      name: "GetonCoin",
      symbol: "GETON",
      decimals: 18,
      description: "GetonCoin is the native digital currency of the Geton Ecosystem. It fuels automated liquidity unlocking through Renewal Rigs and powers seamless participation in decentralized finance—enabling staking, governance, and rewarding user engagement.",
      stablePrice: "300",
      contractAddress: "0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
      liquidityContract: "0xbF8dF3D3926eCA2aB3EB66F51947fc2621abbc6B",
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeSymbol: "POL",
      logo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
      supplyAddress: "0xbF8dF3D3926eCA2aB3EB66F51947fc2621abbc6B",
    },
    {
      id: 2,
      status: "active",
      name: "Global Token",
      symbol: "GBL",
      decimals: 18,
      description: "Global Token (GBL) is the liquidity backbone of WaveSwaps, designed to boost cross-chain interoperability and streamline asset swaps. It ensures that liquidity is maximized across the Geton Ecosystem while supporting dynamic trading mechanisms.",
      stablePrice: "30", // cost per token, in POL
      contractAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      liquidityContract: "0x08130Cc4FCA3744FCbFe1D663552bc15958bd201",
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeSymbol: "POL",
      logo: "https://tokens.waveswaps.com/images/tokens/GBL.svg",
      supplyAddress: "0x08130Cc4FCA3744FCbFe1D663552bc15958bd201",
    },

    {
      id: 3,
      status: "active",
      name: "WaveCoin",
      symbol: "WAVE",
      decimals: 18,
      description: "WaveCoin is the native currency of WaveSwaps within the Geton Ecosystem. It fuels Liquidity Nodes and powers the innovative PPG game, enhancing cross-chain asset swaps and automated liquidity unlocking. Designed for everyday utility and not for long-term investment, WaveCoin offers seamless transaction efficiency while serving as a cornerstone for decentralized finance innovations.",
      stablePrice: "0.003",
      contractAddress: "0x3c2d4cff75434205ec98980f7b506d035ca7d923",
      liquidityContract: "0x396F7AeeF35aAc7eD4B6cFCc1398679964265fDF",
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeSymbol: "POL",
      logo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
      supplyAddress: "0x396F7AeeF35aAc7eD4B6cFCc1398679964265fDF",
    },
    {
      id: 4,
      status: "not-active",
      name: "DAOToken",
      symbol: "DAO",
      decimals: 0,
      description: "DAOToken is the governance token of the Geton Ecosystem. It empowers holders with direct influence over platform decisions and strategic oversight through DAO-based governance, ensuring fair revenue distribution and long-term sustainability.",
      stablePrice: "3",
      contractAddress: "0x8053563b0678a2Cd900BAbc8756cC55e25685215",
      liquidityContract: "0x3b983F1E77B462Ad76EcbBa5FE7D0f5940A5aB35",
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeSymbol: "POL",
      logo: "https://tokens.waveswaps.com/images/tokens/DAO.svg",
      supplyAddress: "0x3b983F1E77B462Ad76EcbBa5FE7D0f5940A5aB35",
    },
    // ... add more tokens as needed ...
  ],
};

window.__litDevMode = settings.LIT_DEV_MODE;

export default settings;

// Export NODE_ASSET_REQUIREMENTS (only once)
export const NODE_ASSET_REQUIREMENTS = {
  1: { taptoken: 1500, grosh: 12.50, egrosh: 12.50, geton: 0.25, pipl: 8.00, pron: 0.02, los: 0.75, apod: 12.50, carb: 0.80, cbdx: 1.80, meta: 15.00, code: 15.00, gbl: 15.00 },
  2: { taptoken: 3000, grosh: 25.00, egrosh: 25.00, geton: 0.50, pipl: 16.00, pron: 0.03, los: 1.25, apod: 25.00, carb: 1.60, cbdx: 3.60, meta: 30.00, code: 30.00, gbl: 30.00 },
  3: { taptoken: 6000, grosh: 50.00, egrosh: 50.00, geton: 1.00, pipl: 32.00, pron: 0.06, los: 2.50, apod: 50.00, carb: 3.20, cbdx: 7.20, meta: 60.00, code: 60.00, gbl: 60.00 },
  4: { taptoken: 12000, grosh: 100.00, egrosh: 100.00, geton: 2.00, pipl: 64.00, pron: 0.12, los: 5.00, apod: 100.00, carb: 6.40, cbdx: 14.40, meta: 120.00, code: 120.00, gbl: 120.00 },
  5: { taptoken: 24000, grosh: 200.00, egrosh: 200.00, geton: 4.00, pipl: 128.00, pron: 0.24, los: 10.00, apod: 200.00, carb: 12.80, cbdx: 28.80, meta: 240.00, code: 240.00, gbl: 240.00 },
  6: { taptoken: 48000, grosh: 400.00, egrosh: 400.00, geton: 8.00, pipl: 256.00, pron: 0.48, los: 20.00, apod: 400.00, carb: 25.60, cbdx: 57.60, meta: 480.00, code: 480.00, gbl: 480.00 },
  7: { taptoken: 96000, grosh: 800.00, egrosh: 800.00, geton: 16.00, pipl: 512.00, pron: 1.00, los: 40.00, apod: 800.00, carb: 51.20, cbdx: 125.20, meta: 960.00, code: 960.00, gbl: 960.00 },
  8: { taptoken: 192000, grosh: 1600.00, egrosh: 1600.00, geton: 32.00, pipl: 1024.00, pron: 2.00, los: 80.00, apod: 1600.00, carb: 102.40, cbdx: 230.40, meta: 1920.00, code: 1920.00, gbl: 1920.00 },
};

