const SETTINGS = {

    ASSETS: {
        WaveCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        chainExplorer: "https://polygonscan.com/token/0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
        name: "WaveCoin",
        symbol: "WAVE",
        walletAddress: '0x0ef8291c70e6fafdd16e7b648a73bb4fa7af31dd',
        wallet: '0xcFbA2075F6F4bd815aef59b8554e17a20da67698',
        tokenAddress1: '0x3c2d4cfF75434205Ec98980f7B506d035CA7d923',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x0ef8291c70e6fafdd16e7b648a73bb4fa7af31dd",
        chart1: "https://dexscreener.com/polygon/0x25c0f0f0a7ab69883ecc32040b1976cb6fc4ef98",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE',
        price:{
            56:0.01,
            137:1,
            }
      },
      GlobalToken: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GBL.svg",
        chainExplorer: "https://polygonscan.com/token/0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
        name: "GlobalToken",
        symbol: "GBL",
        walletAddress: '0xe0b2b83718400188528f914b9991ff3246fa009b',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 17900000,
        chart: "https://coinmarketcap.com/currencies/global-token/",
        chart1: "https://dexscreener.com/polygon/0xe0b2b83718400188528f914b9991ff3246fa009b",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'XXE1HV4MS8KMJ69PN38JRIGFUMT8DKBWRY',
        price:{
            56:0.01,
            137:1,
            }
      },
      CryptoCode: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/CODE.svg",
        chainExplorer: "https://polygonscan.com/token/0x2d04d3f49d11c11ad99caca515d209c741c1bd9b",
        name: "CryptoCode",
        symbol: "CODE",
        walletAddress: '0xade8045983eed33d604dbaa56e32a22b63ca9b12',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x2d04d3f49d11c11ad99caca515d209c741c1bd9b',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 21000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xade8045983eed33d604dbaa56e32a22b63ca9b12/",
        chart1: "https://dexscreener.com/polygon/0x0d217b8ae66483fd4b29a9930ee17be265636eda",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'IKQ8JR39YAI2ICYBAFD2IUB2AKHR79Y2TR',
        price:{
            56:0.01,
            137:1,
            }
      },
      MetaCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/META.svg",
        chainExplorer: "https://polygonscan.com/token/0xD51E7065B942D5A5886915C6BEbf42600D546162",
        name: "MetaCoin",
        symbol: "META",
        walletAddress: '0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xd51e7065b942d5a5886915c6bebf42600d546162',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128/",
        chart1: "https://dexscreener.com/polygon/0x35e6981b70b72358d3142b7dd7f1d9ddce417b8a",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'GYZEQUPJMXVFVX4T2WVR7BQ4AIRZGSJK2T',
        price:{
            56:0.01,
            137:1,
            }
      },
      PiplCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PIPL.svg",
        chainExplorer: "https://polygonscan.com/token/0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc",
        name: "PiplCoin",
        symbol: "PIPL",
        walletAddress: '0xc7d018cf9f5fd54d466321cf9398420c3bd26fca',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 261797900,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xc7d018cf9f5fd54d466321cf9398420c3bd26fca/",
        chart1: "https://dexscreener.com/polygon/0x38e6d06cd9c561213a32ce980c13e24683fb7f62",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'G43J4K2HTUD7DZTMVUNWPNS19H4BBJWCEN',
        price:{
            56:0.01,
            137:1,
            }
      },
      GetonCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
        chainExplorer: "https://polygonscan.com/token/0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
        name: "GetonCoin",
        symbol: "GETON",
        walletAddress: '0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x1b9d6a5fc2382a97777fc56cebb109bca815c3be',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1542000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9/",
        chart1: "https://dexscreener.com/polygon/0xb5ad0e4335d8e92e9008058c72b94779c381e85a",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: '55K63YUSMCUSKPJ2RXGFJPBEGCNNXD91CA',
        price:{
            56:0.01,
            137:1,
            }
      },
      GroshCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GROSH.svg",
        chainExplorer: "https://polygonscan.com/token/0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220",
        name: "GroshCoin",
        symbol: "GROSH",
        walletAddress: '0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b/",
        chart1: "https://dexscreener.com/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'EVXHEG39R4CAW1PSSNB5SS4V9XCSY791V7',
        price:{
            56:0.01,
            137:1,
            }
      },
      PronCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PRON.svg",
        chainExplorer: "https://polygonscan.com/token/0x2a6af8da13b36f925632a404a3161fe344d8f469",
        name: "PronCoin",
        symbol: "PRON",
        walletAddress: '0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x2a6af8da13b36f925632a404a3161fe344d8f469',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 69000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24/",
        chart1: "https://dexscreener.com/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN',
        price:{
            56:0.01,
            137:1,
            }
      },
      LifeOnScreen: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/LOS.svg",
        chainExplorer:
          "https://polygonscan.com/token/0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
        name: "LifeOnScreen",
        symbol: "LOS",
        walletAddress: "0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9",
        wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
        tokenAddress1: "0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
        tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        tokenDecimals1: 18, // Decimals for tokenAddress1
        tokenDecimals2: 6, // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x2ff1ecd69b4e4c60b0b4c7e58207e9a1df1fb9f9/",
        chart1: "#",
        chainId: 137, // Polygon Mainnet chain ID
        network: "Polygon Mainnet", // Name of the network
        rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
        currencySymbol: "POL", // Currency symbol
        blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
        apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
      },
      CarbCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/CARB.svg",
        chainExplorer:
          "https://polygonscan.com/token/0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
        name: "CarbCoin",
        symbol: "CARB",
        walletAddress: "0x7401933b0852287637aEa48d87cA8Ec4359A9509",
        wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
        tokenAddress1: "0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
        tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        tokenDecimals1: 18, // Decimals for tokenAddress1
        tokenDecimals2: 6, // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xdd5755f257dbebc82780bf66888acafbd94da77d/",
        chart1: "#",
        chainId: 137, // Polygon Mainnet chain ID
        network: "Polygon Mainnet", // Name of the network
        rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
        currencySymbol: "POL", // Currency symbol
        blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
        apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
      },
      CryptoBoard: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/CBDX.svg",
        chainExplorer:
          "https://polygonscan.com/token/0x7607Cb4a4538a1a90501bc919F495889b005A63b",
        name: "CryptoBoard",
        symbol: "CBDX",
        walletAddress: "0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd",
        wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
        tokenAddress1: "0x7607Cb4a4538a1a90501bc919F495889b005A63b",
        tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        tokenDecimals1: 18, // Decimals for tokenAddress1
        tokenDecimals2: 6, // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x8cb95cFE2c66252C6D3A8399BD7f3e0c06DDC7Fd/",
        chart1: "#",
        chainId: 137, // Polygon Mainnet chain ID
        network: "Polygon Mainnet", // Name of the network
        rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
        currencySymbol: "POL", // Currency symbol
        blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
        apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
      },
      AirPod: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/APOD.svg",
        chainExplorer:
          "https://polygonscan.com/token/0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
        name: "AirPod Token",
        symbol: "APOD",
        walletAddress: "0x1401483F86940E3a2ED890fa75c01E3D8A099A31",
        wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
        tokenAddress1: "0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
        tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        tokenDecimals1: 18, // Decimals for tokenAddress1
        tokenDecimals2: 6, // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x1401483F86940E3a2ED890fa75c01E3D8A099A31/",
        chart1: "#",
        chainId: 137, // Polygon Mainnet chain ID
        network: "Polygon Mainnet", // Name of the network
        rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
        currencySymbol: "POL", // Currency symbol
        blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
        apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
      },
      EuroGrosh: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/eGROSH.svg",
        chainExplorer:
          "https://polygonscan.com/token/0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
        name: "EuroGrosh",
        symbol: "eGROSH",
        walletAddress: "0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e",
        wallet: "0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067",
        tokenAddress1: "0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
        tokenAddress2: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        tokenDecimals1: 18, // Decimals for tokenAddress1
        tokenDecimals2: 6, // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x2269384544dC73F5F8F3D92312519fa9dFB1fb7e/",
        chart1: "https://dexscreener.com/bsc/0x9ada8be10b186daba6a5c8970bbe7f7785fcc8bd",
        chainId: 137, // Polygon Mainnet chain ID
        network: "Polygon Mainnet", // Name of the network
        rpcUrl: "https://polygon-mainnet.infura.io", // New RPC URL
        currencySymbol: "POL", // Currency symbol
        blockExplorerUrl: "https://polygonscan.com/", // Block explorer URL
        apiKey: "QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN",
      },
      DAOToken: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/DAO.svg",
        chainExplorer: "https://polygonscan.com/token/0x8053563b0678a2Cd900BAbc8756cC55e25685215",
        name: "DAOToken",
        symbol: "DAO",
        walletAddress: 'OX',
        wallet: '0X',
        tokenAddress1: '0x8053563b0678a2Cd900BAbc8756cC55e25685215',
        tokenAddress2: '0X',
        tokenDecimals1: 0,  // Decimals for tokenAddress1
        tokenDecimals2: 0,   // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "#",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE',
        price:{
            56:0,
            137:0,
            }  
        },
    WaveShare: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WSHARE.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e",
        name: "WaveShare",
        symbol: "WSHARE",
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x51C2b96740aa4591E84747522559B870e3F531C3',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        totalSupply: 100,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chart1: "https://dexscreener.com/polygon/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        price:{
            56:0.01,
            137:1,
            }
        },
      
      // Add other assets similarly...
    },

    Tokens: {
        GlobalToken: {
            tokenName: "GlobalToken",
            networks: {
                ethereum: {
                    totalSupply: "17,900,000",
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    swapWallet: "0x000000000000000000000000000000000000",
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "17,900,000",
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "17,900,000",
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "17,900,000",
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "17,900,000",
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                }
            }
          },
        CryptoCode: {
            tokenName: "CryptoCode",
            networks: {
                ethereum: {
                    totalSupply: "1,000,000",
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://etherscan.io/address/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",  
                    tokenUrl: "https://etherscan.io/token/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "21,000,000",
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 8,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://bscscan.com/address/0xdc514814ece982fce52d5b5dfd8d22fa652fb942",  
                    tokenUrl: "https://bscscan.com/token/0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "1,000,000",
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://polygonscan.com/address/0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",  
                    tokenUrl: "https://polygonscan.com/token/0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "1,000,000",
                    apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                    tokenAddress: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://arbiscan.io/address/0x1E2A7824942754c30D8a24C1de8B383d417b804d",  
                    tokenUrl: "https://arbiscan.io/token/0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "1,000,000",
                    apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                    tokenAddress: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x1E2A7824942754c30D8a24C1de8B383d417b804d",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    network: 'OP Mainnet'
                }
            }
        },
        MetaCoin: {
            tokenName: "MetaCoin",
            networks: {
                ethereum: {
                    totalSupply: "1,000,000", 
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC", 
                    tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac", 
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18, 
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://etherscan.io/address/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",  
                    tokenUrl: "https://etherscan.io/token/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "210,000,000", 
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                    tokenAddress: "0xfe1e4b7515b933faaf7b32e569eb245b7542b03c", 
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://bscscan.com/address/0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",  
                    tokenUrl: "https://bscscan.com/token/0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "1,000,000", 
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                    tokenAddress: "0xD51E7065B942D5A5886915C6BEbf42600D546162", 
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18, 
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://polygonscan.com/address/0xD51E7065B942D5A5886915C6BEbf42600D546162",  
                    tokenUrl: "https://polygonscan.com/token/0xD51E7065B942D5A5886915C6BEbf42600D546162",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "1,000,000",
                    apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                    tokenAddress: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://arbiscan.io/address/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",  
                    tokenUrl: "https://arbiscan.io/token/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "1,000,000",
                    apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                    tokenAddress: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    network: 'OP Mainnet'
                }
            }
        },
        PiplCoin: {
            tokenName: "PiplCoin",
            networks: {
            ethereum: {
                totalSupply: "210,000,000", 
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC", 
                tokenAddress: "0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4", 
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                swapWallet: "0x000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://etherscan.io/address/0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",  
                tokenUrl: "https://etherscan.io/token/0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000", 
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                tokenAddress: "0xE12723848699AdcbFF466050cE17Cbf5C6156720", 
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://bscscan.com/address/0xE12723848699AdcbFF466050cE17Cbf5C6156720",  
                tokenUrl: "https://bscscan.com/token/0xE12723848699AdcbFF466050cE17Cbf5C6156720",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000", 
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                tokenAddress: "0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc", 
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://polygonscan.com/address/0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",  
                tokenUrl: "https://polygonscan.com/token/0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://arbiscan.io/address/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",  
                tokenUrl: "https://arbiscan.io/token/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                network: 'OP Mainnet'
            }
            }
        },
        GetonCoin: {
            tokenName: "GetonCoin",
            networks: {
            ethereum: {
                totalSupply: "1,542,000,000",
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB",
                tokenAddress: "0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://etherscan.io/address/0xF1D353199fdf1F1B8A1ccD641611129137a40aec",  
                tokenUrl: "https://etherscan.io/token/0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "1,542,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://bscscan.com/address/0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",  
                tokenUrl: "https://bscscan.com/token/0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://polygonscan.com/address/0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",  
                tokenUrl: "https://polygonscan.com/token/0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://arbiscan.io/address/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",  
                tokenUrl: "https://arbiscan.io/token/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                network: 'OP Mainnet'
            }
            }
        },
        GroshCoin: {
            tokenName: "GroshCoin",
            networks: {
            ethereum: {
                totalSupply: "210,000,000", 
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB", 
                tokenAddress: "0xd92B3100e19bef7A32aef1451D891f99F9316e13", 
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://etherscan.io/address/0xd92B3100e19bef7A32aef1451D891f99F9316e13",  
                tokenUrl: "https://etherscan.io/token/0xd92B3100e19bef7A32aef1451D891f99F9316e13",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000", 
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                tokenAddress: "0xb67B9a92D902F8beadEe9846CDde40a10F198808", 
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://bscscan.com/address/0xb67B9a92D902F8beadEe9846CDde40a10F198808",  
                tokenUrl: "https://bscscan.com/token/0xb67B9a92D902F8beadEe9846CDde40a10F198808",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000", 
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                tokenAddress: "0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220", 
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://polygonscan.com/address/0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",  
                tokenUrl: "https://polygonscan.com/token/0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                network: 'OP Mainnet'
            }
            }
        },
        PronCoin: {
            tokenName: "PronCoin",
            networks: {
            ethereum: {
                totalSupply: "69,000,000",
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB",
                tokenAddress: "0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x00",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://etherscan.io/address/0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",  
                tokenUrl: "https://etherscan.io/token/0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "69,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://bscscan.com/address/0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",  
                tokenUrl: "https://bscscan.com/token/0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://polygonscan.com/address/0x2a6aF8DA13B36F925632a404A3161fE344d8f469",  
                tokenUrl: "https://polygonscan.com/token/0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://arbiscan.io/address/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",  
                tokenUrl: "https://arbiscan.io/token/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x3692668069fe43771f46EEA1aaa28064debFFA9c",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x3692668069fe43771f46EEA1aaa28064debFFA9c",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x3692668069fe43771f46EEA1aaa28064debFFA9c",
                network: 'OP Mainnet'
            }
            }
        },
        LifeOnScreen: {
            tokenName: "LifeOnScreen",
            networks: {
            ethereum: {
                totalSupply: "1,125,000,000",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x705794a33b0aCdD9C47aD363c82a45A20056F734",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "https://etherscan.io/address/0x705794a33b0aCdD9C47aD363c82a45A20056F734",  
                tokenUrl: "https://etherscan.io/token/0x705794a33b0aCdD9C47aD363c82a45A20056F734",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "1,000,048",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xc1463935d11EB0C9a06530F387077053Ebe4500B",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "https://bscscan.com/address/0xc1463935d11EB0C9a06530F387077053Ebe4500B",  
                tokenUrl: "https://bscscan.com/token/0xc1463935d11EB0C9a06530F387077053Ebe4500B",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1000000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "https://polygonscan.com/address/0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",  
                networkUrl: "",  
                tokenUrl: "https://polygonscan.com/token/0x2e14b5cfE4acaCCD18a206B4d2B0332E7dBF8494",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        CarbCoin: {
            tokenName: "CarbCoin",
            networks: {
            ethereum: {
                totalSupply: "743,100,000",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0xA517a46Baad6B054A76bD19c46844f717fe69fea",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "https://etherscan.io/address/0xA517a46Baad6B054A76bD19c46844f717fe69fea",  
                tokenUrl: "https://etherscan.io/token/0xA517a46Baad6B054A76bD19c46844f717fe69fea",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "743,100,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "https://bscscan.com/address/0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",  
                tokenUrl: "https://bscscan.com/token/0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1000000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "https://polygonscan.com//address/0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",  
                networkUrl: "",  
                tokenUrl: "https://polygonscan.com/token/0x2F6fef0C258d29e4863EDB970831CB9a8cB02bdC",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        CryptoBoard: {
            tokenName: "CryptoBoard",
            networks: {
            ethereum: {
                totalSupply: "6,184,089",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "https://etherscan.io/address/0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",  
                tokenUrl: "https://etherscan.io/token/0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "6,184,089",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "https://bscscan.com/address/0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",  
                tokenUrl: "https://bscscan.com/token/0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1000000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x7607Cb4a4538a1a90501bc919F495889b005A63b",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg", 
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "https://polygonscan.com//address/0x7607Cb4a4538a1a90501bc919F495889b005A63b",  
                networkUrl: "",  
                tokenUrl: "https://polygonscan.com/token/0x7607Cb4a4538a1a90501bc919F495889b005A63b",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        AirPodToken: {
            tokenName: "AirPodToken",
            networks: {
            ethereum: {
                totalSupply: "147,792,670",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x99bC08DB67F52010f2D6017b7aD968808113dB10",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "https://etherscan.io/address/0x99bC08DB67F52010f2D6017b7aD968808113dB10",  
                tokenUrl: "https://etherscan.io/token/0x99bC08DB67F52010f2D6017b7aD968808113dB10",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "147,792,670",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "https://bscscan.com/address/0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",  
                tokenUrl: "https://bscscan.com/token/0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1000000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "https://polygonscan.com//address/0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",  
                networkUrl: "",  
                tokenUrl: "https://polygonscan.com/token/0x1681EfDb0F48eF0485A7daFd62Da3912D6F932Ae",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        eGROSH: {
            tokenName: "eGROSH",
            networks: {
            ethereum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x542e258550dc85ede56d9480bc6a68c07158ed24",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "https://bscscan.com/address/0x542e258550dc85ede56d9480bc6a68c07158ed24",  
                tokenUrl: "https://bscscan.com/token/0x542e258550dc85ede56d9480bc6a68c07158ed24",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1000000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "https://polygonscan.com//address/0xBe2Df1188E8372F998F4951dBDF95629B6B70197",  
                networkUrl: "",  
                tokenUrl: "https://polygonscan.com/token/0xBe2Df1188E8372F998F4951dBDF95629B6B70197",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        GMAAR: {
            tokenName: "GMAAR",
            networks: {
            ethereum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "57000000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x7A81eeB086e4502bb8A022F804831575db42Fa07",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "https://bscscan.com/address/0x7A81eeB086e4502bb8A022F804831575db42Fa07",  
                tokenUrl: "https://bscscan.com/token/0x7A81eeB086e4502bb8A022F804831575db42Fa07",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        }

    },
}


export default SETTINGS;