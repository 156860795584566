import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import SETTINGS from "../config/wshare";

// Helper function to truncate long wallet addresses
const truncate = (address) => {
  if (!address || address.length < 20) return address;
  return `${address.slice(0, 8)}...${address.slice(-8)}`;
};

// Helper function to darken a hex color by a given percentage
const darkenColor = (hex, percent) => {
  hex = hex.replace("#", "");
  let num = parseInt(hex, 16);
  let r = (num >> 16) - Math.floor(255 * percent);
  let g = ((num >> 8) & 0x00ff) - Math.floor(255 * percent);
  let b = (num & 0x0000ff) - Math.floor(255 * percent);
  r = r < 0 ? 0 : r;
  g = g < 0 ? 0 : g;
  b = b < 0 ? 0 : b;
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)}`;
};

const WSharePieChart = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Map shareholders from config into pie chart data
  const shareholders = Object.keys(SETTINGS.SHAREHOLDERS).map((key) => {
    const asset = SETTINGS.SHAREHOLDERS[key];
    return {
      title: asset.shareholder, // Use shareholder name as title
      value: asset.percentage,  // Percentage for the slice
      color: asset.shareColour || "#8884d8", // Fallback colour if not defined
      key,
      asset, // Attach entire asset object for tooltip info
    };
  });

  // Modify slice color on hover
  const pieData = shareholders.map((entry, index) => ({
    ...entry,
    color: index === hoveredIndex ? darkenColor(entry.color, 0.2) : entry.color,
  }));

  return (
    <div className="relative max-w-4xl mx-auto p-8">
      <p className="text-center text-gray-600 dark:text-gray-300 mb-8">
        Distribution of WaveShare among liquidity providers.
      </p>

      <div
        className="relative flex justify-center items-center"
        onMouseLeave={() => setHoveredIndex(null)}
      >
        {/* Tooltip positioned above the chart */}
        {hoveredIndex !== null && (
          <div
            className="absolute z-10 bg-white dark:bg-gray-800 shadow-lg p-4 rounded border border-gray-200 dark:border-gray-700 text-sm text-gray-800 dark:text-gray-200 transition-colors duration-300"
            style={{ bottom: "105%", left: "50%", transform: "translateX(-50%)" }}
          >
            <p>
              <span className="font-semibold">Asset:</span>{" "}
              {shareholders[hoveredIndex].asset.name} (
              {shareholders[hoveredIndex].asset.symbol})
            </p>
            <p>
              <span className="font-semibold">ShareHolder:</span>{" "}
              {shareholders[hoveredIndex].asset.shareholder}
            </p>
            <p>
              <span className="font-semibold">Wallet:</span>{" "}
              {truncate(shareholders[hoveredIndex].asset.wallet)}
            </p>
            <p>
              <span className="font-semibold">Share:</span>{" "}
              {shareholders[hoveredIndex].asset.percentage.toLocaleString()}%
            </p>
          </div>
        )}

        {/* Pie Chart Container */}
        <div className="p-4 w-full max-w-[500px] h-[500px]">
          <PieChart
            data={pieData}
            style={{ width: "100%", height: "100%" }}
            lineWidth={81}
            paddingAngle={2}
            animate
            radius={40}
            viewBoxSize={[100, 100]}
            segmentsShift={(index) => (index === hoveredIndex ? 5 : 0)}
            segmentsStyle={{ cursor: "pointer", transition: "all 0.3s ease" }}
            onMouseOver={(_, index) => setHoveredIndex(index)}
            label={({ dataEntry }) => `${dataEntry.value}%`}
            labelStyle={{
              fontSize: "3px",
              zIndex: "9999",
              fontFamily: "sans-serif",
            }}
            labelPosition={104}
          />
        </div>
      </div>
    </div>
  );
};

export default WSharePieChart;
