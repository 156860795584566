// src/pages/Dashboard.js
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import DashboardRow from '../components/DashboardRow';
import settings from '../config/settings';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

const ERC20_ABI = [
  "function balanceOf(address) view returns (uint256)"
];

function Dashboard() {
  const { user, userBalances, refreshUserBalances } = useContext(UserContext);
  const navigate = useNavigate();

  const [onChainBalances, setOnChainBalances] = useState({});
  const [isDashboardLoading, setIsDashboardLoading] = useState(true);

  async function fetchOnChainBalances() {
    if (!window.ethereum) {
      console.warn("MetaMask not found");
      setOnChainBalances({});
      setIsDashboardLoading(false);
      return;
    }
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      // Check if MetaMask has any accounts connected
      const accounts = await provider.send("eth_accounts", []);
      if (!accounts || accounts.length === 0) {
        // MetaMask is disconnected; clear on-chain balances
        setOnChainBalances({});
        setIsDashboardLoading(false);
        return;
      }
      // Request accounts if needed
      await provider.send("eth_requestAccounts", []);
      // Optionally, ensure the provider is on the correct chain
      const chainId = await provider.send("eth_chainId", []);
      if (chainId !== settings.BNB_CHAIN_ID) {
        try {
          await provider.send("wallet_switchEthereumChain", [
            { chainId: settings.BNB_CHAIN_ID },
          ]);
        } catch (err) {
          console.error("Please switch to BNB Smart Chain in MetaMask", err);
          setIsDashboardLoading(false);
          return;
        }
      }
      const signer = await provider.getSigner();
      const userAddress = await signer.getAddress();

      const balances = {};
      await Promise.all(
        settings.ASSETS.map(async (asset) => {
          let rawBalance;
          const decimals = parseInt(asset.decimals, 10) || 18;
          if (asset.bnbTokenAddress) {
            const tokenContract = new ethers.Contract(asset.bnbTokenAddress, ERC20_ABI, provider);
            rawBalance = await tokenContract.balanceOf(userAddress);
          } else {
            rawBalance = await provider.getBalance(userAddress);
          }
          const formattedBalance = ethers.formatUnits(rawBalance, decimals);
          balances[asset.key] = formattedBalance;
        })
      );
      setOnChainBalances(balances);
    } catch (err) {
      console.error("Error fetching on-chain balances:", err);
    } finally {
      setIsDashboardLoading(false);
    }
  }

  useEffect(() => {
    fetchOnChainBalances();

    if (window.ethereum) {
      const handleAccountsChanged = async () => {
        setIsDashboardLoading(true);
        await refreshUserBalances();
        await fetchOnChainBalances();
      };
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, []);

  // Filter assets: show a row if the user is connected (off-chain) and either off-chain or on-chain balance > 0.
  const filteredAssets = settings.ASSETS.filter((asset) => {
    if (!user) return false;
    // offChain balance comes from email-based connection
    const offChain = parseFloat(userBalances[asset.key] || "0");
    // onChain balance is from MetaMask (will be "0" if MetaMask is disconnected)
    const onChain = parseFloat(onChainBalances[asset.key] || "0");
    return offChain > 0 || onChain > 0;
  });

  return (
    <div className="flex flex-col min-h-screen dark:bg-gray-900 relative">
      {isDashboardLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Loading Dashboard...</p>
          </div>
        </div>
      )}

      {/* Page Header */}
      <header className="py-4 text-center">
        <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
          Dashboard
        </h2>
        <p className="mb-0 text-[var(--text-color)]">
          Welcome, <span className="font-semibold text-secondary">{user?.email || 'User'}</span>
        </p>
      </header>

      <main className="container mx-auto my-4 flex-grow pb-16">
        {/* DAO Voting Button */}
        <div className="flex justify-end mb-3">
          <button 
            onClick={() => navigate('/dao-weighted')}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition"
          >
            New Asset Listing
          </button>
        </div>
        {/* Dashboard Table */}
        <div className="bg-white dark:bg-gray-800 shadow rounded overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-4 py-2 w-1/3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Asset
                </th>
                <th className="px-4 py-2 w-1/4 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  BNB Smartchain
                </th>
                <th className="px-4 py-2 w-1/4 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Off-Chain Balance
                </th>
                <th className="px-4 py-2 w-1/4 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Token Renewal
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredAssets.length > 0 ? (
                filteredAssets.map((asset) => {
                  const offChainBalance = userBalances[asset.key] || "0";
                  // If MetaMask is disconnected, onChainBalances[asset.key] is undefined so default to "0"
                  const onChainBalance = onChainBalances[asset.key] || "0";
                  return (
                    <DashboardRow
                      key={asset.key}
                      asset={asset}
                      offChainBalance={offChainBalance}
                      onChainBalance={onChainBalance}
                      refreshBalances={refreshUserBalances}
                      navigate={navigate}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" className="px-4 py-8 text-center text-gray-500 dark:text-gray-400">
                    No balances available. Please connect your email and MetaMask.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
