import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import OTPModal from './OTPModal';
import ConnectButton from './ConnectButton';
import { FiSun, FiMoon, FiMenu, FiX } from 'react-icons/fi';
import lightLogo from '../assets/light-logo.svg';
import darkLogo from '../assets/dark-logo.svg';

function Navbar() {
  const { user, logout } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleThemeToggle = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark', !darkMode);
  };

  const hardRefreshWebsite = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <nav className="fixed top-0 left-0 w-full z-50 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center justify-between px-4 py-3">
          <Link to="/" className="flex items-center">
            <img
              src={darkMode ? darkLogo : darkLogo}
              alt="GetonGlobal Logo"
              className="w-48 h-8 cursor-pointer object-contain"
              onClick={hardRefreshWebsite}
            />
          </Link>
          {/* Desktop Navigation */}
          <div className="hidden md:flex space-x-6">
            {user && (
              <>
                <Link to="/dashboard" className="menu-item no-underline hover:no-underline">Dashboard</Link>
                <Link to="/transactions" className="menu-item no-underline hover:no-underline">Transactions</Link>
                <Link to="/nodes" className="menu-item no-underline  hover:no-underline">Nodes</Link>
                {/* <Link to="/ponzi" className="menu-item no-underline hover:no-underline">Ponzi</Link> */}
              </>
            )}
          </div>
          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden flex items-center menu-item no-underline hover:no-underline">
            <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="focus:outline-none">
              {mobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </div>
          {/* Desktop Actions */}
          <div className="hidden md:flex items-center space-x-4">
            {user ? (
              <button
                onClick={logout}
                className="mx-2 px-3 py-2 w-44 bg-baseviolet border-baseviolet text-white-500 rounded-full hover:bg-basegreen hover:text-baseviolet transition"
              >
                Disconnect E-mail
              </button>
            ) : (
              <button
                onClick={() => setShowModal(true)}
                className="mx-2 px-3 py-2 w-44 bg-basegreen border-basegreen text-baseviolet rounded-full hover:bg-baseviolet hover:text-white transition"
              >
                Connect E-mail
              </button>
            )}
            <ConnectButton />
            <button onClick={handleThemeToggle} className="p-2 focus:outline-none">
              {darkMode ? <FiSun size={20} className="toggler" /> : <FiMoon size={20} className="toggler" />}
            </button>
          </div>
        </div>
        {/* Mobile Navigation Links */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-gray-800 px-4 pt-2 pb-4">
            {user && (
              <div className="flex flex-col space-y-2">
                <Link to="/dashboard" className="block text-white hover:text-basegreen no-underline" onClick={() => setMobileMenuOpen(false)}>
                  Dashboard
                </Link>
                <Link to="/transactions" className="block text-white hover:text-basegreen no-underline" onClick={() => setMobileMenuOpen(false)}>
                  Transactions
                </Link>
                <Link to="/nodes" className="block text-white hover:text-basegreen no-underline" onClick={() => setMobileMenuOpen(false)}>
                  Nodes
                </Link>
                {/* <Link to="/ponzi" className="block text-white hover:text-basegreen no-underline" onClick={() => setMobileMenuOpen(false)}>
                  Ponzi
                </Link>*/}
              </div>
            )}
            {/* Buttons in a single row */}
            <div className="mt-4 flex flex-wrap justify-between items-center space-x-2">
          <ConnectButton />
              {user ? (
                <button
                  onClick={() => { logout(); setMobileMenuOpen(false); }}
                  className="px-3 py-1 w-44 bg-baseviolet border-baseviolet text-white-500 rounded-full hover:bg-basegreen hover:text-baseviolet transition"
                >
                  Disconnect E-mail
                </button>
              ) : (
                <button
                  onClick={() => { setShowModal(true); setMobileMenuOpen(false); }}
                  className="px-3 py-1 bg-basegreen border-basegreen text-baseviolet rounded-full hover:bg-baseviolet hover:text-white transition"
                >
                  Connect E-mail
                </button>
              )}
              <button onClick={handleThemeToggle} className="p-2 focus:outline-none">
                {darkMode ? <FiSun size={20} className="toggler text-xl" /> : <FiMoon size={20} className="toggler text-xl" />}
              </button>
            </div>
          </div>
        )}

      </nav>
      <OTPModal show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default Navbar;
