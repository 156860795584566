import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, RefreshCcw } from 'lucide-react';

export default function SwapNavigation() {
  return (
    <section className="py-12 px-4 dark:bg-gray-900 text-gray-100">
      <div className="max-w-5xl mx-auto text-center mb-8">
        <h2 className="text-3xl font-bold mb-4">Choose Your Swap Option</h2>
        <p className="text-gray-300">
          Select between our legacy Swap V1 and the advanced Swap V3 to meet your trading needs.
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-5xl mx-auto">
        {/* Swap V1 Option */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 flex flex-col items-center">
          <div className="mb-4">
            <RefreshCcw className="w-16 h-16 text-blue-500" />
          </div>
          <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-2">
            Swap V1 – Standard Swap
          </h3>
          <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
            Experience our classic liquidity provision with a straightforward, reliable swap.
          </p>
          <Link
            to="/swap-v1"
            className="inline-flex items-center bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Go to Swap V1 <ArrowRight className="ml-2 w-4 h-4" />
          </Link>
        </div>

        {/* Swap V3 Option */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 flex flex-col items-center">
          <div className="mb-4">
            <RefreshCcw className="w-16 h-16 text-green-500" />
          </div>
          <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-2">
            Swap V3 – Advanced Swap
          </h3>
          <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
            Unlock enhanced features, improved liquidity, and advanced trading tools.
          </p>
          <Link
            to="/swap-v3"
            className="inline-flex items-center bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          >
            Go to Swap V3 <ArrowRight className="ml-2 w-4 h-4" />
          </Link>
        </div>
      </div>
      <div className="mt-10 text-center">
        <Link to="/learn-more" className="text-blue-400 underline">
          Not sure which swap to choose? Learn more.
        </Link>
      </div>
    </section>
  );
}
