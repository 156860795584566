// src/components/modals/DepositModal.js
import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import ModalPortal from '../ModalPortal';
import { ethers } from 'ethers';
import settings from '../../config/settings';
import { depositAsset } from '../../services/api';

const ERC20_ABI = [
  "function balanceOf(address) view returns (uint256)",
  "function transfer(address to, uint256 amount) returns (bool)"
];

function DepositModal({ asset, onClose }) {
  const { user } = useContext(UserContext);
  const [amount, setAmount] = useState('');
  const [step, setStep] = useState(1);
  const [txHash, setTxHash] = useState(null);

  async function ensureBnbChain(provider) {
    const chainId = await provider.send('eth_chainId', []);
    if (chainId !== settings.BNB_CHAIN_ID) {
      try {
        await provider.send('wallet_switchEthereumChain', [{ chainId: settings.BNB_CHAIN_ID }]);
      } catch (err) {
        throw new Error('Please switch to BNB Smart Chain in Metamask');
      }
    }
  }

  function shortenHash(hash) {
    if (!hash) return '';
    return `${hash.substring(0, 12)}......${hash.substring(hash.length - 12)}`;
  }

  function handleNext() {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount to deposit.');
      return;
    }
    setStep(2);
  }

  async function handleDeposit() {
    try {
      if (!user?.token) {
        alert('You must be logged in first.');
        return;
      }
      if (!window.ethereum) {
        alert('Metamask not found.');
        return;
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      await ensureBnbChain(provider);
      const signer = await provider.getSigner();

      const decimals = parseInt(asset.decimals, 10) || 18;
      const depositAmount = ethers.parseUnits(amount, decimals);

      if (!asset.bnbTokenAddress) {
        // Deposit native BNB
        const userAddress = await signer.getAddress();
        const balance = await provider.getBalance(userAddress);
        if (balance < depositAmount) {
          alert('Insufficient BNB to deposit + gas fees.');
          return;
        }
        const tx = await signer.sendTransaction({
          to: settings.BNB_ESCROW_CONTRACT,
          value: depositAmount
        });
        await tx.wait();

        await depositAsset({
          assetKey: asset.key,
          amount,
          txHash: tx.hash
        });
        setTxHash(tx.hash);
        setStep(3);
      } else {
        // Deposit BEP-20 token
        const tokenContract = new ethers.Contract(asset.bnbTokenAddress, ERC20_ABI, signer);
        const userAddress = await signer.getAddress();
        const rawBalance = await tokenContract.balanceOf(userAddress);
        if (rawBalance < depositAmount) {
          alert('You do not have enough tokens to deposit this amount.');
          return;
        }
        const transferTx = await tokenContract.transfer(settings.BNB_ESCROW_CONTRACT, depositAmount);
        await transferTx.wait();

        await depositAsset({
          assetKey: asset.key,
          amount,
          txHash: transferTx.hash
        });
        setTxHash(transferTx.hash);
        setStep(3);
      }
    } catch (err) {
      console.error('Deposit transaction failed:', err);
      alert(`Deposit transaction failed: ${err.reason || err.message}`);
    }
  }

  return (
    <ModalPortal>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 bg-opacity-70">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4">
          <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
            <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              Deposit {asset.symbol}
            </h5>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
              &times;
            </button>
          </div>
          <div className="p-4">
            {step === 1 && (
              <>
                <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                  Amount to Deposit ({asset.symbol})
                </label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded p-2 mb-4 dark:bg-gray-700 dark:text-gray-100"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder={`Enter amount in ${asset.symbol}`}
                />
                <button
                  className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition"
                  onClick={handleNext}
                >
                  Next
                </button>
              </>
            )}
            {step === 2 && (
              <>
                <p className="mb-4 dark:text-gray-200 text-center">
                  You will permanently recycle <strong>{amount} {asset.symbol}</strong> by allocating them to the{" "}
                  <a href="https://bscscan.com/address/0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D#asset-tokens" target="_blank" rel="noopener noreferrer">
                    <strong>
                      {settings.BNB_ESCROW_CONTRACT.slice(0, 17)}...
                      {settings.BNB_ESCROW_CONTRACT.slice(-17)}
                    </strong>
                  </a>.
                </p>

                <p className="mb-4 dark:text-gray-200 text-justify">
                  These deposited balances act as raw material for Renewal Rig operations, allowing you to start Renewal Rigs.
                  The transaction is irreversible; however, you can transfer your deposited balances to other users if desired.
                </p>
                <button
                  className="w-full bg-yellow-500 text-white py-2 rounded hover:bg-yellow-600 transition"
                  onClick={handleDeposit}
                >
                  Confirm in Metamask
                </button>
              </>
            )}
            {step === 3 && txHash && (
              <>
                <p className="text-green-500 font-semibold mb-4">Deposit Successful!</p>
                <p className="mb-4 dark:text-gray-200">
                  Transaction Hash:{' '}
                  <a
                    href={`https://bscscan.com/tx/${txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    {shortenHash(txHash)}
                  </a>
                </p>
                <button
                  className="w-full bg-gray-600 text-white py-2 rounded hover:bg-gray-700 transition"
                  onClick={onClose}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}

export default DepositModal;
