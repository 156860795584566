import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function AutoImageSwitcher({ images, interval = 3000, fadeDuration = 2.5, fixedHeight = "h-96" }) {
  const [index, setIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    let timer;
    const cycleImage = () => {
      // Wait for the specified interval before starting the fade-out
      timer = setTimeout(() => {
        setIsFading(true); // start fade-out
        // Wait half the fadeDuration to complete fade-out, then swap image and fade back in
        timer = setTimeout(() => {
          setIndex(prev => (prev + 1) % images.length);
          setIsFading(false);
          cycleImage(); // schedule the next cycle
        }, (fadeDuration * 1500) / 9);
      }, interval);
    };
    cycleImage();
    return () => clearTimeout(timer);
  }, [images.length, interval, fadeDuration]);

  return (
    <div className={`relative w-full ${fixedHeight} rounded-lg shadow-lg overflow-hidden`} style={{ backgroundColor: "transparent" }}>
      <motion.img
        src={images[index]}
        alt="BFS Diagram"
        className="w-full h-full object-contain rounded-lg shadow-lg"
        animate={{ opacity: isFading ? 0 : 1 }}
        transition={{ duration: (fadeDuration / 2), ease: "easeInOut" }}
      />
    </div>
  );
}

export default AutoImageSwitcher;
