// src/pages/Home.js
import React, { useEffect, useState } from "react";

// Local components
import HeroSection from "../components/HeroSection";
import HeroSlider from "../components/HeroSlider";
import FeaturesSection from "../components/FeaturesSection";
import GetonEcosystem from "../components/GetonEcosystem";
import SectorSection from "../components/SectorSection";
import FAQSection from "../components/FAQSection";
import Wallet from "../components/Wallet";

function Home() {
  useEffect(() => {
    // Attach click handlers to developer topic nav items (desktop view)
    const navItems = document.querySelectorAll(".dev-topic-nav li");

    navItems.forEach((li) => {
      li.addEventListener("click", () => {
        // Remove 'active' from all <li>
        navItems.forEach((x) => x.classList.remove("active"));
        li.classList.add("active");

        // Hide all .feature-panel
        const panels = document.querySelectorAll(".feature-panel");
        panels.forEach((panel) => {
          panel.classList.remove("show");
        });

        // Show the matching one
        const featureId = li.getAttribute("data-feature");
        const panelEl = document.getElementById(featureId);
        if (panelEl) {
          panelEl.classList.add("show");
        }
      });
    });
  }, []);

  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="features-section">
        <HeroSection />
      </section>
      {/* GetonEcosystem Section */}
      <section className="features-section py-1">
        <GetonEcosystem />
      </section>

      {/* Wallet Section */}
      <section className="features-section">
        <Wallet />
      </section>
        <div className="text-center text-white">
        <a href="/wallet" target="_blank" rel="noopener noreferrer">
        <button 
            className="mx-2 px-3 py-2 w-72 bg-baseviolet border-baseviolet btn-item rounded hover:bg-basegreen" >
            Go to GetonWallet →
        </button> </a>
        </div>
      {/* Ponzi Section */}
      <section className="features-section py-2">
        <HeroSlider />
      </section>
      <div className="text-center text-white">
      <a href="https://getonponzi.com/" target="_blank" rel="noopener noreferrer">
      <button 
          className="mx-2 px-3 py-2 w-72 bg-baseviolet border-baseviolet btn-item rounded hover:bg-basegreen" >
          Go to GetOnPonzi.com →
      </button> </a>
      </div>

      {/* FAQ Section */}
      <section className="features-section py-1">
        <FAQSection />
      </section>

      {/* Newsletter Section */}
      <section className="newsletter-section newsletter py-5">
        <h2 className="text-3xl font-bold text-center text-secondary">
          Subscribe to Geton News
        </h2>
        <iframe
          src="https://newsletter.geton.global/newsletter.html"
          style={{ width: "100%", height: 300, border: "none" }}
        />
      </section>
    </div>
  );
}

/* Inline styles (if you need them) */
const styles = {
  overlay: {
    position: "fixed",
    zIndex: 9999,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderContainer: {
    textAlign: "center",
  },
};

export default Home;
