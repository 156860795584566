// src/components/InactivityModal.js
import React, { useState, useEffect } from 'react';

function InactivityModal({ show, secondsLeft, onStayActive, onLogout }) {
  const [counter, setCounter] = useState(Math.ceil(secondsLeft / 1000));

  // Reset the counter whenever secondsLeft changes
  useEffect(() => {
    setCounter(Math.ceil(secondsLeft / 1000));
  }, [secondsLeft]);

  // Set up a live counter that ticks every second when modal is shown
  useEffect(() => {
    if (!show) return;

    const interval = setInterval(() => {
      setCounter(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
            Session About to Expire
          </h3>
          <button
            onClick={onStayActive}
            className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white text-2xl leading-none"
            aria-label="Close"
          >
            &times;
          </button>
        </div>
        <div className="text-gray-700 dark:text-gray-300 mb-6">
          <p>
            You have been inactive. You will be automatically logged out in{' '}
            <span className="inline-block w-6 text-right">
              <strong>{counter}</strong>
            </span>{' '}
            seconds.
          </p>
          <p className="mt-2">Do you want to stay active?</p>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onStayActive}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded transition"
          >
            Stay Active
          </button>
          <button
            onClick={onLogout}
            className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded transition"
          >
            Logout Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default InactivityModal;
