import React from 'react';
import { motion } from "framer-motion";
import { Button } from "./ui/Button";

const BFSAutomation = () => {
  return (
    <section className="container mx-auto py-16 mt-4 px-6 dark:bg-bggrey text-gray-800">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <motion.div 
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-3xl font-bold" style={{ color: "var(--secondary)" }}>
            BFS-Powered Automated Network
          </h2>
          <p className="mt-4" style={{ color: "var(--text-color)", opacity: 0.8 }}>
            Our BFS algorithm guarantees equal positioning and automated network expansion—ensuring sustainable, balanced growth without the pitfalls of traditional MLM structures.
          </p>
          <Button className="mt-4">Learn More</Button>
        </motion.div>
        <motion.img
          src="/assets/bfs-network.svg"
          alt="BFS Network Diagram"
          className="w-full md:w-1/2"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        />
      </div>
    </section>
  );
};

export default BFSAutomation;
