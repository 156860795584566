// src/components/modals/TransferModal.js
import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { transferPoints } from '../../services/api';

function TransferModal({ asset, onClose, refreshBalances }) {
  const { user } = useContext(UserContext);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function handleTransfer() {
    if (!recipientEmail || !amount) {
      setError('Please provide recipient email and amount.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await transferPoints({
        assetKey: asset.key,
        recipientEmail,
        amount,
      });
      alert('Transfer successful!');
      if (refreshBalances) refreshBalances();
      onClose();
    } catch (err) {
      console.error('Off-chain transfer error:', err);
      setError(err.response?.data?.error || 'Transfer failed. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4">
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
            Transfer {asset.symbol} Off-Chain
          </h5>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            disabled={loading}
          >
            &times;
          </button>
        </div>

        <div className="p-4">
          {error && (
            <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
              {error}
            </div>
          )}

          <label className="block text-sm font-medium mb-1 dark:text-gray-300">
            Recipient Email
          </label>
          <input
            type="email"
            className="w-full border border-gray-300 rounded p-2 mb-3 dark:bg-gray-700 dark:text-gray-100"
            value={recipientEmail}
            onChange={(e) => setRecipientEmail(e.target.value)}
          />

          <label className="block text-sm font-medium mb-1 dark:text-gray-300">
            Amount
          </label>
          <input
            type="number"
            className="w-full border border-gray-300 rounded p-2 mb-3 dark:bg-gray-700 dark:text-gray-100"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="0"
            step="any"
          />

          <button
            className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition disabled:opacity-60"
            onClick={handleTransfer}
            disabled={loading}
          >
            {loading ? 'Transferring...' : 'Confirm Transfer'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferModal;
