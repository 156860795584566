// src/components/Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineHowToVote, MdHowToVote } from 'react-icons/md';
import settings from '../config/settings';

function Footer() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <footer
      className="py-4 fixed bottom-0 left-0 w-full"
      style={{ backgroundColor: 'var(--card-background)', color: 'var(--text-color)' }}
    >
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center relative">
        <div>
          <span>{settings.FOOTER_TEXT}</span>
        </div>
        <div className="flex items-center space-x-4">
          <Link to="/dashboard" className="footer-item no-underline dark:text-white">
            Dashboard
          </Link>
          <Link to="/transactions" className="footer-item no-underline dark:text-white">
            Transactions
          </Link>
          <Link to="/nodes" className="footer-item no-underline dark:text-white">
            Nodes
          </Link>
          {/* DAO Governance Dropdown */}
          <div className="relative">
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="hover:underline focus:outline-none">
              DAO
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 bottom-full mb-2 w-48 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded shadow-lg z-10">
                <Link
                  to="/dao-standard"
                  onClick={() => setDropdownOpen(false)}
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <MdOutlineHowToVote className="inline mr-2" />
                  Standard Voting
                </Link>
                <Link
                  to="/dao-weighted"
                  onClick={() => setDropdownOpen(false)}
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <MdHowToVote className="inline mr-2" />
                  Weighted Voting
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
