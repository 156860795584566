// src/components/DashboardRow.js
import React, { useState } from 'react';
import DepositModal from './modals/DepositModal';
import TransferModal from './modals/TransferModal';
import ModalPortal from './ModalPortal';

function DashboardRow({ asset, offChainBalance, onChainBalance, refreshBalances, navigate }) {
  const [modalType, setModalType] = useState(null);

  return (
    <>
      <tr className="align-middle dark:bg-gray-800">
        {/* Asset Column */}
        <td className="px-4 py-2">
          <div className="flex items-center">
            <img
              src={asset.logo}
              alt={asset.symbol}
              className="w-8 h-8 rounded-full object-contain mr-2"
            />
            <span className="text-sm font-medium text-gray-800 dark:text-gray-200">
              {asset.name} ({asset.symbol})
            </span>
          </div>
        </td>
        {/* On-chain Balance with Deposit Button */}
        <td className="px-4 py-2">
          <div className="flex flex-col items-end">
            <span className="text-lg text-gray-800 dark:text-gray-300">
              {parseFloat(onChainBalance).toFixed(2)}
            </span>
            <button
              onClick={() => setModalType('deposit')}
              className="mt-1 bg-blue-600 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 text-white text-xs font-semibold py-1 w-40 px-3 rounded transition"
            >
              SmartChain Deposit
            </button>
          </div>
        </td>
        {/* Off-chain Balance with Transfer Button */}
        <td className="px-4 py-2">
          <div className="flex flex-col items-end">
            <span className="text-lg text-gray-800 dark:text-gray-300">
              {parseFloat(offChainBalance).toFixed(2)}
            </span>
            <button
              onClick={() => setModalType('transfer')}
              className="mt-1 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-600 dark:hover:bg-indigo-700 text-white text-xs font-semibold w-40 py-1 px-3 rounded transition"
            >
              Transfer to Recipient
            </button>
          </div>
        </td>
        {/* Actions Column (Buy Node) */}
        <td className="px-4 py-2">
          <button
            onClick={() => navigate('/nodes')}
            className="w-40 bg-green-600 hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-700 text-white text-xs font-semibold py-2 px-3 rounded transition"
          >
            Start Renewal Rig
          </button>
        </td>
      </tr>

      {modalType === 'deposit' && (
        <ModalPortal>
          <DepositModal
            asset={asset}
            onClose={() => setModalType(null)}
            refreshBalances={refreshBalances}
          />
        </ModalPortal>
      )}
      {modalType === 'transfer' && (
        <ModalPortal>
          <TransferModal
            asset={asset}
            onClose={() => setModalType(null)}
            refreshBalances={refreshBalances}
          />
        </ModalPortal>
      )}
    </>
  );
}

export default DashboardRow;
