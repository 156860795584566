import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import SETTINGS from "../config/supplies"; // Ensure the path to SETTINGS is correct
import SUPPLYS from "../supplys"; // Ensure the path to SETTINGS is correct

const Supplies = () => {
  const [tokenData, setTokenData] = useState(SUPPLYS);
  const [loading, setLoading] = useState(false);

  // Utility to get the API URL for different networks
const getApiUrlForNetwork = (
  network,
  tokenAddress,
  walletAddress,
  apiKey
) => {
  let url;
  switch (network) {
    case "Polygon Mainnet":
      url = `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "BNB Smart Chain":
      url = `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "Ethereum Mainnet":
      url = `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "Arbitrum One":
      url = `https://api.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "OP Mainnet":
      url = `https://api-optimistic.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    default:
      console.error("Unsupported network:", network);
      return null;
  }
  console.log(`Generated API URL for ${network}: ${url}`);
  return url;
};

// Updated fetchBalance with enhanced error handling
const fetchBalance = async (asset, walletKey) => {
  const { tokenAddress, tokenDecimals, apiKey, network } = asset;
  const walletAddress = asset[walletKey];

  // Skip fetching if token address or wallet address is invalid
  if (!tokenAddress || tokenAddress === "0x" || !walletAddress || walletAddress === "0x") {
    return { balanceAmount: 0, formattedBalanceAmount: "0" };
  }

  const apiUrl = getApiUrlForNetwork(network, tokenAddress, walletAddress, apiKey);
  if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: "0" };

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      console.error(`Failed to fetch from ${apiUrl}:`, response.statusText);
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }
    const data = await response.json();

    if (data.status !== "1" || !data.result) {
      console.error(`Error fetching ${walletKey} amount:`, data.message || "Invalid data");
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }

    const balanceAmount = data.result / Math.pow(10, tokenDecimals);
    const formattedBalanceAmount = Math.floor(balanceAmount).toLocaleString();
    return { balanceAmount, formattedBalanceAmount };
  } catch (error) {
    console.error(`Error fetching ${walletKey} amount for ${walletKey}`, error);
    return { balanceAmount: 0, formattedBalanceAmount: "0" };
  }
};

// Add log outputs in fetchAllData to debug circulating supplies
const fetchAllData = async () => {
  setLoading(true);
  const results = {};

  for (const [tokenName, tokenSettings] of Object.entries(SETTINGS.Tokens || {})) {
    results[tokenName] = {};

    let ethereumCirculatingSupply = 0;
    let bscCirculatingSupply = 0;
    let polygonCirculatingSupply = 0;
    let arbitrumCirculatingSupply = 0;
    let optimismCirculatingSupply = 0;

    // Ethereum network balances
    if (tokenSettings.networks.ethereum.tokenAddress !== "0x") {
      const ethereumTotalSupply = parseFloat(tokenSettings.networks.ethereum.totalSupply.replace(/,/g, ""));
      results[tokenName]["Ethereum Total"] = tokenSettings.networks.ethereum.totalSupply;

      const ethereumCustody = await fetchBalance(tokenSettings.networks.ethereum, "custodyWallet");
      results[tokenName]["Ethereum Custody"] = ethereumCustody.formattedBalanceAmount;

      const ethereumRecyclePool = await fetchBalance(tokenSettings.networks.ethereum, "recyclePoolWallet");
      results[tokenName]["Ethereum Recycle Pool"] = ethereumRecyclePool.formattedBalanceAmount;

      const ethereumSwap = await fetchBalance(tokenSettings.networks.ethereum, "swaplWallet");
      results[tokenName]["Ethereum Swap"] = ethereumSwap.formattedBalanceAmount;

      const ethereumLiquidityPool = await fetchBalance(tokenSettings.networks.ethereum, "liquidityPoolWallet");
      results[tokenName]["Ethereum Liquidity Pool"] = ethereumLiquidityPool.formattedBalanceAmount;

      const ethereumEcosystem = await fetchBalance(tokenSettings.networks.ethereum, "ecosystemWallet");
      results[tokenName]["Ethereum Ecosystem"] = ethereumEcosystem.formattedBalanceAmount;

      ethereumCirculatingSupply =
        ethereumTotalSupply -
        (ethereumCustody.balanceAmount || 0) -
        (ethereumRecyclePool.balanceAmount || 0) -
        (ethereumSwap.balanceAmount || 0) -
        (ethereumLiquidityPool.balanceAmount || 0) -
        (ethereumEcosystem.balanceAmount || 0);

      results[tokenName]["Ethereum Circulating"] = Math.floor(ethereumCirculatingSupply).toLocaleString();
    }

    // Smartchain network balances
    if (tokenSettings.networks.bsc.tokenAddress !== "0x") {
      const bscTotalSupply = parseFloat(tokenSettings.networks.bsc.totalSupply.replace(/,/g, ""));
      results[tokenName]["Smartchain Total"] = tokenSettings.networks.bsc.totalSupply;

      const bscCustody = await fetchBalance(tokenSettings.networks.bsc, "custodyWallet");
      results[tokenName]["Smartchain Custody"] = bscCustody.formattedBalanceAmount;

      const bscRecyclePool = await fetchBalance(tokenSettings.networks.bsc, "recyclePoolWallet");
      results[tokenName]["Smartchain Recycle Pool"] = bscRecyclePool.formattedBalanceAmount;

      const bscSwap = await fetchBalance(tokenSettings.networks.bsc, "swapWallet");
      results[tokenName]["Smartchain Swap"] = bscSwap.formattedBalanceAmount;

      bscCirculatingSupply =
      bscTotalSupply -
        (bscCustody.balanceAmount || 0) -
        (bscSwap.balanceAmount || 0) -
        (bscRecyclePool.balanceAmount || 0);

      results[tokenName]["Smartchain Circulating"] = Math.floor(bscCirculatingSupply).toLocaleString();
    }

    // Polygon network balances
    if (tokenSettings.networks.polygon.tokenAddress !== "0x") {
      const polygonTotalSupply = parseFloat(tokenSettings.networks.polygon.totalSupply.replace(/,/g, ""));
      results[tokenName]["Polygon Total"] = tokenSettings.networks.polygon.totalSupply;

      const polygonCustody = await fetchBalance(tokenSettings.networks.polygon, "custodyWallet");
      results[tokenName]["Polygon Custody"] = polygonCustody.formattedBalanceAmount;

      const polygonRecyclePool = await fetchBalance(tokenSettings.networks.polygon, "recyclePoolWallet");
      results[tokenName]["Polygon Recycle Pool"] = polygonRecyclePool.formattedBalanceAmount;

      const polygonSwap = await fetchBalance(tokenSettings.networks.polygon, "swapWallet");
      results[tokenName]["Polygon Swap"] = polygonSwap.formattedBalanceAmount;

      polygonCirculatingSupply =
      polygonTotalSupply -
        (polygonCustody.balanceAmount || 0) -
        (polygonSwap.balanceAmount || 0) -
        (polygonRecyclePool.balanceAmount || 0);

      results[tokenName]["Polygon Circulating"] = Math.floor(polygonCirculatingSupply).toLocaleString();
    }

    // Arbitrum network balances
    if (tokenSettings.networks.arbitrum.tokenAddress !== "0x") {
      const arbitrumTotalSupply = parseFloat(tokenSettings.networks.arbitrum.totalSupply.replace(/,/g, ""));
      results[tokenName]["Arbitrum Total"] = tokenSettings.networks.arbitrum.totalSupply;

      const arbitrumCustody = await fetchBalance(tokenSettings.networks.arbitrum, "custodyWallet");
      results[tokenName]["Arbitrum Custody"] = arbitrumCustody.formattedBalanceAmount;

      const arbitrumRecyclePool = await fetchBalance(tokenSettings.networks.arbitrum, "recyclePoolWallet");
      results[tokenName]["Arbitrum Recycle Pool"] = arbitrumRecyclePool.formattedBalanceAmount;

      const arbitrumSwap = await fetchBalance(tokenSettings.networks.arbitrum, "swapWallet");
      results[tokenName]["Arbitrum Swap"] = arbitrumSwap.formattedBalanceAmount;

      arbitrumCirculatingSupply =
      arbitrumTotalSupply -
        (arbitrumCustody.balanceAmount || 0) -
        (arbitrumSwap.balanceAmount || 0) -
        (arbitrumRecyclePool.balanceAmount || 0);

      results[tokenName]["Arbitrum Circulating"] = Math.floor(arbitrumCirculatingSupply).toLocaleString();
    }

    // Optimism network balances
    if (tokenSettings.networks.optimism.tokenAddress !== "0x") {
      const optimismTotalSupply = parseFloat(tokenSettings.networks.optimism.totalSupply.replace(/,/g, ""));
      results[tokenName]["Optimism Total"] = tokenSettings.networks.optimism.totalSupply;

      const optimismCustody = await fetchBalance(tokenSettings.networks.optimism, "custodyWallet");
      results[tokenName]["Optimism Custody"] = optimismCustody.formattedBalanceAmount;

      const optimismRecyclePool = await fetchBalance(tokenSettings.networks.optimism, "recyclePoolWallet");
      results[tokenName]["Optimism Recycle Pool"] = optimismRecyclePool.formattedBalanceAmount;

      const optimismSwap = await fetchBalance(tokenSettings.networks.optimism, "swapWallet");
      results[tokenName]["Optimism Swap"] = optimismSwap.formattedBalanceAmount;

      optimismCirculatingSupply =
      optimismTotalSupply -
        (optimismCustody.balanceAmount || 0) -
        (optimismSwap.balanceAmount || 0) -
        (optimismRecyclePool.balanceAmount || 0);

      results[tokenName]["Optimism Circulating"] = Math.floor(optimismCirculatingSupply).toLocaleString();
    }
    // Inside fetchAllData function after calculating individual network circulating supplies
    results[tokenName]["Total Circulating"] = Math.floor(
      (ethereumCirculatingSupply || 0) + 
      (bscCirculatingSupply || 0) + 
      (polygonCirculatingSupply || 0) + 
      (arbitrumCirculatingSupply || 0) + 
      (optimismCirculatingSupply || 0)
    ).toLocaleString();
  }
    console.log(results);
    
    setTokenData(results);
    setLoading(false);
  };

  useEffect(() => {
    //fetchAllData();
  }, []);

  // Helper function to render network and token logos
  const renderNetworkTokenLogos = (network, tokenName) => {
    const tokenSettings = SETTINGS.Tokens[tokenName]?.networks[network];

    if (!tokenSettings || tokenSettings.tokenAddress === "0x") {
      return null; // Handle cases where the token is not deployed on this network
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href={tokenSettings.networkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.networkLogo}
            alt="Network Logo"
            style={{ width: "18px", height: "auto", marginRight: "20px" }}
          />
        </a>
        <a
          href={tokenSettings.tokenUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.tokenLogo}
            alt="Token Logo"
            style={{ width: "18px", height: "auto" }}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="supplies-container mt-3 ml-1 mctable">
      <h2 className="pageTitle supplies-header">Multichain Token Supplies</h2>
      <p className="hero-p sub-header">Multi-chain live token supplies with real-time insights, enabling transparent asset monitoring and measuring the impact of recycling.</p> 
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <button
          onClick={fetchAllData}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "10px",
          }}
          className="refresh-button hero-button "
          disabled={loading}
        >
          {loading ? "Loading..." : "Refresh Data"}
        </button>
      </div>
      <div className="table-responsive mb-3">
        <Table
          className="w-100 table-bordered-custom"
          style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
        >
          <thead>
            <tr style={{ borderBottom: "1px solid #dee2e6" }}>
              <th
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  width: "150px",
                }}
              >
                Network
              </th>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <th
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "14px",
                    width: "110px",
                  }}
                  key={idx}
                >
                  {SETTINGS.Tokens[tokenName].tokenName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Total Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating Supply
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Total Circulating"]
                    ? tokenData[tokenName]["Total Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(SETTINGS.Tokens || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Ethereum row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                Ethereum Mainnet
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("ethereum", tokenName)}
                </td>
              ))}
            </tr>

            {/* Ethereum Total row */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Total
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Total"]
                    ? tokenData[tokenName]["Ethereum Total"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Ethereum Liquidity Pool row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Liquidity Pool
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Liquidity Pool"]
                    ? tokenData[tokenName]["Ethereum Liquidity Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Ethereum Ecosystem row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address/0x5bCa87e3261fA4FEEEE6E83012e48849468e108e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ecosystem
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Ecosystem"]
                    ? tokenData[tokenName]["Ethereum Ecosystem"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Ethereum Custody row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address/0x131439c89353A3379913482A21d1F7e420313Cbd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custody
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Custody"]
                    ? tokenData[tokenName]["Ethereum Custody"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Ethereum Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Swap"]
                    ? tokenData[tokenName]["Ethereum Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Ethereum Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Ethereum Circulating"]
                    ? tokenData[tokenName]["Ethereum Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(SETTINGS.Tokens || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* BNB Smart Chain row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                BNB Smart Chain
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("bsc", tokenName)}
                </td>
              ))}
            </tr>

            {/* Smartchain Total row */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Total
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Smartchain Total"]
                    ? tokenData[tokenName]["Smartchain Total"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Smartchain Recycle Pool row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recycle Pool
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Smartchain Recycle Pool"]
                    ? tokenData[tokenName]["Smartchain Recycle Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Smartchain Custody row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custody
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Smartchain Custody"]
                    ? tokenData[tokenName]["Smartchain Custody"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* SmartChain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0xA63Cb1F1d6a787a35B5285B709B761917e933642"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Smartchain Swap"]
                    ? tokenData[tokenName]["Smartchain Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>


            {/* Smartchain Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Smartchain Circulating"]
                    ? tokenData[tokenName]["Smartchain Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(SETTINGS.Tokens || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Polygon Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                Polygon Mainnet
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("polygon", tokenName)}
                </td>
              ))}
            </tr>

            {/* Polygon Total row */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Total
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Polygon Total"]
                    ? tokenData[tokenName]["Polygon Total"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Polygon Recycle Pool row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x61D634c1B1F276b2f11A6B60f120F0097e356C17"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recycle Pool
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Polygon Recycle Pool"]
                    ? tokenData[tokenName]["Polygon Recycle Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Polygon Custody row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custody
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Polygon Custody"]
                    ? tokenData[tokenName]["Polygon Custody"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Polygon Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x1bc0395366a2a31b95213021D5F3E39160Eff5bA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Polygon Swap"]
                    ? tokenData[tokenName]["Polygon Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Polygon Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Polygon Circulating"]
                    ? tokenData[tokenName]["Polygon Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(SETTINGS.Tokens || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Arbitrum Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                Arbitrum One
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("arbitrum", tokenName)}
                </td>
              ))}
            </tr>

            {/* Arbitrum Total row */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Total
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Arbitrum Total"]
                    ? tokenData[tokenName]["Arbitrum Total"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Arbitrum Recycle Pool row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0x045725BC613844741fa2050a075b15237E45A23F"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recycle Pool
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Arbitrum Recycle Pool"]
                    ? tokenData[tokenName]["Arbitrum Recycle Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Arbitrum Custody row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custody
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Arbitrum Custody"]
                    ? tokenData[tokenName]["Arbitrum Custody"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Arbitrum Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0xa1b63deabec09a0a9a710c5768e915c5ac95b66c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Arbitrum Swap"]
                    ? tokenData[tokenName]["Arbitrum Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Arbitrum Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Arbitrum Circulating"]
                    ? tokenData[tokenName]["Arbitrum Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(SETTINGS.Tokens || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Optimism Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                OP Mainnet
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("optimism", tokenName)}
                </td>
              ))}
            </tr>

            {/* Optimism Total row */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Total
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Optimism Total"]
                    ? tokenData[tokenName]["Optimism Total"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Optimism Recycle Pool row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recycle Pool
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Optimism Recycle Pool"]
                    ? tokenData[tokenName]["Optimism Recycle Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Optimism Custody row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x61D634c1B1F276b2f11A6B60f120F0097e356C17"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custody
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Optimism Custody"]
                    ? tokenData[tokenName]["Optimism Custody"]
                    : "N/A"}
                </td>
              ))}
            </tr>

            {/* Optimism Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x5fca3cfb3b7e54d833648a44422285229df36560"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap
                </a>
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Optimism Swap"]
                    ? tokenData[tokenName]["Optimism Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>


            {/* Optimism Circulating Supply row (centered and bold) */}
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                Circulating
              </td>
              {Object.keys(SETTINGS.Tokens || {}).map((tokenName, idx) => (
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                  key={idx}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[tokenName] &&
                      tokenData[tokenName]["Optimism Circulating"]
                    ? tokenData[tokenName]["Optimism Circulating"]
                    : "N/A"}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Supplies;
