

const SETTINGS = {

    BRIDGE_CONTRACTS: {
        56: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
        137: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
        42161: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
        10: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
    },
    RPC_URLS: {
        // Binance Smart Chain
        56: "https://bsc-dataseed.binance.org/",
      
        // Polygon (Mainnet) - using Infura with your project ID
        137: `https://polygon-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,
      
        // Optimism (Mainnet) - using Infura with your project ID
        10: `https://optimism-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`,
      
        // Arbitrum One (Mainnet) - using Infura with your project ID
        42161: `https://arbitrum-mainnet.infura.io/v3/bdd317b57d5746e48cce8e03818d8908`
      },
      PRICES: {
        "WAVE": {
            56:-1,
            137:1,
            42161:-1,
            10:-1,
            name:"WAVE"
        },
        "GBL": {
            56:0.002,
            137:20,
            42161:50,
            10:100,
            name:"GBL"
        },
        "CODE": {
            56:0.000012,
            137:3.2,
            42161:-1,
            10:-1,
            name:"CODE"
        },
        "META": {
            56:0.000007,
            137:2.9,
            42161:-1,
            10:-1,
            name:"META"
        },
        "PIPL": {
            56:0.00001,
            137:2.8,
            42161:-1,
            10:-1,
            name:"PIPL"
        },
        "GETON": {
            56:0.00004,
            137:140,
            42161:-1,
            10:-1,
            name:"GETON"
        },
        "GROSH": {
            56:0.000016,
            137:2.8,
            42161:-1,
            10:-1,
            name:"GROSH"
        },
        "PRON": {
            56:0.000033,
            137:1290,
            42161:-1,
            10:-1,
            name:"PRON"
        },
        "LOS": {
            56:0.0005,
            137:24.00,
            42161:-1,
            10:-1,
            name:"LOS"
        },
        "CARB": {
            56:0.00002,
            137:18.00,
            42161:-1,
            10:-1,
            name:"CARB"
        },
        "CBDX": {
            56:0.000067,
            137:8.3,
            42161:-1,
            10:-1,
            name:"CBDX"
        },
        "APOD": {
            56:0.000026,
            137:1.2,
            42161:-1,
            10:-1,
            name:"APOD"
        },
        "eGROSH": {
            56:0.0018,
            137:1.2,
            42161:-1,
            10:-1,
            name:"eGROSH"
        },
        "GMAAR": {
            56:0.000001,
            137:-1,
            42161:-1,
            10:-1,
            name:"GMAAR"
        },

    },
    TOKEN_ADDRESSES: {
        "GBL": {
            1: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
            56:"0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
            137:"0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
            42161:"0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
            10:"0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
        },
        "WAVE": {
            1: "-",
            56:"-",
            137:"0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
            42161:"-",
            10:"-",
        },
        "CODE": {
            1: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
            56:"0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
            137:"0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
            42161:"0x1E2A7824942754c30D8a24C1de8B383d417b804d",
            10:"0x1E2A7824942754c30D8a24C1de8B383d417b804d",
        },
        "META": {
            1: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
            56:"0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",
            137:"0xD51E7065B942D5A5886915C6BEbf42600D546162",
            42161:"0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
            10:"0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
        },
        "PIPL": {
            1: "0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",
            56:"0xE12723848699AdcbFF466050cE17Cbf5C6156720",
            137:"0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
            42161:"0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
            10:"0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
        },
        "GETON": {
            1: "0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
            56:"0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
            137:"0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
            42161:"0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
            10:"0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
        },
        "GROSH": {
            1: "0xd92B3100e19bef7A32aef1451D891f99F9316e13",
            56:"0xb67B9a92D902F8beadEe9846CDde40a10F198808",
            137:"0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
            42161:"0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
            10:"0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
        },

        "PRON": {
            1: "0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
            56:"0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
            137:"0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
            42161:"0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
            10:"0x3692668069fe43771f46EEA1aaa28064debFFA9c",
        },
    },
}
export default SETTINGS;