import React, { useState, useEffect } from "react";

// Define background colors for different toast types.
const toastTypeClasses = {
  success: "bg-green-500",
  danger: "bg-red-500",
  info: "bg-blue-500",
  warning: "bg-yellow-500",
};

const CustomToast = ({ show, message, type, onClose }) => {
  // Automatically hide the toast after 6000ms when it's shown.
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div
        className={`flex items-center p-4 max-w-xs w-full ${toastTypeClasses[type] || "bg-blue-500"} text-white rounded shadow-lg transition-opacity duration-300`}
      >
        <div className="flex-1">{message}</div>
        <button onClick={onClose} className="ml-4 text-white focus:outline-none">
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

const useCustomToast = () => {
  const [toast, setToast] = useState({
    show: false,
    message: "",
    type: "success", // success, danger, info, warning
  });

  const showToast = (message, type = "success") => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  return { toast, showToast, hideToast };
};

export { CustomToast, useCustomToast };
