const SETTINGS = {

    ownerAddress: "0xcFbA2075F6F4bd815aef59b8554e17a20da67698",
    waweSwapPolygonAddress: "0x5e801c790CA1b768166c1aA198C6C51588BF2e74",
    waweSwapPolygonStorageAddress: "0x0EAfB43088891A78b3a70cf5e3188f64C3A44684",
    waweSwapStorageAddress: "0xB14204DdB5d7328B9D777387E86D64556a03a092",
    waweSwapAddress: "0x89989F889B8B998242D14368136B62299bf7bC6d",
    tokenSwapAddress: "0xa61C9184d9FA8Db83499B6dcf91752292CE338E4",


    gblAddresses:{
        1: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
        10: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
        42161: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
        137: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
        56: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
      },
      quoterAddress:{
        1: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        10: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        42161: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        137: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        56: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
      },
      //1388023 polygon 0.318938937766619260
        globalTokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
        globalTokenAddressEthereum: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
        globalTokenAddressOptimism: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
        voteGlobalTokenAddress: "0x2380B4d49094F0474E1adAeaDe94C5B789120549",
    
        genessisAddress: "0x8A802B796e8Dba2eC7e7Bf667fCe5d9eefC9d504",

dexLinks:{
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    10: "https://app.uniswap.org/swap?chain=optimism&inputCurrency=0x94b008aA00579c1307B0EF2c499aD98a8ce58e58&outputCurrency=",
    42161: "https://app.uniswap.org/swap?chain=arbitrum&inputCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9&outputCurrency=",
    137: "https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=",
    56: "https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=",
  },
  scanLinks:{
    1: "https://etherscan.io/tx/",
    10: "https://optimistic.etherscan.io/tx/",
    42161: "https://arbiscan.io/tx/",
    137: "https://polygonscan.com/tx/",
    56: "https://bscscan.com/tx/",
  },
  revokeLinks:{
    1: "https://etherscan.io/tokenapprovalchecker",
    10: "https://optimistic.etherscan.io/tokenapprovalchecker",
    42161: "https://arbiscan.io/tokenapprovalchecker",
    137: "https://polygonscan.com/tokenapprovalchecker",
    56: "https://bscscan.com/tokenapprovalchecker",
  },
  networkIcons: {
    1:"https://docs.waweswaps.com/images/chains/ethereum.svg",
    10:"https://docs.waweswaps.com/images/chains/optimism.svg",
    137:"https://docs.waweswaps.com/images/chains/polygon.svg",
    56:"https://docs.waweswaps.com/images/chains/smartchain.svg",
    42161:"https://docs.waweswaps.com/images/chains/arbitrum.svg",
  },
  usdtAddress:{
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    10: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    56: "0x55d398326f99059fF775485246999027B3197955",
  },
  bridgeFees:{
    1: "0.1",
    10: "0.001",
    42161: "0.001",
    137: "2",
    56: "0.005",
  },
  
  buyTokensDataEthereum: [
    {"name":"BNB","symbol":"BNB","address":"0xB8c77482e45F1F44dE1745F52C74426C631bDD52"},
    {"name":"WBTC","symbol":"Wrapped BTC ","address":"0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599"},
    {"name":"USDT","symbol":"USDT","address":"0xdAC17F958D2ee523a2206206994597C13D831ec7"},
  ],
  buyTokensDataPolygon: [
    {"name":"POL","symbol":"POL","address":"0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"},
   /* {"name":"BTCBToken","symbol":"BTCB","address":"0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6"},
    {"name":"EthereumToken","symbol":"ETH","address":"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"},
    {"name":"USDT","symbol":"USDT","address":"0xc2132D05D31c914a87C6611C10748AEb04B58e8F"},*/
  ],
  buyTokensDataArbitrum: [
    {"name":"ETH","symbol":"ETH","address":"0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"},
    /*{"name":"BNB","symbol":"BNB","address":"0x20865e63B111B2649ef829EC220536c82C58ad7B"},
    {"name":"WBTC","symbol":"WBTC","address":"0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f"},
    {"name":"USDT","symbol":"USDT","address":"0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"},*/
  ],
  buyTokensDataOptimism: [
    {"name":"ETH","symbol":"ETH","address":"0x4200000000000000000000000000000000000006"},
   /* {"name":"WBTC","symbol":"WBTC","address":"0x68f180fcCe6836688e9084f035309E29Bf0A2095"},
    {"name":"USDT","symbol":"USDT","address":"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58"},*/
  ],
  buyTokensData: [
    {"name":"BNB","symbol":"BNB","address":"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"},
    /*{"name":"BTCB","symbol":"BTCB","address":"0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c"},
    {"name":"EthereumToken","symbol":"ETH","address":"0x2170Ed0880ac9A755fd29B2688956BD959F933F8"},
    {"name":"Solana","symbol":"SOL","address":"0x570A5D26f7765Ecb712C0924E4De545B89fD43dF"},
    {"name":"USDT","symbol":"USDT","address":"0x55d398326f99059fF775485246999027B3197955"},
    {"name":"Cardano","symbol":"ADA","address":"0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"},
    {"name":"XRPToken","symbol":"XRP","address":"0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE"}*/
  ],
  tokensData: [
  {"name":"Pron Coin","symbol":"PRON","address":"0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393","price":100},
  {"name":"Geton Coin","symbol":"GETON","address":"0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51","price":0.1},
  //{"name":"LifeOnScreen","symbol":"LOS","address":"0xc1463935d11EB0C9a06530F387077053Ebe4500B","price":1},
  //{"name":"CarbCoin","symbol":"CARB","address":"0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d","price":0.1},
  //{"name":"ATON EVOLUTION","symbol":"ATE","address":"0xc8d9d70C1E1C1aebA75CCeF338776f27C451377B","price":1000},
  //{"name":"CryptoBoard Token","symbol":"CBDX","address":"0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E","price":1},
  //{"name":"ATONReversalVoucher","symbol":"ARV","address":"0xC01419523900ACCD39EBA74c5a1B6909154F99a3","price":100},
  {"name":"Pipl Coin","symbol":"PIPL","address":"0xE12723848699AdcbFF466050cE17Cbf5C6156720","price":0.1},
  //{"name":"GMAAR Token","symbol":"GMAAR","address":"0x7A81eeB086e4502bb8A022F804831575db42Fa07","price":0.001},
  //{"name":"EuroGrosh","symbol":"eGROSH","address":"0x542e258550dC85EdE56D9480Bc6A68c07158ed24","price":100},
  {"name":"Grosh Coin","symbol":"GROSH","address":"0xb67B9a92D902F8beadEe9846CDde40a10F198808","price":0.01},
  {"name":"CryptoCode","symbol":"CODE","address":"0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942","price":1},
  {"name":"MetaCoin","symbol":"META","address":"0xfe1E4B7515b933faAF7B32e569eB245b7542b03C","price":0.001}],

  tokensDataPolygon: [
    {"name":"PiplCoin","symbol":"PIPL","address":"0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc","price":100},
    {"name":"PronCoin","symbol":"PRON","address":"0x2a6aF8DA13B36F925632a404A3161fE344d8f469","price":100},
    {"name":"GroshCoin","symbol":"GROSH","address":"0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220","price":100},
    {"name":"GetonCoin","symbol":"GETON","address":"0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE","price":100},
    {"name":"MetaCoin","symbol":"META","address":"0xD51E7065B942D5A5886915C6BEbf42600D546162","price":100},
    {"name":"CryptoCode","symbol":"CODE","address":"0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B","price":100}],
  tokensDataV1: [
  {"name":"Pron Coin","symbol":"PRON","address":"0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393","price":100},
  {"name":"Geton Coin","symbol":"GETON","address":"0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51","price":0.1},
  {"name":"LifeOnScreen","symbol":"LOS","address":"0xc1463935d11EB0C9a06530F387077053Ebe4500B","price":1},
  {"name":"CarbCoin","symbol":"CARB","address":"0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d","price":0.1},
  {"name":"ATON EVOLUTION","symbol":"ATE","address":"0xc8d9d70C1E1C1aebA75CCeF338776f27C451377B","price":1000},
  {"name":"CryptoBoard Token","symbol":"CBDX","address":"0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E","price":1},
  {"name":"ATONReversalVoucher","symbol":"ARV","address":"0xC01419523900ACCD39EBA74c5a1B6909154F99a3","price":100},
  {"name":"Pipl Coin","symbol":"PIPL","address":"0xE12723848699AdcbFF466050cE17Cbf5C6156720","price":0.1},
  {"name":"GMAAR Token","symbol":"GMAAR","address":"0x7A81eeB086e4502bb8A022F804831575db42Fa07","price":0.001},
  {"name":"EuroGrosh","symbol":"eGROSH","address":"0x542e258550dC85EdE56D9480Bc6A68c07158ed24","price":100},
  {"name":"Grosh Coin","symbol":"GROSH","address":"0xb67B9a92D902F8beadEe9846CDde40a10F198808","price":0.01},
  {"name":"CryptoCode","symbol":"CODE","address":"0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942","price":1},
  {"name":"MetaCoin","symbol":"META","address":"0xfe1E4B7515b933faAF7B32e569eB245b7542b03C","price":0.001}],

  loanTokensData:[{"name":"BTCBToken","symbol":"BTCB","address":"0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c","token_amount":0.05,"gbl_amount":5000,"balance":0},
  {"name":"EthereumToken","symbol":"ETH","address":"0x2170Ed0880ac9A755fd29B2688956BD959F933F8","token_amount":0.5,"gbl_amount":5000,"balance":0},
  {"name":"PronCoin","symbol":"PRON","address":"0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393","token_amount":5,"gbl_amount":5000,"balance":0},
  {"name":"WrappedSOL","symbol":"SOL","address":"0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e","token_amount":50,"gbl_amount":5000,"balance":0},
  {"name":"GetonCoin","symbol":"GETON","address":"0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51","token_amount":50,"gbl_amount":5000,"balance":2092.98},
  {"name":"LifeOnScreen","symbol":"LOS","address":"0xc1463935d11EB0C9a06530F387077053Ebe4500B","token_amount":100,"gbl_amount":5000,"balance":0},
  {"name":"CarbCoin","symbol":"CARB","address":"0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d","token_amount":250,"gbl_amount":5000,"balance":0},
  {"name":"XRPToken","symbol":"XRP","address":"0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE","token_amount":500,"gbl_amount":5000,"balance":0},
  {"name":"ATONReversalVoucher","symbol":"ARV","address":"0xC01419523900ACCD39EBA74c5a1B6909154F99a3","token_amount":500,"gbl_amount":5000,"balance":1},
  {"name":"CardanoToken","symbol":"ADA","address":"0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47","token_amount":500,"gbl_amount":5000,"balance":0},
  {"name":"PolkadotToken","symbol":"DOT","address":"0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402","token_amount":500,"gbl_amount":5000,"balance":0},
  {"name":"PancakeSwapToken","symbol":"Cake","address":"0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82","token_amount":500,"gbl_amount":5000,"balance":0},
  {"name":"CryptoBoardToken","symbol":"CBDX","address":"0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E","token_amount":500,"gbl_amount":5000,"balance":0},
  {"name":"PiplCoin","symbol":"PIPL","address":"0xE12723848699AdcbFF466050cE17Cbf5C6156720","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"CryptoCode","symbol":"CODE","address":"0xdc514814ECE982fCE52D5b5dFD8D22fa652FB942","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"EuroGrosh","symbol":"eGROSH","address":"0x542e258550dC85EdE56D9480Bc6A68c07158ed24","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"GroshCoin","symbol":"GROSH","address":"0xb67B9a92D902F8beadEe9846CDde40a10F198808","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"AirPodToken","symbol":"APOD","address":"0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"GMAARToken","symbol":"GMAAR","address":"0x7A81eeB086e4502bb8A022F804831575db42Fa07","token_amount":5000,"gbl_amount":5000,"balance":0},
  {"name":"SHIBAINU","symbol":"SHIB","address":"0xb1547683DA678f2e1F003A780143EC10Af8a832B","token_amount":49999.99999999999,"gbl_amount":5000,"balance":13.10815536044723},
  {"name":"MetaCoin","symbol":"META","address":"0xfe1E4B7515b933faAF7B32e569eB245b7542b03C","token_amount":5000000,"gbl_amount":5000,"balance":100}],
  tokenIcons: {
      "POL":"https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.2.2.0",
      "BNB":"https://bscscan.com/assets/bsc/images/svg/logos/bnb-token.svg?v=23.9.3.0",
      "USDT":"https://tokens.pancakeswap.finance/images/symbol/usdt.png",
      "WBNB":"https://bscscan.com/assets/bsc/images/svg/logos/bnb-token.svg?v=23.9.3.0",
      "BTCB":"https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
      "WBTC":"https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    "ETH":"https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "WETH":"https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "XRP":"https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    "SOL":"https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    "ADA":"https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png",
    "PRON":"https://logos.waveswaps.com/assets/PRON.svg",
    "GETON":"https://logos.waveswaps.com/assets/GETON.svg",
    "LOS":"https://logos.waveswaps.com/assets/LOS.svg",
    "CARB":"https://logos.waveswaps.com/assets/CARB.svg",
    "ARV":"https://logos.waveswaps.com/assets/ARV.svg",
    "DOT":"https://logos.waveswaps.com/assets/DOT.svg",
    "CAKE":"https://logos.waveswaps.com/assets/CAKE.svg",
    "WAVE":"https://logos.waveswaps.com/assets/WAVE.svg",
    "CBDX":"https://logos.waveswaps.com/assets/CBDX.svg",
    "PIPL":"https://logos.waveswaps.com/assets/PIPL.svg",
    "GROSH":"https://logos.waveswaps.com/assets/GROSH.svg",
    "GBL":"https://logos.waveswaps.com/assets/GBL.svg",
    "VGBL":"https://logos.waveswaps.com/assets/VGBL.svg",
    "Cake":"https://logos.waveswaps.com/assets/CAKE.svg",
    "SHIB":"https://logos.waveswaps.com/assets/SHIB.svg",
    "GMAAR":"https://logos.waveswaps.com/assets/GMAAR.svg",
    "CODE":"https://logos.waveswaps.com/assets/CODE.svg",
    "eGROSH":"https://logos.waveswaps.com/assets/eGROSH.svg",
    "META":"https://logos.waveswaps.com/assets/META.svg",
    "ATE":"https://logos.waveswaps.com/assets/ATE.svg",
    "WMATIC":"https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=24.2.2.0",
  },
};

export default SETTINGS;