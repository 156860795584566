// src/pages/Transactions.js
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { getTransactions } from '../services/api';
import { format } from 'date-fns';

function Transactions() {
  const { user } = useContext(UserContext);
  const [txs, setTxs] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTxs = async (page, limit, searchTerm) => {
    setLoading(true);
    setError(null);
    try {
      const res = await getTransactions({ page, limit, search: searchTerm });
      setTxs(res.data.transactions || []);
      setTotalPages(res.data.pagination?.totalPages || 1);
      setTotalTransactions(res.data.pagination?.total || 0);
    } catch (err) {
      console.error('Failed to load transactions:', err);
      setError('Failed to load transactions. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTxs(currentPage, rowsPerPage, search);
  }, [currentPage, rowsPerPage, search]);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  function shortenEmail(email) {
    if (!email) return '';
    const [name, domain] = email.split('@');
    return `${name.length > 10 ? `${name.substring(0, 10)}...` : name}@${domain}`;
  }

  function shortenHash(hash) {
    if (!hash) return '';
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  }

  function formatDate(date) {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  }

  return (
    <div className="container mx-auto my-4">
      <h2 className="mb-4 text-xl font-bold">Transaction History</h2>
      <div className="flex flex-col md:flex-row justify-between items-center mb-3">
        <input
          type="text"
          className="w-full md:w-auto border border-gray-300 rounded p-2 mb-2 md:mb-0 md:mr-2"
          placeholder="Search transactions..."
          value={search}
          onChange={handleSearchChange}
          style={{ maxWidth: '300px' }}
        />
        <div className="flex items-center">
          <label className="mr-2 text-sm">Rows per page:</label>
          <select
            className="border border-gray-300 dark:text-gray-500 rounded p-2"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            style={{ width: '80px' }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>
      {loading ? (
        <div className="text-center my-5">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
          <p className="mt-4 text-white">Loading Dashboard...</p>
        </div>
      ) : error ? (
        <div className="bg-red-100 text-red-700 p-3 rounded" role="alert">
          {error}
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">Date</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">From</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">To</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">Type</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">Asset</th>
                  <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">Amount</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300  uppercase">Tx Hash</th>
                </tr>
              </thead>
              <tbody className="dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {txs.length > 0 ? (
                  txs.map((tx) => (
                    <tr key={tx.id}>
                      <td className="px-4 py-2 text-sm">{formatDate(tx.created_at)}</td>
                      <td className="px-4 py-2 text-sm">{shortenEmail(tx.sender_email)}</td>
                      <td className="px-4 py-2 text-sm">{shortenEmail(tx.recipient_email)}</td>
                      <td className="px-4 py-2 text-sm">{tx.tx_type}</td>
                      <td className="px-4 py-2 text-sm">{tx.asset}</td>
                      <td className="px-4 py-2 text-sm text-right">{Number(tx.amount).toFixed(2)}</td>
                      <td className="px-4 py-2 text-sm">
                        <a
                          href={`https://bscscan.com/tx/${tx.tx_hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {shortenHash(tx.tx_hash)}
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center text-sm">
                      No transactions found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="text-sm">
              Showing {txs.length} of {totalTransactions} transactions
            </div>
            <div className="flex space-x-2">
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white text-xs px-3 py-1 rounded disabled:opacity-50"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(1)}
              >
                First
              </button>
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white text-xs px-3 py-1 rounded disabled:opacity-50"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              <span className="bg-gray-200 text-gray-700 text-xs px-3 py-1 rounded">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white text-xs px-3 py-1 rounded disabled:opacity-50"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white text-xs px-3 py-1 rounded disabled:opacity-50"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(totalPages)}
              >
                Last
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Transactions;
