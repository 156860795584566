import React, { useState, useEffect } from "react";
import { BrowserProvider, Contract, formatUnits, parseUnits, isAddress, parseEther } from "ethers";
import SETTINGS from "../config/swap";
import WaweSwapsABI from "../abis/WaweSwapsV3ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV3ABI";
import { ERC20_ABI } from "../abis/erc20";
import { BiCopy, BiArrowToBottom, BiCaretDown } from "react-icons/bi";
import ToastNotification from "./ToastNotification";
import InfoModal from "./InfoModal";
import TooltipInfoReverse from "./TooltipInfoReverse";

function SwapV3({
  provider,
  selectedAccount,
  networkSymbol,
  isConnected,
  networkName,
  networkId,
  switchNetwork,
}) {
  // State variables
  const [isLoading, setIsLoading] = useState(true);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [ercBalance, setErcBalance] = useState(0);
  const [ercAlowance, setErcAlowance] = useState("");
  const [gblBalance, setGblBalance] = useState(0);
  const [swapV3Balance, setSwapV3Balance] = useState("");
  const [unclaimedGblRewards, setUnclaimedGblRewards] = useState(0);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
  const [swapTokenAmount, setSwapTokenAmount] = useState("");
  const [gblBuyAmount, setGblBuyAmount] = useState("");
  const [usdtBuyValue, setUsdtBuyValue] = useState("");
  const [usdtSellAmount, setUsdtSellAmount] = useState("");
  const [priceImpact, setPriceImpact] = useState(0);
  const [buyTokens, setBuyTokens] = useState([]);
  const [userSwaps, setUserSwaps] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [waveStakes, setWaveStakes] = useState([]);

  const [selectDisabled, setSelectDisabled] = useState(false);
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [showCollectGblModal, setShowCollectGblModal] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [loadingStep, setLoadingStep] = useState(1);
  const [loadingNumber, setLoadingNumber] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState("Select");

  const [refferalAddress, setRefferalAddress] = useState("");
  const [refferalDisabled, setRefferalDisabled] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastUrl, setToastUrl] = useState(null);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("-");

  // Local state for accordions (custom Tailwind accordions)
  const [waveAccordionOpen, setWaveAccordionOpen] = useState({});
  const [swapAccordionOpen, setSwapAccordionOpen] = useState({});

  // Constants
  const waweSwapV3Fees = {
    1: 0,
    10: 0,
    137: "500000000000000000",
    56: 0,
    42161: 0,
  };
  const exportFeeAddresses = {
    10: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
    137: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
    56: "0x07dDF9A4230A0324317e062F9ccD666E26887Db2",
    42161: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
  };
  const exportFeeAmounts = {
    10: "0.0007",
    137: "2",
    56: "0.0032",
    42161: "0.0007",
  };
  const waweSwapV3Addresses = {
    1: "",
    10: SETTINGS.waweSwapAddressOptimism,
    137: SETTINGS.waweSwapAddressPolygon,
    56: SETTINGS.waweSwapAddressBsc,
    42161: SETTINGS.waweSwapAddressArbitrum,
  };
  const waweSwapStorageV3Addresses = {
    1: "",
    10: SETTINGS.waweSwapStorageAddressOptimism,
    137: SETTINGS.waweSwapStorageAddressPolygon,
    56: SETTINGS.waweSwapStorageAddressBsc,
    42161: SETTINGS.waweSwapStorageAddressArbitrum,
  };
  const waweSwapV3BuyTokens = {
    1: SETTINGS.buyTokensDataEthereum,
    10: SETTINGS.buyTokensDataOptimism,
    137: SETTINGS.buyTokensDataPolygon,
    56: SETTINGS.buyTokensData,
    42161: SETTINGS.buyTokensDataArbitrum,
  };

  // Toast display helper
  const displayToast = (msg, url = null, error = false) => {
    setToastMessage(msg);
    setToastUrl(url);
    setToastError(error);
    setShowToast(true);
  };

  // ------------------------- Original Functions (kept unchanged) -------------------------

  const getWaveStakes = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const userStakes = await contract.getUserWaveStakes(userAddress);
      const stakesWithClaimable = await Promise.all(
        userStakes.map(async (stake, index) => {
          const claimableAmount = await contract.getClaimableWaveAmount(userAddress, index);
          return {
            amount: parseFloat(formatUnits(stake.amount.toString(), 18)).toFixed(6),
            startTime: new Date(parseInt(stake.startTime.toString()) * 1000).toLocaleString(),
            claimedAmount: formatUnits(stake.claimedAmount.toString(), 18),
            claimableAmount: parseFloat(formatUnits(claimableAmount.toString(), 18)).toFixed(6),
          };
        })
      );
      setWaveStakes(stakesWithClaimable);
    } catch (error) {
      console.error("Error fetching stakes:", error);
    }
  };

  const claimWaveStake = async (stakeIndex) => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claimWave(stakeIndex, {
        value: waweSwapV3Fees[parseInt(networkId)],
      });
      await tx.wait();
      alert("Claim successful!");
      await loadUserData();
      setIsLoading(false);
    } catch (error) {
      console.error("Error claiming stake:", error);
      setIsLoading(false);
    }
  };

  const closeSwap = async (_swapIndex) => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.openWaveStake(_swapIndex, {
        value: waweSwapV3Fees[parseInt(networkId)],
      });
      await tx.wait();
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
      displayToast("Swap closed.", tx.hash);
    } catch (error) {
      console.error("Swap close failed", error);
      setIsLoading(false);
    }
  };

  const getBalance = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const balanceWei = await ethersProvider.getBalance(selectedAccount);
      const balanceEth = formatUnits(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const getBalanceERC = async (addr, user) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const TokenContract = new Contract(addr, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(user);
      const TokenDecimals = await TokenContract.decimals();
      return formatUnits(TokenBalance, TokenDecimals);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };

  const firstLoad = async () => {
    await loadUserData();
    showAvailableTokens();
    setIsLoading(false);
  };

  const loadUserData = async () => {
    setGblBalance(
      await getBalanceERC(
        SETTINGS.gblAddresses[parseInt(networkId)],
        selectedAccount
      )
    );
    const contractBal = parseFloat(
      await getBalanceERC(
        SETTINGS.gblAddresses[parseInt(networkId)],
        waweSwapV3Addresses[parseInt(networkId)]
      )
    ).toFixed(2);
    setSwapV3Balance(contractBal);
    setErcBalance(await getBalance());
    setErcAlowance("-");

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const myRefferer = await contract.referrals(selectedAccount);
      if (myRefferer !== "0x0000000000000000000000000000000000000000") {
        setRefferalAddress(myRefferer);
        setRefferalDisabled(true);
      }
      if (parseInt(networkId) === 137) {
        const contractOldPolygon = new Contract(
          "0x48b9b7761f6bc5c3e560350b9D7c904FF8e9E5DF",
          WaweSwapsABI,
          signer
        );
        const myReffererOldPolygon = await contractOldPolygon.referrals(
          selectedAccount
        );
        if (myReffererOldPolygon !== "0x0000000000000000000000000000000000000000") {
          setRefferalAddress(myReffererOldPolygon);
          setRefferalDisabled(true);
        }
        const contractOldPolygonCustom = new Contract(
          SETTINGS.waweSwapAddressPolygonOld,
          WaweSwapsABI,
          signer
        );
        const myReffererOldPolygonCustom =
          await contractOldPolygonCustom.referrals(selectedAccount);
        if (
          myReffererOldPolygonCustom !== "0x0000000000000000000000000000000000000000"
        ) {
          setRefferalAddress(myReffererOldPolygonCustom);
          setRefferalDisabled(true);
        }
      }
      if (parseInt(networkId) === 56) {
        const contractOldBsc = new Contract(
          SETTINGS.waweSwapAddressBscOld,
          WaweSwapsABI,
          signer
        );
        const myReffererOldBsc = await contractOldBsc.referrals(selectedAccount);
        if (myReffererOldBsc !== "0x0000000000000000000000000000000000000000") {
          setRefferalAddress(myReffererOldBsc);
          setRefferalDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error fetching referral data:", error);
    }

    if (parseInt(networkId) === 137) {
      await getWaveStakes(selectedAccount);
    }
    const swaps = await getSwapsForUser(selectedAccount);
    setUserSwaps(swaps);
    setIsLoading(false);
  };

  const getTimeLeft = (createdAt) => {
    const endTime = new Date((createdAt + 30 * 24 * 60 * 60) * 1000);
    const now = new Date();
    const timeLeft = endTime - now;
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${daysLeft} days and ${hoursLeft} hours left`;
  };

  const getSwapsForUser = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const contractStorage = new Contract(
        waweSwapStorageV3Addresses[parseInt(networkId)],
        WaweSwapStorageABI,
        signer
      );
      const userSwaps = await contractStorage.getSwaps(userAddress);
      const userRewardAmountWei = await contractStorage.usdtRewards(userAddress);
      const userGblRewardAmountWei = await contractStorage.gblRewards(userAddress);
      let tokenDecimals = parseInt(networkId) === 56 ? 18 : 6;
      const userRewardAmount = parseFloat(
        formatUnits(userRewardAmountWei, tokenDecimals === 18 ? "ether" : "mwei")
      );
      const userGblRewardAmount = parseFloat(formatUnits(userGblRewardAmountWei, "ether"));
      let swaps = [];
      for (let i = 0; i < userSwaps.length; i++) {
        const amount = parseFloat(formatUnits(userSwaps[i][0], "ether"));
        const startValue = parseFloat(
          formatUnits(userSwaps[i][2], tokenDecimals === 18 ? "ether" : "mwei")
        );
        const sellAmount = parseFloat(
          formatUnits(userSwaps[i][1], tokenDecimals === 18 ? "ether" : "mwei")
        );
        const createdAt = parseInt(userSwaps[i][4]);
        let feePercentage = getFeePercentage(startValue);
        let maxSellAmount = startValue * parseFloat(feePercentage);
        if (createdAt <= 1719865467) {
          maxSellAmount = startValue;
        }
        let soldAmount = maxSellAmount - sellAmount;
        if (soldAmount < 0) soldAmount = 0;
        const swapIndex = parseInt(userSwaps[i][5]);
        swaps.push({
          uid: i,
          swapIndex: swapIndex,
          amount: amount.toFixed(2),
          startValue: startValue.toFixed(2),
          sellAmount: sellAmount.toFixed(2),
          maxSellAmount: maxSellAmount.toFixed(2),
          soldAmount: soldAmount.toFixed(2),
          timeLeft: getTimeLeft(parseInt(userSwaps[i][4])),
        });
      }
      setUnclaimedRewards(userRewardAmount.toFixed(4));
      setUnclaimedGblRewards(userGblRewardAmount.toFixed(4));
      let feePercentage;
      if (userRewardAmount < 100) {
        feePercentage = 0.02;
      } else if (userRewardAmount >= 100 && userRewardAmount < 1000) {
        feePercentage = 0.01;
      } else {
        feePercentage = 0.005;
      }
      const feeAmount = userRewardAmount * feePercentage;
      const netRewardAmount = userRewardAmount - feeAmount;
      setUnclaimedRewardsFee(feeAmount.toFixed(4));
      setUnclaimedRewardsNet(netRewardAmount.toFixed(4));
      return swaps.reverse();
    } catch (error) {
      console.error("Error fetching swap data:", error);
      return [];
    }
  };

  const handleTokenChange = async (e) => {
    const selectedAddress = e.target.value;
    if (selectedAddress === "") {
      setSelectedToken(null);
      setSelectDisabled(false);
    } else {
      if (!buyTokens) return;
      const token = buyTokens.find((token) => token.address === selectedAddress);
      if (!token) return;
      setSelectedToken(token);
      if (token.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setErcBalance(await getBalance());
        setErcAlowance("-");
      } else {
        setErcBalance(await getBalanceERC(token.address, selectedAccount));
        try {
          const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const ercContract = new Contract(token.address, ERC20_ABI, signer);
          const allowanceERC = await ercContract.allowance(
            selectedAccount,
            waweSwapV3Addresses[parseInt(networkId)]
          );
          const decimals = await ercContract.decimals();
          const formattedBalance = parseFloat(formatUnits(allowanceERC, decimals));
          setErcAlowance(formattedBalance.toFixed(4));
        } catch (error) {
          console.error("Error fetching token allowance:", error);
          setErcAlowance("0");
        }
      }
      setSwapTokenAmount("");
      setGblBuyAmount(0);
      setUsdtSellAmount(0);
      setUsdtBuyValue(0);
      setSelectDisabled(false);
    }
    setSwapAvailable(false);
  };

  const getPriceNetwork = async (tokenAddress) => {
    const routerABI = [
      {
        constant: true,
        inputs: [
          { internalType: "uint256", name: "amountIn", type: "uint256" },
          { internalType: "address[]", name: "path", type: "address[]" },
        ],
        name: "getAmountsOut",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];
    const usdtAddress = SETTINGS.usdtAddress[parseInt(networkId)];
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const routerContract = new Contract("0x10ED43C718714eb63d5aA57B78B54704E256024E", routerABI, signer);
    const amountIn = parseUnits("1", 18);
    try {
      const amountsOut = await routerContract.getAmountsOut(amountIn, [tokenAddress, usdtAddress]);
      let priceInUSDT = amountsOut[1];
      return priceInUSDT;
    } catch (error) {
      console.error("Error fetching price:", error);
      return 999;
    }
  };

  const getUniswapV3PriceUSDT = async (tokenAddress, poolFee) => {
    if (parseInt(networkId) === 56) {
      const p = await getPriceNetwork(tokenAddress);
      return p;
    }
    const usdtAddress = SETTINGS.usdtAddress[parseInt(networkId)];
    const quoterABI = [
      {
        inputs: [
          { internalType: "address", name: "tokenIn", type: "address" },
          { internalType: "address", name: "tokenOut", type: "address" },
          { internalType: "uint24", name: "fee", type: "uint24" },
          { internalType: "uint256", name: "amountIn", type: "uint256" },
          { internalType: "uint160", name: "sqrtPriceLimitX96", type: "uint160" },
        ],
        name: "quoteExactInputSingle",
        outputs: [{ internalType: "uint256", name: "amountOut", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
    ];
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const quoterContract = new Contract(SETTINGS.quoterAddress[parseInt(networkId)], quoterABI, signer);
    const amountIn = parseUnits("1", 18);
    try {
      const quote = await quoterContract.quoteExactInputSingle(tokenAddress, usdtAddress, poolFee, amountIn, 0);
      return quote.toString();
    } catch (error) {
      console.error("Error fetching price:", error);
      return "9999999999";
    }
  };

  const handleRefferalAddressChange = async (e) => {
    const addr = e.target.value;
    if (addr.length === 0) return;
    if (!isAddress(addr)) {
      displayToast("Wrong address type", null, true);
      setRefferalAddress("");
      return;
    }
    if (addr.toLowerCase() === selectedAccount.toLowerCase()) {
      displayToast("You can't use your own address.", null, true);
      setRefferalAddress("");
      return;
    }
    setRefferalAddress(addr);
  };

  const handleBuyAmountChange = async (e) => {
    const amount = e.target.value;
    setSwapTokenAmount(amount);
    setGblBuyAmount(0);
    setUsdtSellAmount(0);
    setUsdtBuyValue(0);
    if (amount === "") {
      setSwapAvailable(false);
    } else {
      if (parseFloat(amount) > parseFloat(ercBalance)) {
        setErrorText("Balance too low!");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setSwapAvailable(false);
      } else {
        setErrorText("");
        let dec = parseInt(networkId) === 56 ? 18 : 6;
        let tokenPriceUSDT = parseUnits("1", dec);
        if (selectedToken.symbol !== "USDT") {
          tokenPriceUSDT = await getUniswapV3PriceUSDT(selectedToken.address, 10000);
        }
        const gblPriceUSDT = await getUniswapV3PriceUSDT(SETTINGS.gblAddresses[parseInt(networkId)], 10000);
        const tokenPriceUSDTDecimal = parseFloat(tokenPriceUSDT) / Math.pow(10, dec);
        const gblPriceUSDTDecimal = parseFloat(gblPriceUSDT) / Math.pow(10, dec);
        const amountInUSDT = parseFloat(amount) * tokenPriceUSDTDecimal;
        const amountGblToBuy = amountInUSDT / gblPriceUSDTDecimal;
        let feePercentage = getFeePercentage(amountInUSDT);
        setPriceImpact(((feePercentage - 1) * 100).toFixed(0));
        setGblBuyAmount(parseFloat(amountGblToBuy).toFixed(6));
        setUsdtBuyValue(parseFloat(amountInUSDT).toFixed(6));
        setUsdtSellAmount((parseFloat(amountInUSDT) * feePercentage).toFixed(6));
        setSwapAvailable(true);
      }
    }
  };

  const getFeePercentage = (amountInUSDT) => {
    let feePercentage = 1.1;
    if (parseFloat(amountInUSDT) < 100) {
      feePercentage = 1.5;
    } else if (parseFloat(amountInUSDT) >= 100 && parseFloat(amountInUSDT) < 250) {
      feePercentage = 1.4;
    } else if (parseFloat(amountInUSDT) >= 250 && parseFloat(amountInUSDT) < 750) {
      feePercentage = 1.3;
    } else if (parseFloat(amountInUSDT) >= 750 && parseFloat(amountInUSDT) < 1000) {
      feePercentage = 1.2;
    } else if (parseFloat(amountInUSDT) >= 1000) {
      feePercentage = 1.1;
    }
    return feePercentage;
  };

  const reopenSwap = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.reopenSwap(refferalAddress);
      await tx.wait();
      await loadUserData();
      setIsLoading(false);
      displayToast("Stake reopened.", tx.hash);
    } catch (error) {
      console.error("Stake failed", error);
      setIsLoading(false);
    }
  };

  const collectIncome = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claimUsdtReward({
        gasLimit: 300000,
        from: selectedAccount,
      });
      await tx.wait();
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
      displayToast("Income claimed.", tx.hash);
    } catch (error) {
      console.error("Claim failed", error);
      setIsLoading(false);
    }
  };

  const collectGblIncome = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claimGblReward({
        gasLimit: 300000,
        from: selectedAccount,
      });
      await tx.wait();
      await loadUserData();
      handleCloseCollectGblModal();
      setIsLoading(false);
      displayToast("Income claimed.", tx.hash);
    } catch (error) {
      console.error("Claim failed", error);
      setIsLoading(false);
    }
  };

  const handleSwap = async () => {
    setIsLoading(true);
    if (parseInt(networkId) !== 56) {
      await handleSwapOthers();
      return;
    }
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE();
      if (selectedToken.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setLoadingNumber(1);
        let refAddr = SETTINGS.genessisAddress;
        if (refferalAddress !== "") {
          refAddr = refferalAddress;
        }
        const swapAmountWithFee =
          parseFloat(swapTokenAmount) +
          parseFloat(formatUnits(fee.toString(), "ether"));
        const bnbAmountInWei = parseUnits(swapAmountWithFee.toString(), "ether");
        setLoadingStep(1);
        setLoadingText("Starting V3 GBL Bot");
        const tx = await contract.buySwapWithETH(refAddr, {
          from: selectedAccount,
          value: bnbAmountInWei,
        });
        await tx.wait();
        await loadUserData();
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setIsLoading(false);
        displayToast("Position Opened!", tx.hash);
      }
    } catch (error) {
      console.error("Swap failed", error);
      displayToast(error.message, null, true);
    } finally {
      setSwapAvailable(false);
      setIsLoading(false);
    }
  };

  const copyRefferalLink = async () => {
    const text = "https://waveswaps.com/?v=3&reff=" + selectedAccount;
    navigator.clipboard.writeText(text);
    displayToast("Link coppied!");
  };

  const handleSwapOthers = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE();
      if (selectedToken.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setLoadingNumber(1);
        const swapAmountWithFee =
          parseFloat(swapTokenAmount) +
          parseFloat(formatUnits(fee.toString(), "ether"));
        const bnbAmountInWei = parseUnits(swapAmountWithFee.toString(), "ether");
        setLoadingStep(1);
        setLoadingText("Starting V3 GBL Bot");
        let refAddr = SETTINGS.genessisAddress;
        if (refferalAddress !== "") {
          refAddr = refferalAddress;
        }
        const gblAmountSlippage =
          parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
        const gblAmountInWei = parseUnits(gblBuyAmount.toString(), "ether");
        const gblAmountSlippageInWei = parseUnits(gblAmountSlippage.toString(), "ether");
        const buyValueSlippage =
          parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
        const buyValueInWei = parseUnits(buyValueSlippage.toString(), "ether");
        const tx = await contract.buySwapWithETH(refAddr, {
          from: selectedAccount,
          value: bnbAmountInWei,
        });
        await loadUserData();
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setIsLoading(false);
        displayToast("Position Opened!", tx.hash);
      } else {
        setLoadingNumber(2);
        setLoadingStep(1);
        setLoadingText("Approving " + selectedToken.symbol + " transaction.");
        const tokenContract = new Contract(selectedToken.address, ERC20_ABI, signer);
        const tokenDecimals = await tokenContract.decimals();
        const amountInTokenUnits = swapTokenAmount;
        const amountInWei = parseUnits(amountInTokenUnits.toString(), tokenDecimals);
        try {
          const allowanceToken = await tokenContract.allowance(
            selectedAccount,
            waweSwapV3Addresses[parseInt(networkId)]
          );
          await tokenContract.approve(waweSwapV3Addresses[parseInt(networkId)], amountInWei);
          setLoadingStep(2);
          setLoadingText("Starting V3 GBL Bot");
          const gblAmountSlippage =
            parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
          const gblAmountInWei = parseUnits(gblBuyAmount.toString(), "ether");
          const gblAmountSlippageInWei = parseUnits(gblAmountSlippage.toString(), "ether");
          const buyValueSlippage =
            parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
          const buyValueInWei = parseUnits(buyValueSlippage.toString(), "ether");
          const tx = await contract.buySwap(
            selectedToken.address,
            amountInWei,
            gblAmountInWei,
            buyValueInWei,
            gblAmountSlippageInWei
          );
          await loadUserData();
          setSwapTokenAmount("");
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
          setIsLoading(false);
          displayToast("Position Opened!", tx.hash);
        } catch (error) {
          console.error("Send failed", error);
          displayToast(error.message, null, true);
        }
      }
    } catch (error) {
      await loadUserData();
      console.error("Swap failed", error);
      displayToast(error.message, null, true);
    } finally {
      setSwapAvailable(false);
      setIsLoading(false);
    }
  };

  const showAvailableTokens = () => {
    setBuyTokens(waweSwapV3BuyTokens[parseInt(networkId)]);
  };

  const exportSwapsUser = async () => {
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Exporting swaps.");
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const feeAddress = exportFeeAddresses[parseInt(networkId)];
    const feeAm = parseEther(exportFeeAmounts[parseInt(networkId)]);
    const transaction = await signer.sendTransaction({
      to: feeAddress,
      value: feeAm,
    });
    await transaction.wait();
    await exportSwaps();
    setIsLoading(false);
  };

  const exportSwaps = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const waweSwapStorageV3Address = waweSwapStorageV3Addresses[parseInt(networkId)];
    const contractStorage = new Contract(waweSwapStorageV3Address, WaweSwapStorageABI, signer);
    const contract = new Contract(waweSwapV3Addresses[parseInt(networkId)], WaweSwapsABI, signer);
    let allOpenSwaps = await contractStorage.getAllOpenSwaps();
    let csvResult = "Address;Swapped_Value;Target_Value;Swap_Value;GBL_Liquidity\n";
    let swapsArr = [];
    for (let i = 0; i < allOpenSwaps.length; i++) {
      try {
        let swap = allOpenSwaps[i];
        if (swap[3] === "0x0000000000000000000000000000000000000000") continue;
        let tokenDecimals = parseInt(networkId) === 56 ? "ether" : "mwei";
        const swapAmount = formatUnits(swap[0], "ether");
        const sellAmount = formatUnits(swap[1], tokenDecimals);
        const startValue = formatUnits(swap[2], tokenDecimals);
        const addr = swap[3];
        let feePercentage = getFeePercentage(startValue);
        let maxSellAmount = startValue * parseFloat(feePercentage);
        csvResult += `${addr};${sellAmount};${maxSellAmount};${startValue};${swapAmount}\n`;
      } catch (error) {
        console.error("wrong err", error);
      }
    }
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV3_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  // ------------------------- useEffect for Initialization -------------------------
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search);
    const ref = sp.get("reff");
    if (ref) {
      setRefferalAddress(ref);
      setRefferalDisabled(true);
    }
    if (selectedAccount) {
      firstLoad();
    } else {
      setGblBalance(0);
      setSwapV3Balance(0);
      setUserSwaps([]);
      showAvailableTokens();
    }
    if (parseInt(networkId) === 56) {
      handleSelect({
        symbol: "BNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      });
    }
    if (parseInt(networkId) === 137) {
      handleSelect({
        symbol: "MATIC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      });
    }
    if (parseInt(networkId) === 42161) {
      handleSelect({
        symbol: "ETH",
        address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      });
    }
    if (parseInt(networkId) === 10) {
      handleSelect({
        symbol: "ETH",
        address: "0x4200000000000000000000000000000000000006",
      });
    }
  }, [selectedAccount, buyTokens, networkId]);

  // A helper to set selected token from dropdown
  const handleSelect = (token) => {
    setSelectedLabel(token.symbol);
    handleTokenChange({ target: { value: token.address } });
  };

  // Modal close handlers
  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };
  const handleShowCollectModal = () => {
    setShowCollectModal(true);
  };
  const handleCloseCollectGblModal = () => {
    setShowCollectGblModal(false);
  };
  const handleShowCollectGblModal = () => {
    setShowCollectGblModal(true);
  };

  // ------------------------- Render -------------------------
  return (
    <div className="my-12">
      {isLoading && (
        <div className="flex flex-col items-center justify-center mb-8">
          <div className="animate-spin">
            <svg className="h-10 w-10 text-blue-500" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z" />
            </svg>
          </div>
          <p className="mt-2 text-lg font-semibold">
            {loadingStep} / {loadingNumber} <br /> {loadingText}
          </p>
        </div>
      )}

      <ToastNotification
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        url={toastUrl}
        error={toastError}
        networkId={networkId}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column – Swap V3 Bot Form */}
        <div className="bg-white shadow rounded p-6">
          {selectedAccount &&
            selectedAccount.toLowerCase() === SETTINGS.ownerAddress.toLowerCase() && (
              <button
                onClick={exportSwaps}
                className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
              >
                Export Admin
              </button>
            )}

          <div className="flex justify-between items-center mb-4">
            <InfoModal
              link="https://docs.waveswaps.com/swaps/v3-gbl-bot"
              message="Developed from the Swap V3, the V3 GBL Bot offers users the opportunity to increase their deposited liquidity provision value by 10% to 50%, potentially providing stable returns in the form of a stable coin. Additionally, purchasing GBL coin increases liquidity, contributing to market stability and reducing volatility. Users can now claim remaining balances through WaveCoin. However, this is an additional, optional feature. If not chosen, swaps will continue processing as set innitially."
            />
          </div>

          <h4 className="text-xl font-bold mb-4">Revitalize GBL Tokens</h4>

          {selectedAccount && (
            <div className="relative bg-blue-600 text-white p-4 rounded mb-4">
              <label className="block">Earn 10% from referrals' swaps!</label>
              <button
                onClick={copyRefferalLink}
                className="absolute right-4 top-4 flex items-center space-x-1"
              >
                <BiCopy className="h-4 w-4" />
                <span className="text-sm">Copy</span>
              </button>
            </div>
          )}

          <div className="bg-gray-100 p-4 rounded mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Liquidity Provision
            </label>
            <div className="flex space-x-2 mt-2">
              <input
                id="tokenAmount"
                type="text"
                className="flex-1 border border-gray-300 rounded p-2"
                placeholder="0"
                value={swapTokenAmount}
                onChange={handleBuyAmountChange}
              />
              <select
                value={selectedToken ? selectedToken.address : ""}
                onChange={(e) => handleTokenChange({ target: { value: e.target.value } })}
                disabled={selectDisabled}
                className="border border-gray-300 rounded p-2"
              >
                <option value="">Select</option>
                {buyTokens && buyTokens.length > 0 ? (
                  buyTokens.map((token) => (
                    <option key={token.address} value={token.address}>
                      {token.symbol}
                    </option>
                  ))
                ) : (
                  <option disabled>No tokens available</option>
                )}
              </select>
            </div>
            <label className="text-xs text-gray-500 mt-1">Balance: {ercBalance}</label>
          </div>

          <div className="flex justify-center my-4">
            <BiArrowToBottom className="h-6 w-6 text-gray-500" />
          </div>

          <div className="bg-gray-100 p-4 rounded mb-4 space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={SETTINGS.tokenIcons["GBL"]} alt="" className="w-4 mr-2" />
                <span className="text-sm">GBL liquidity:</span>
              </div>
              <span className="text-sm">{gblBuyAmount} GBL</span>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 mr-2" />
                <span className="text-sm">Swap value:</span>
              </div>
              <span className="text-sm">{usdtBuyValue} USDT</span>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 mr-2" />
                <span className="text-sm">
                  Swapping income: <TooltipInfoReverse />
                </span>
              </div>
              <span className="text-sm">{priceImpact} %</span>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 mr-2" />
                <span className="text-sm">Target value:</span>
              </div>
              <span className="text-sm">{usdtSellAmount} USDT</span>
            </div>
          </div>

          <div className="bg-gray-100 p-4 rounded mb-4">
            <label className="block text-sm font-medium text-gray-700">Referral address</label>
            <input
              id="refferalAddress"
              type="text"
              className="w-full border border-gray-300 rounded p-2 text-sm"
              placeholder="0x.. (Optional)"
              value={refferalAddress}
              onChange={handleRefferalAddressChange}
              disabled={refferalDisabled}
            />
          </div>

          <div className="mb-4 text-left">
            <small className="text-xs text-gray-600">
              The referral address used for the first time will remain permanently linked to your current wallet.
              <br />
              When a referral address is not linked or added, the swap will automatically use and allocate the Genesis referral address.
              <br />
              By clicking the button "Start V3 GBL Bot", you agree with WaveSwaps{" "}
              <a
                href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
                target="_blank"
                className="text-blue-500"
              >
                Terms and Conditions.
              </a>
            </small>
          </div>

          <div className="mt-4">
            {swapAvailable ? (
              <button onClick={handleSwap} className="w-full bg-blue-600 text-white py-2 rounded">
                Start V3 GBL Bot
              </button>
            ) : (
              <>
                <button className="w-full bg-gray-400 text-white py-2 rounded cursor-not-allowed">
                  Start V3 GBL Bot
                </button>
                <p className="text-red-500 text-center text-sm mt-2">{errorText}</p>
              </>
            )}
          </div>
          <div className="mt-4">
            <small className="text-xs text-gray-600">
              Swapping income is distributed when enough total native currency has accumulated to execute the distribution function within the V3 GBL Bot smart contract.
            </small>
          </div>
        </div>

        {/* Right Column – Rewards Managing */}
        <div className="bg-white shadow rounded p-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <small className="text-sm font-bold">USDT Rewards:</small>
                <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 ml-2" />
                <span className="text-sm ml-1">{unclaimedRewards} USDT</span>
              </div>
              <button onClick={handleShowCollectModal} className="bg-green-500 text-white py-1 px-3 rounded text-sm">
                Claim
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <small className="text-sm font-bold">GBL rewards:</small>
                <img src={SETTINGS.tokenIcons["GBL"]} alt="" className="w-4 ml-2" />
                <span className="text-sm ml-1">{unclaimedGblRewards} GBL</span>
              </div>
              <button onClick={handleShowCollectGblModal} className="bg-green-500 text-white py-1 px-3 rounded text-sm">
                Claim
              </button>
            </div>
            <div className="flex items-center justify-between">
              <small className="text-sm font-bold">Restart bot with your income:</small>
              <button onClick={reopenSwap} className="bg-blue-600 text-white py-1 px-3 rounded text-sm">
                Restart
              </button>
            </div>
          </div>

          {/* Wave Claim Accordion */}
          {waveStakes.length > 0 && (
            <div className="mt-6">
              <h5 className="text-lg font-bold mb-2">WAVE Claim:</h5>
              <small className="text-xs text-gray-600">
                Your list of migrated and canceled V3 GBL Bots, releasing a fixed, proportional WAVE amount daily over 100 days.
              </small>
              <div className="mt-4 space-y-2">
                {waveStakes.map((stake, index) => (
                  <div key={index} className="border border-gray-200 rounded">
                    <div className="flex justify-between items-center p-2 bg-gray-50">
                      <div className="flex space-x-2">
                        <img src={SETTINGS.tokenIcons["WAVE"]} alt="" className="w-4" />
                        <small className="text-sm">{stake.amount} WAVE</small>
                        <small className="text-sm">Claimable: {stake.claimableAmount} WAVE</small>
                      </div>
                      <button
                        onClick={() =>
                          setWaveAccordionOpen((prev) => ({
                            ...prev,
                            [index]: !prev[index],
                          }))
                        }
                        className="text-gray-600"
                      >
                        <BiCaretDown
                          className={`h-4 w-4 transform ${waveAccordionOpen[index] ? "rotate-180" : ""}`}
                        />
                      </button>
                    </div>
                    {waveAccordionOpen[index] && (
                      <div className="p-2">
                        <div className="flex justify-between">
                          <div>
                            <label className="text-xs">Start Time</label>
                            <p className="text-sm">{stake.startTime}</p>
                          </div>
                          <div>
                            <label className="text-xs">Claimed Amount</label>
                            <p className="text-sm">{parseFloat(stake.claimedAmount).toFixed(4)} WAVE</p>
                          </div>
                        </div>
                        {parseFloat(stake.claimableAmount) > 0 && (
                          <button
                            onClick={() => claimWaveStake(index)}
                            className="mt-2 bg-blue-600 text-white py-1 px-3 rounded text-sm"
                          >
                            Claim WAVE
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* V3 GBL Bot(s) Accordion */}
          <div className="mt-6">
            <h5 className="text-lg font-bold mb-2">V3 GBL Bot(s):</h5>
            <small className="text-xs text-gray-600">
              Your active V3 GBL Bots, designed to maximize liquidity and streamline token recycling through automated processes.
            </small>
            <div className="mt-4 space-y-2">
              {userSwaps.map((swap, index) => (
                <div key={index} className="border border-gray-200 rounded">
                  <div className="p-2 bg-gray-50 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                      <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4" />
                      <small className="text-sm">{swap.soldAmount} USDT</small>
                    </div>
                    <div className="flex items-center space-x-2">
                      <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4" />
                      <small className="text-sm">{swap.maxSellAmount} USDT</small>
                    </div>
                    <button
                      onClick={() =>
                        setSwapAccordionOpen((prev) => ({
                          ...prev,
                          [index]: !prev[index],
                        }))
                      }
                      className="text-gray-600"
                    >
                      <BiCaretDown
                        className={`h-4 w-4 transform ${swapAccordionOpen[index] ? "rotate-180" : ""}`}
                      />
                    </button>
                  </div>
                  {swapAccordionOpen[index] && (
                    <div className="p-2">
                      <div className="flex justify-between">
                        <div>
                          <label className="text-xs">Swap value:</label>
                          <p className="text-sm flex items-center">
                            <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 mr-1" /> {swap.startValue} USDT
                          </p>
                        </div>
                        <div>
                          <label className="text-xs">Outstanding:</label>
                          <p className="text-sm flex items-center">
                            <img src={SETTINGS.tokenIcons["USDT"]} alt="" className="w-4 mr-1" /> {swap.sellAmount} USDT
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between mt-2">
                        <div>
                          <label className="text-xs">Swapping:</label>
                          <p className="text-sm flex items-center">
                            <img src={SETTINGS.tokenIcons["GBL"]} alt="" className="w-4 mr-1" /> {swap.amount} GBL
                          </p>
                        </div>
                        {parseInt(networkId) === 137 && (
                          <div>
                            <label className="text-xs">Migrate to WAVE:</label>
                            <button
                              onClick={() => closeSwap(swap.swapIndex)}
                              className="mt-1 bg-blue-600 text-white py-1 px-2 rounded text-xs"
                            >
                              Close swap and claim {swap.sellAmount} WAVE through 100 days.
                            </button>
                          </div>
                        )}
                        {parseInt(networkId) === 57 && (
                          <div>
                            <label className="text-xs">Earned VGBL</label>
                            <p className="text-sm flex items-center">
                              <img src={SETTINGS.tokenIcons["VGBL"]} alt="" className="w-4 mr-1" /> {swap.amount} VGBL
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="mt-2">
                        <small className="text-xs text-gray-600">
                          *50% of GBL swapped will be credited to your GBL Income at the completion of each swap.
                        </small>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Claim Income */}
      {showCollectModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded shadow-lg z-50 p-6 w-11/12 md:w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Claim Income</h3>
              <button onClick={handleCloseCollectModal} className="text-gray-600 text-2xl leading-none">
                &times;
              </button>
            </div>
            <div className="space-y-4 text-center">
              <div>
                <small>Available USDT Income:</small>
                <input type="text" value={unclaimedRewards} disabled className="w-full border border-gray-300 rounded p-2 text-center" />
              </div>
              <div>
                <small>Amount to Claim:</small>
                <input type="text" value={unclaimedRewardsNet} disabled className="w-full border border-gray-300 rounded p-2 text-center" />
              </div>
              <div>
                <small>Claim Income Processing Fee:</small>
                <input type="text" value={unclaimedRewardsFee} disabled className="w-full border border-gray-300 rounded p-2 text-center" />
              </div>
              {unclaimedRewards > 0 && (
                <button onClick={collectIncome} className="w-full bg-blue-600 text-white py-2 rounded">
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white mx-auto" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                    </svg>
                  ) : (
                    "Claim"
                  )}
                </button>
              )}
            </div>
            <small className="text-xs text-left mt-4">
              <b>Swapping Income Claiming Fee Requests:</b>
              <ul className="list-disc list-inside">
                <li>Up to 99 USDT: 2%</li>
                <li>From 100 to 999 USDT: 1%</li>
                <li>1000 USDT onward: 0.5%</li>
              </ul>
            </small>
          </div>
        </div>
      )}

      {/* Modal for Claim GBL Income */}
      {showCollectGblModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded shadow-lg z-50 p-6 w-11/12 md:w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Claim GBL Income</h3>
              <button onClick={handleCloseCollectGblModal} className="text-gray-600 text-2xl leading-none">
                &times;
              </button>
            </div>
            <div className="space-y-4 text-center">
              <div>
                <small>Available GBL Income:</small>
                <input type="text" value={unclaimedGblRewards} disabled className="w-full border border-gray-300 rounded p-2 text-center" />
              </div>
              {unclaimedGblRewards > 0 && (
                <button onClick={collectGblIncome} className="w-full bg-blue-600 text-white py-2 rounded">
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white mx-auto" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                    </svg>
                  ) : (
                    "Claim"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SwapV3;
