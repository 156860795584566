// src/components/AssetTable.js
import React from "react";
import settings from "../config/settings";

const AssetTable = () => {
  const activeTokens = settings.TOKENS.filter((token) => token.status === "active");

  return (
    <div className="max-w-screen-xl mx-auto">
        <h3 className="py-6 text-3xl font-bold text-center mt-2 text-secondary">
        Listed Assets
      </h3>
      <table className="min-w-full border border-gray-300 dark:border-gray-600">
        <thead>
          <tr className="bg-gray-100 dark:bg-gray-700">
            <th className="px-4 py-2 border border-gray-300 dark:border-gray-600">Symbol</th>
            <th className="px-4 py-2 border border-gray-300 dark:border-gray-600">Name</th>
            <th className="px-4 py-2 border border-gray-300 dark:border-gray-600">Price</th>
          </tr>
        </thead>
        <tbody>
          {activeTokens.map((token) => (
            <tr key={token.contractAddress} className="text-center">
              <td className="px-4 py-2 border border-gray-300 dark:border-gray-600">{token.symbol}</td>
              <td className="px-4 py-2 border border-gray-300 dark:border-gray-600">{token.name}</td>
              <td className="px-4 py-2 border border-gray-300 dark:border-gray-600">
                {token.stablePrice ? token.stablePrice : "TBA"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-6">
        <a 
          href="/dao-weighted" 
          className="px-6 py-2 bg-secondary text-white rounded hover:bg-secondary-dark transition-colors">
          Propose Additional Asset
        </a>
      </div>
    </div>
  );
};

export default AssetTable;
