import React, { useState, useEffect } from "react";
import { BrowserProvider, Contract, formatUnits, parseUnits, parseEther } from "ethers";
import StakingABI from "../abis/StakingABI.json";
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../config/staking";
import logoToken from "../logoToken.svg";
import { CustomToast, useCustomToast } from "./CustomToast";
import ConnectButton from "./ConnectButton";

const STAKE_DURATIONS = {
  FIRST: [30 * 86400, 50, 15], // 30 days, 1.0% per day
  SECOND: [45 * 86400, 100, 45], // 45 days, 1.5% per day
  THIRD: [90 * 86400, 150, 135], // 90 days, 2.0% per day
  FOURTH: [270 * 86400, 200, 540], // 270 days, 2.5% per day
  FIFTH: [540 * 86400, 250, 1350], // 540 days, 3.0% per day
  SIXTH: [810 * 86400, 300, 2430], // 810 days, 3.5% per day
};

const StakeWaveCoin = ({ provider, account, isConnected, networkId, switchNetwork }) => {
  const [tokenBalance, setTokenBalance] = useState("0");
  const [stakes, setStakes] = useState([]);
  const [staked, setStaked] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [amountToStake, setAmountToStake] = useState("");
  const [durationToStake, setDurationToStake] = useState("FIRST");
  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const { toast, showToast, hideToast } = useCustomToast();

  async function getBalance() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
    const TokenBalance = await TokenContract.balanceOf(account);
    const TokenDecimals = await TokenContract.decimals();
    setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

    const ContractBalance = await TokenContract.balanceOf(SETTINGS.stakingContract);
    setContractBalance(formatUnits(ContractBalance, TokenDecimals));
  }

  const getStakeDurationName = (firstValue) => {
    for (const [key, value] of Object.entries(STAKE_DURATIONS)) {
      if (value[0] === firstValue) {
        return key;
      }
    }
    return null;
  };

  useEffect(() => {
    if (!provider || !account) return;
    fetchStakes();
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, account, networkId]);

  const fetchStakes = async () => {
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
      const stakedAm = await contract.staked();
      setStaked(formatUnits(stakedAm, 18));
      const stakesDataUser = await contract.getUserStakes(account);
      const serialized = JSON.stringify(stakesDataUser, (key, value) =>
        typeof value === "bigint" ? value.toString() : value
      );
      const stakesData = JSON.parse(serialized);
      let stakesArr = [];
      for (let i = 0; i < stakesData.length; i++) {
        const stake = stakesData[i];
        const durationKey = getStakeDurationName(parseInt(stake["2"]));
        const rewardPercent = STAKE_DURATIONS[durationKey][1];
        const amount = formatUnits(stake["0"].toString(), 18);
        const rewardAmount =
          parseFloat(amount) + (parseFloat(amount) / 100) * rewardPercent;
        if (!stake["3"]) {
          stakesArr.push({
            index: i,
            amount: amount,
            rewardAmount: rewardAmount,
            timestamp: stake["1"],
            duration: stake["2"],
            claimed: stake["3"],
          });
        }
      }
      setStakes(stakesArr);
      console.log(stakesArr);
    } catch (error) {
      console.error("Failed to fetch stakes", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStake = async () => {
    if (!provider || !account) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      setLoading(true);

      const tokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const waveAllowance = await tokenContract.allowance(account, SETTINGS.nodeContract);
      const amountToStakeWei = parseUnits(amountToStake.toString(), 18);
      if (amountToStakeWei.gt(waveAllowance)) {
        setTxMessage("Approving " + SETTINGS.tokenSymbol + " transaction...");
        const approveTx = await tokenContract.approve(
          SETTINGS.stakingContract,
          amountToStakeWei
        );
        await approveTx.wait();
      }

      setTxMessage("Staking " + SETTINGS.tokenSymbol + " tokens...");
      const stakingContract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
      const stakeTx = await stakingContract.stake(
        amountToStakeWei,
        STAKE_DURATIONS[durationToStake][0],
        { value: parseEther("1") }
      );
      await stakeTx.wait();
      setTxMessage(SETTINGS.tokenSymbol + " staked successfully!");
      await fetchStakes();
      await getBalance();
      setAmountToStake("");
      showToast("Stake successful!", "success");
    } catch (error) {
      console.error("Staking failed", error);
      showToast("Staking failed. Please try again.", "danger");
      setTxMessage("Staking failed. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  const handleClaim = async (stakeIndex) => {
    if (!provider || !account) return;
    try {
      setLoading(true);
      setTxMessage("Claiming rewards...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
      await contract.claim(stakeIndex, { value: parseEther("1") });
      setTxMessage("Rewards successfully claimed!");
      await fetchStakes();
    } catch (error) {
      console.error("Claiming failed", error);
      showToast("Claiming failed. Please try again.", "danger");
      setTxMessage("Claiming failed. Please try again.");
    } finally {
      setLoading(false);
      showToast("Claim successful!", "success");
      setTimeout(() => setTxMessage(""), 5000);
      await fetchStakes();
    }
  };

  const getProgress = (timestamp, duration) => {
    const now = Math.floor(Date.now() / 1000);
    const timePassed = now - timestamp;
    const progress = (timePassed / duration) * 100;
    return Math.min(progress, 100);
  };

  const getTimeLeft = (timestamp, duration) => {
    const now = Math.floor(Date.now() / 1000);
    const timePassed = now - timestamp;
    const timeLeft = duration - timePassed;

    if (timeLeft <= 0) {
      return "Time is up";
    }

    const days = Math.floor(timeLeft / (24 * 3600));
    const hours = Math.floor((timeLeft % (24 * 3600)) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);

    return `${days} days, ${hours} hours, and ${minutes} minutes left`;
  };

  const isClaimable = (timestamp, duration) => {
    const now = Math.floor(Date.now() / 1000);
    return now - timestamp >= duration;
  };

  const calculateReward = (amount, duration) => {
    const rewardPercent = STAKE_DURATIONS[duration][1];
    return (parseFloat(amount) / 100) * rewardPercent;
  };

  const isStakeButtonDisabled = () => {
    if (isNaN(parseFloat(amountToStake)) || parseFloat(amountToStake) <= 0) {
      return true;
    }
    return false;
  };

  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
  };



  return (
    <div className="container mx-auto px-4 py-10">
      {loading && (
        <div className="flex flex-col items-center justify-center my-8">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4"></div>
          <p className="mt-4">{txMessage}</p>
        </div>
      )}
      <CustomToast show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} />

      <h2 className="text-3xl font-bold text-center text-secondary my-8">WAVE Stake</h2>
      <p className="text-center text-gray-600 mb-10">
        Boost your assets with WAVE staking options, designed for maximum flexibility and consistent rewards.
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Staking Form */}
        <div className="bg-white dark:bg-gray-800 shadow rounded p-6">
          <div className="mb-4 text-center">
            <h4 className="text-xl font-semibold">Open New Stake</h4>
          </div>
          <div className="mb-4 flex items-center justify-between">
            <span>Available staking rewards:</span>
            <div className="flex items-center">
              <img src={logoToken} alt={SETTINGS.tokenSymbol} className="w-5 mr-2" />
              <b>
                {new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(contractBalance - staked)}{" "}
                {SETTINGS.tokenSymbol}
              </b>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Enter amount:</label>
            <input
              type="number"
              value={amountToStake}
              onChange={(e) => setAmountToStake(e.target.value)}
              placeholder="Amount to stake"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
            />
          </div>

          <div className="mb-4 flex flex-col">
            <span className="text-sm mb-1">Your balance:</span>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <img src={logoToken} alt={SETTINGS.tokenSymbol} className="w-5" />
                <small>
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(tokenBalance)}{" "}
                  {SETTINGS.tokenSymbol}
                </small>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setAmountToStake(parseFloat(tokenBalance) / 4)}
                  className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                >
                  25%
                </button>
                <button
                  onClick={() => setAmountToStake(parseFloat(tokenBalance) / 2)}
                  className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                >
                  50%
                </button>
                <button
                  onClick={() => setAmountToStake(parseFloat(tokenBalance) - parseFloat(tokenBalance) / 4)}
                  className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                >
                  75%
                </button>
                <button
                  onClick={() => setAmountToStake(tokenBalance)}
                  className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                >
                  100%
                </button>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Select duration:</label>
            <select
              value={durationToStake}
              onChange={(e) => setDurationToStake(e.target.value)}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
            >
              <option value="FIRST">30 Days (15%)</option>
              <option value="SECOND">45 Days (45%)</option>
              <option value="THIRD">90 Days (135%)</option>
              <option value="FOURTH">270 Days (540%)</option>
              <option value="FIFTH">540 Days (1350%)</option>
              <option value="SIXTH">810 Days (2430%)</option>
            </select>
          </div>

          <div className="mb-4 border-t pt-4">
            <div className="flex items-center mb-2">
              <span className="font-semibold mr-2">Stake size:</span>
              <img src={logoToken} alt={SETTINGS.tokenSymbol} className="w-4 inline mr-1" />
              <b>
                {isNaN(parseFloat(amountToStake))
                  ? "0"
                  : new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(parseFloat(amountToStake) + calculateReward(amountToStake, durationToStake))}{" "}
                {SETTINGS.tokenSymbol}
              </b>
            </div>
            <div className="flex items-center mb-2 text-sm">
              <span>You are staking:</span>
              <b className="ml-2 font-normal">
                {isNaN(parseFloat(amountToStake))
                  ? "0"
                  : new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(amountToStake)}{" "}
                {SETTINGS.tokenSymbol}
              </b>
            </div>
            <div className="flex items-center text-sm">
              <span>Stake reward:</span>
              <b className="ml-2 font-normal">
                {isNaN(parseFloat(amountToStake))
                  ? "0"
                  : new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(calculateReward(amountToStake, durationToStake))}{" "}
                {SETTINGS.tokenSymbol}
              </b>
            </div>
          </div>

          <small className="text-xs text-gray-500 mb-4 block">
            By clicking the button "Stake {SETTINGS.tokenSymbol}", you agree with WaveSwaps{" "}
            <a
              href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Terms and Conditions.
            </a>
          </small>

          <button
            onClick={handleStake}
            disabled={isStakeButtonDisabled()}
            className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded"
          >
            Stake {SETTINGS.tokenSymbol}
          </button>
          {isStakeButtonDisabled() && !isNaN(parseFloat(amountToStake)) && (
            <div className="mt-3 p-2 bg-yellow-100 text-yellow-800 rounded">
              {parseFloat(tokenBalance) <= parseFloat(amountToStake)
                ? "Insufficient balance to stake this amount."
                : "Insufficient tokens in the reward pool to cover this stake."}
            </div>
          )}
        </div>

        {/* User Stakes */}
        <div className="bg-white dark:bg-gray-800 shadow rounded p-6">
          {stakes.length > 0 && (
            <h4 className="text-xl font-semibold mb-4">Your Stakes</h4>
          )}
          {stakes.map((stake, index) => (
            <div key={index} className="mb-6 border-b pb-4">
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-gray-500">Stake size:</p>
                  <h6 className="text-lg font-semibold flex items-center">
                    <img src={logoToken} alt={SETTINGS.tokenSymbol} className="w-6 mr-2" />
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(stake.rewardAmount)}{" "}
                    {SETTINGS.tokenSymbol}
                  </h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Staked:</p>
                  <h6 className="text-lg font-semibold flex items-center">
                    <img src={logoToken} alt={SETTINGS.tokenSymbol} className="w-6 mr-2" />
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(stake.amount)}{" "}
                    {SETTINGS.tokenSymbol}
                  </h6>
                </div>
              </div>
              <p className="text-sm text-gray-500 mb-1">Time left:</p>
              <div className="w-full bg-gray-200 rounded-full h-4 mb-2">
                <div
                  className="bg-green-500 h-4 rounded-full"
                  style={{ width: `${getProgress(stake.timestamp, stake.duration)}%`, transition: "width 2s ease-in-out" }}
                ></div>
              </div>
              <div className="text-sm font-semibold mb-4">
                {getProgress(stake.timestamp, stake.duration).toFixed(0)}%
              </div>
              <div className="text-sm mb-4">
                {getTimeLeft(stake.timestamp, stake.duration)}
              </div>
              <button
                onClick={() => handleClaim(stake.index)}
                disabled={!isClaimable(stake.timestamp, stake.duration) || stake.claimed}
                className={`w-full py-2 rounded ${
                  !isClaimable(stake.timestamp, stake.duration) || stake.claimed
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-green-500 hover:bg-green-600 text-white"
                }`}
              >
                {stake.claimed ? "Claimed" : "Claim"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StakeWaveCoin;
