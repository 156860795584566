// src/components/dao/StandardProposalList.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserProvider, Contract, parseEther } from 'ethers';
import { BiLike, BiDislike, BiFlag } from 'react-icons/bi';
import StandardVotingForm from './StandardVotingForm';
import SETTINGS from '../../config/daosettings';
import StandardVotingABI from '../../abis/StandardVoting.json';

const reportThreshold = 5;
const proposalsPerPage = 10;

const StandardProposalList = ({ provider, selectedAccount, isConnected }) => {
  const [proposals, setProposals] = useState([]);
  const [expandedProposalIndex, setExpandedProposalIndex] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [filter, setFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [proposalDuration, setProposalDuration] = useState(0);
  const [showVoterHistoryIndex, setShowVoterHistoryIndex] = useState(null);
  const [hasVotedMap, setHasVotedMap] = useState({});
  const [rewardClaimedMap, setRewardClaimedMap] = useState({});
  const [voterHistory, setVoterHistory] = useState([]);
  const [reportMap, setReportMap] = useState({});
  const [reportedWalletsMap, setReportedWalletsMap] = useState({});
  const [reportConfirmedMap, setReportConfirmedMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const [showReportersIndex, setShowReportersIndex] = useState(null);
  const [reportCounts, setReportCounts] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const fetchWalletAddress = useCallback(async () => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const address = await signer.getAddress();
      setWalletAddress(address);
    } catch (error) {
      console.error('Error fetching wallet address:', error);
    }
  }, [provider]);

  const fetchProposalsAndDuration = useCallback(async () => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const fetchedProposalDuration = await daoContract.proposalDuration();
      setProposalDuration(Number(fetchedProposalDuration) * 60 * 60 * 1000);
      
      // getProposals now returns 9 arrays; destructure accordingly:
      const proposalsResult = await daoContract.getProposals();
      const structuredProposals = proposalsResult.subjects.map((_, index) => ({
        index,
        subject: proposalsResult.subjects[index],
        category: proposalsResult.categories[index],
        description: proposalsResult.descriptions[index],
        refLink: proposalsResult.refLinks[index], // Now available!
        yesVotes: proposalsResult.yesVotes[index],
        noVotes: proposalsResult.noVotes[index],
        proposer: proposalsResult.proposers[index],
        isActive: proposalsResult.isActive[index],
        creationTime: Number(proposalsResult.createdAt[index].toString()) * 1000,
      }));
      
      setProposals(structuredProposals);
    } catch (error) {
      console.error('Error fetching proposals:', error);
    }
  }, [provider]);
  

  const handleVoteCallback = async (proposalIndex) => {
    console.log(`Vote registered for proposal index: ${proposalIndex}`);
    await fetchVoterHistory(proposalIndex);
    await fetchVotingStatus();
    fetchProposalsAndDuration();
  };

  const fetchVotingStatus = useCallback(async () => {
    if (!provider || proposals.length === 0) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer.provider);
      const updatedHasVotedMap = {};
      for (const proposal of proposals) {
        const voterHistory = await daoContract.getVoterHistory(proposal.index);
        const hasVoted = voterHistory[0].includes(userAddress);
        updatedHasVotedMap[proposal.index] = hasVoted;
      }
      setHasVotedMap(updatedHasVotedMap);
    } catch (error) {
      console.error('Error fetching voting status:', error);
    }
  }, [proposals, provider]);

  useEffect(() => {
    if (provider) {
      fetchProposalsAndDuration();
      fetchWalletAddress();
      fetchVotingStatus();
    }
  }, [provider, fetchProposalsAndDuration, fetchWalletAddress, fetchVotingStatus]);

  const sortedProposals = proposals.sort((a, b) => b.creationTime - a.creationTime);
  const filteredProposals = sortedProposals.filter((proposal) =>
    (filter === 'all' ? true : filter === 'open' ? proposal.isActive : !proposal.isActive) &&
    (categoryFilter === 'all' ? true : proposal.category === categoryFilter)
  );
  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = filteredProposals.slice(indexOfFirstProposal, indexOfLastProposal);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProposals.length / proposalsPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <div className="flex justify-center mt-4 space-x-2">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-3 py-1 rounded ${
              currentPage === number ? 'bg-blue-600 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200'
            }`}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  const handleVoteSubmit = (proposalIndex) => {
    const proposal = proposals.find(p => p.index === proposalIndex);
    if (isProposalExpired(proposal.creationTime)) {
      alert('This proposal has already expired. Voting is not allowed.');
      return;
    }
  };

  const isProposalExpired = (creationTime) => {
    const now = Date.now();
    const expirationTime = creationTime + proposalDuration;
    return now >= expirationTime;
  };

  const toggleAccordion = (index) => {
    setExpandedProposalIndex(expandedProposalIndex === index ? null : index);
  };

  const fetchVoterHistory = async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const history = await daoContract.getVoterHistory(proposalIndex);
      const voters = history[0];
      const votes = history[1];
      const voterHistoryData = voters.map((voter, index) => ({
        voterAddress: voter,
        vote: votes[index] ? 'Yes' : 'No',
      }));
      setVoterHistory(voterHistoryData);
    } catch (error) {
      console.error('Error fetching voter history:', error);
    }
  };

  const toggleVoterHistory = async (proposalIndex) => {
    if (showVoterHistoryIndex === proposalIndex) {
      setShowVoterHistoryIndex(null);
    } else {
      await fetchVoterHistory(proposalIndex);
      setShowVoterHistoryIndex(proposalIndex);
    }
  };

  const calculateTimeRemaining = (creationTime, index) => {
    const now = Date.now();
    const expirationTime = creationTime + proposalDuration;
    const timeRemaining = expirationTime - now;
    if (timeRemaining <= 0) {
      setProposals((prevProposals) =>
        prevProposals.map((proposal, i) =>
          i === index ? { ...proposal, isActive: false } : proposal
        )
      );
      return `0 Hours 0 Min`;
    }
    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const hours = Math.floor(timeRemaining / 1000 / 60 / 60);
    return `${hours} h ${minutes} min`;
  };

  const handleClaimRewards = async (proposalIndex, isSubmitter = false) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const proposal = await daoContract.getProposal(proposalIndex);
      if (proposal.isActive) {
        const tx = await daoContract.finalizeProposal(proposalIndex);
        await tx.wait();
      }
      const claimType = isSubmitter ? "Submitter" : "Voter";
      const claimTx = await daoContract.claimReward(proposalIndex, {
        value: parseEther("0.01"),
      });
      await claimTx.wait();
      persistClaimedRewards(proposalIndex, isSubmitter);
      setRewardClaimedMap((prevMap) => ({
        ...prevMap,
        [proposalIndex]: true,
      }));
    } catch (error) {
      console.error(`Error claiming ${isSubmitter ? "submitter" : "voter"} rewards for proposal ${proposalIndex}:`, error);
    }
  };

  const renderClaimButtons = (proposal) => {
    const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
    if (!proposal.isActive && !rewardClaimedMap[proposal.index] && !claimedRewards[proposal.index]?.submitter && !claimedRewards[proposal.index]?.voter) {
      return (
        <div className="mt-4 space-y-2">
          {walletAddress === proposal.proposer && !claimedRewards[proposal.index]?.submitter && (
            <button
              className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded"
              onClick={() => handleClaimSubmitterRewards(proposal.index)}
            >
              Claim Rewards as Submitter
            </button>
          )}
          {hasVotedMap[proposal.index] && !claimedRewards[proposal.index]?.voter && (
            <button
              className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white rounded"
              onClick={() => handleClaimVoterRewards(proposal.index)}
            >
              Claim Rewards as Voter
            </button>
          )}
          {walletAddress === proposal.proposer && hasVotedMap[proposal.index] && !claimedRewards[proposal.index]?.submitter && !claimedRewards[proposal.index]?.voter && (
            <p className="text-sm text-gray-600 dark:text-gray-300">You are both the proposer and a voter. Claim both rewards.</p>
          )}
        </div>
      );
    }
    return null;
  };

  const persistClaimedRewards = (proposalIndex, isSubmitter = false) => {
    const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
    claimedRewards[proposalIndex] = { submitter: isSubmitter, voter: !isSubmitter };
    localStorage.setItem('claimedRewards', JSON.stringify(claimedRewards));
  };

  const handleClaimSubmitterRewards = async (proposalIndex) => {
    await handleClaimRewards(proposalIndex, true);
  };

  const handleClaimVoterRewards = async (proposalIndex) => {
    await handleClaimRewards(proposalIndex, false);
  };

  useEffect(() => {
    const loadClaimedRewardsFromStorage = () => {
      const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
      setRewardClaimedMap(claimedRewards);
    };
    loadClaimedRewardsFromStorage();
  }, []);

  const finalizeProposal = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const tx = await daoContract.finalizeProposal(proposalIndex);
      await tx.wait();
    } catch (error) {
      console.error(`Error finalizing proposal ${proposalIndex}:`, error);
    }
  }, [provider]);

  const finalizeExpiredProposals = useCallback(async () => {
    const now = Date.now();
    for (const [index, proposal] of proposals.entries()) {
      const expirationTime = proposal.createdAt + proposalDuration;
      if (now > expirationTime && proposal.isActive) {
        try {
          await finalizeProposal(proposal.index);
          setProposals((prevProposals) =>
            prevProposals.map((p, i) => (i === index ? { ...p, isActive: false } : p))
          );
        } catch (error) {
          console.error(`Error finalizing proposal at index ${index}:`, error);
        }
      }
    }
  }, [proposals, proposalDuration, finalizeProposal]);

  useEffect(() => {
    finalizeExpiredProposals();
  }, [finalizeExpiredProposals]);

  const handleReportProposal = async (proposalIndex) => {
    if (!reportConfirmedMap[proposalIndex]) return;
    try {
      setLoadingMap((prevState) => ({ ...prevState, [proposalIndex]: true }));
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      if (reportMap[proposalIndex]) throw new Error("You have already reported this proposal");
      await daoContract.reportProposal(proposalIndex);
      setReportMap((prevState) => ({ ...prevState, [proposalIndex]: true }));
      const reporterWallet = await signer.getAddress();
      setReportedWalletsMap((prevState) => ({
        ...prevState,
        [proposalIndex]: [...(prevState[proposalIndex] || []), reporterWallet],
      }));
      setReportCounts((prevCounts) => ({
        ...prevCounts,
        [proposalIndex]: (prevCounts[proposalIndex] || 0) + 1,
      }));
    } catch (error) {
      console.error(`Error reporting the proposal at index ${proposalIndex}:`, error.message || error);
    } finally {
      setLoadingMap((prevState) => ({ ...prevState, [proposalIndex]: false }));
    }
  };

  const hasAlreadyReported = (proposalIndex) => {
    return reportedWalletsMap[proposalIndex]?.includes(walletAddress);
  };

  const handleCheckboxChange = (proposalIndex) => {
    setReportConfirmedMap((prevState) => ({
      ...prevState,
      [proposalIndex]: !prevState[proposalIndex],
    }));
  };

  const fetchReportedWallets = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const reporters = await daoContract.getReporters(proposalIndex);
      setReportedWalletsMap((prevState) => ({ ...prevState, [proposalIndex]: reporters }));
    } catch (error) {
      console.error('Error fetching reported wallets:', error);
    }
  }, [provider]);

  useEffect(() => {
    proposals.forEach((proposal) => {
      fetchReportedWallets(proposal.index);
    });
  }, [fetchReportedWallets, proposals]);

  const toggleReportersList = async (proposalIndex) => {
    if (showReportersIndex === proposalIndex) {
      setShowReportersIndex(null);
    } else {
      await fetchReportedWallets(proposalIndex);
      setShowReportersIndex(proposalIndex);
    }
  };

  const fetchReportCount = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const reportCount = await daoContract.getReportCount(proposalIndex);
      setReportCounts((prevState) => ({ ...prevState, [proposalIndex]: reportCount }));
    } catch (error) {
      console.error('Error fetching report count:', error);
    }
  }, [provider]);

  const getFlagTextAndColor = (reportCount, threshold) => {
    const reportCountNumber = Number(reportCount);
    const intensity = Math.min(255, (reportCountNumber / threshold) * 255);
    const color = `rgb(255, ${255 - intensity}, ${255 - intensity})`;
    const text = ` (${reportCountNumber}/${threshold})`;
    return { color, text };
  };

  useEffect(() => {
    proposals.forEach((proposal) => {
      fetchReportCount(proposal.index);
      fetchReportedWallets(proposal.index);
    });
  }, [fetchReportCount, fetchReportedWallets, proposals]);

  const renderReportSection = (proposal) => {
    return !hasAlreadyReported(proposal.index) ? (
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Report proposal offensive or harmful content:
        </label>
        <div className="flex items-start mb-4 space-x-2">
          <input
            type="checkbox"
            checked={!!reportConfirmedMap[proposal.index]}
            onChange={() => handleCheckboxChange(proposal.index)}
            className="w-4 h-4 mt-[2px]"
          />
          <span className="text-sm text-gray-600 dark:text-gray-400">
            I confirm that my report is valid and may result in delisting and/or penalties as per our&nbsp;
            <a
              href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 underline"
            >
              Terms and Conditions
            </a>.
          </span>
        </div>
        <button
          onClick={() => handleReportProposal(proposal.index)}
          disabled={!reportConfirmedMap[proposal.index] || loadingMap[proposal.index]}
          className={`w-full py-2 px-4 rounded text-white transition-colors ${
            !reportConfirmedMap[proposal.index] || loadingMap[proposal.index]
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-red-600 hover:bg-red-700"
          }`}
        >
          {loadingMap[proposal.index] ? 'Reporting...' : 'Report Proposal'}
        </button>
      </div>
    ) : (
      <div className="mt-4">
        <p className="flex items-center text-sm text-gray-700 dark:text-gray-300">
          <BiFlag
            className="mr-1"
            style={{ color: getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).color }}
            onClick={() => toggleReportersList(proposal.index)}
          />
          {getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).text}
        </p>
        {showReportersIndex === proposal.index && (
          <div className="mt-2 p-2 border border-gray-300 dark:border-gray-600 rounded">
            <small className="text-xs text-gray-600 dark:text-gray-400">Reported by:</small>
            <ul className="mt-1 text-sm text-gray-700 dark:text-gray-300">
              {reportedWalletsMap[proposal.index]?.length > 0 ? (
                reportedWalletsMap[proposal.index].map((wallet, idx) => (
                  <li key={idx}>{wallet}</li>
                ))
              ) : (
                <p>No wallets have reported this proposal yet.</p>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <h2 className="text-3xl font-bold mb-6">Standard Proposals List</h2>
      <div className="mb-4 flex flex-col md:flex-row md:justify-between md:items-center">
        <div className="mb-2 md:mb-0">
          <label className="mr-2 text-gray-700 dark:text-gray-300">Filter:</label>
          <select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            className="px-2 py-1 border border-gray-300 dark:border-gray-600 rounded focus:outline-none dark:text-gray-300"
          >
            <option value="all">All Categories</option>
            {SETTINGS.proposalCategories.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div className="space-x-2">
          <button onClick={() => setFilter('all')} className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded">
            All
          </button>
          <button onClick={() => setFilter('finished')} className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded">
            Finished
          </button>
        </div>
      </div>
      <div className="space-y-4">
        {currentProposals.length > 0 ? (
          currentProposals.map((proposal, index) => (
            <div key={index} className="border border-gray-300 dark:border-gray-600 rounded shadow-sm">
              <div className="p-4 bg-gray-100 dark:bg-gray-800 flex flex-wrap items-center justify-between cursor-pointer" onClick={() => toggleAccordion(index)}>
                <h3 className="text-xl font-semibold">{proposal.subject}</h3>
                <div className="flex space-x-4 items-center mt-2 md:mt-0">
                  <p className="hidden md:block">{proposal.category}</p>
                  <p className="hidden md:block">{proposal.isActive ? 'Open' : 'Finished'}</p>
                  <p className="flex items-center">
                    <BiLike className="mr-1 text-green-600" /> {proposal.yesVotes.toString()}
                  </p>
                  <p className="flex items-center">
                    <BiDislike className="mr-1 text-red-600" /> {proposal.noVotes.toString()}
                  </p>
                  {proposal.isActive && <p className="text-sm text-gray-700 dark:text-gray-300">{calculateTimeRemaining(proposal.creationTime, index)}</p>}
                </div>
              </div>
              {expandedProposalIndex === index && (
                <>
                  <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <h2 className="text-2xl font-bold mb-2">Proposal Content</h2>
                      <p className="text-gray-700 dark:text-gray-300 mb-2">
                        {proposal.description}
                      </p>
                    </div>
                    <div className="space-y-4">
                      {proposal.isActive && !hasVotedMap[proposal.index] ? (
                        <StandardVotingForm
                          provider={provider}
                          contractAddress={SETTINGS.standardDaoContract}
                          tokenAddress={SETTINGS.daoToken}
                          proposal={proposal}
                          onVote={() => {
                            handleVoteSubmit(proposal.index);
                            if (!isProposalExpired(proposal.creationTime)) {
                              handleVoteCallback(proposal.index);
                            }
                          }}
                        />
                      ) : proposal.isActive ? (
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          You have already voted on this proposal.
                        </p>
                      ) : (
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          This proposal has ended and is no longer open for voting.
                        </p>
                      )}
                      {!proposal.isActive && renderClaimButtons(proposal)}
                      <button
                        onClick={() => toggleVoterHistory(proposal.index)}
                        className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded"
                      >
                        {showVoterHistoryIndex === proposal.index ? 'Hide Voting History' : 'Show Voting History'}
                      </button>
                      {showVoterHistoryIndex === proposal.index && (
                        <div className="mt-2 p-2 border border-gray-300 dark:border-gray-600 rounded">
                          <small className="text-sm text-gray-600 dark:text-gray-400">
                            Voting History:
                          </small>
                          {voterHistory.length > 0 ? (
                            <ul className="mt-1 text-sm text-gray-700 dark:text-gray-300">
                              {voterHistory.map((voter, idx) => (
                                <li key={idx}>
                                  {voter.voterAddress.slice(0, 9)}...{voter.voterAddress.slice(-9)}: <b>{voter.vote}</b>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                              No voter history available
                            </p>
                          )}
                        </div>
                      )}
                      {renderReportSection(proposal)}
                    </div>
                  </div>
                  {/* Always display the reference material button */}
                  <div className="px-4 pb-4">
                    <button
                      onClick={() =>
                        window.open(proposal.refLink, '_blank', 'noopener,noreferrer')
                      }
                      className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                    >
                      View Reference Material
                    </button>
                  </div>
                </>
              )}


            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 dark:text-gray-300">No proposals available</p>
        )}
      </div>
      {filteredProposals.length > proposalsPerPage && renderPagination()}
    </div>
  );
};

export default StandardProposalList;
