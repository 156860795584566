// src/components/modals/BuyNodeModal.js
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { addTransaction, prePurchaseNode } from '../../services/api';
import { ethers, BrowserProvider } from 'ethers';
import BFSNodeABI from '../../abis/BFSNode.json';
import settings, { NODE_ASSET_REQUIREMENTS } from '../../config/settings';

// Reown / Web3
import { getAppKit } from '@reown/appkit/react';
import { useAppKitProvider, useAppKitAccount } from '@reown/appkit/react';

function BuyNodeModal({ node, onClose, loadOwnedNodes }) {
  const { user, userBalances, refreshUserBalances } = useContext(UserContext);

  // Node info
  const typeId = node?.id || 0;
  const nodeTitle = node?.title || 'Unknown Node';
  const nodeImage = node?.imgUrl || 'https://via.placeholder.com/400x200';
  const nodePrice = node?.costMatic || 1;
  const nodeDaily = node?.dailyReward || 0.5;
  const nodeLifespan = node?.lifespan || 30;

  // POL cost (as string)
  const [maticCost, setMaticCost] = useState(String(nodePrice));
  const [processingFee] = useState('0');

  // Retrieve fee for native purchases from the node (if defined) or fallback to global
  const nodePolygonFee = node?.polygonFee ? parseFloat(node.polygonFee) : parseFloat(settings.POLYGON_FEE);

  // Off-chain requirement
  const requirementMap = NODE_ASSET_REQUIREMENTS[typeId] || {};
  const [selectedAsset, setSelectedAsset] = useState('');
  const [offChainRequired, setOffChainRequired] = useState('0');

  // Flow state
  const [step, setStep] = useState(1);
  const [isProcessingOnChain, setIsProcessingOnChain] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [purchasedNodeId, setPurchasedNodeId] = useState(null);

  // Reown / Wallet
  const { isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');

  useEffect(() => {
    const keys = Object.keys(requirementMap);
    if (keys.length > 0) {
      const firstKey = keys[0];
      setSelectedAsset(firstKey);
      setOffChainRequired(String(requirementMap[firstKey]));
    }
  }, [requirementMap]);

  function handleAssetChange(e) {
    const newKey = e.target.value;
    setSelectedAsset(newKey);
    setOffChainRequired(String(requirementMap[newKey] || '0'));
  }

  async function ensurePolygonNetwork() {
    const polygonChainIdHex = settings.CHAIN.chainId;
    if (!isConnected) {
      const appKit = getAppKit();
      if (appKit) appKit.open();
      return false;
    }
    if (!walletProvider) return true;
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      if (network.chainId !== parseInt(polygonChainIdHex, 16)) {
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: polygonChainIdHex }]
        });
      }
      return true;
    } catch (err) {
      console.error("Failed chain switch:", err);
      alert("Please switch to Polygon in your wallet.");
      return false;
    }
  }

  async function handleConfirmPurchase() {
    try {
      if (!user?.token) {
        alert('You must be logged in.');
        return;
      }
      if (!selectedAsset || !offChainRequired) {
        alert('No off-chain asset requirement found.');
        return;
      }
      const userBal = parseFloat(userBalances[selectedAsset] || '0');
      const requiredBal = parseFloat(offChainRequired || '0');
      if (userBal < requiredBal) {
        alert(`Insufficient ${selectedAsset} balance. Need: ${requiredBal}, Have: ${userBal.toFixed(2)}`);
        return;
      }

      setIsProcessingOnChain(true);
      const ok = await ensurePolygonNetwork();
      if (!ok) {
        setIsProcessingOnChain(false);
        return;
      }
      const ethersProvider = new BrowserProvider(walletProvider);
      await ethersProvider.send('eth_requestAccounts', []);
      const signer = await ethersProvider.getSigner();
      const contract = new ethers.Contract(node.contractAddress, BFSNodeABI, signer);

      // Total cost = node price + processing fee + node-specific polygon fee.
      const totalMatic = parseFloat(maticCost) + parseFloat(processingFee) + nodePolygonFee;
      if (isNaN(totalMatic) || totalMatic <= 0) {
        throw new Error("Invalid POL cost");
      }
      const txValue = ethers.parseEther(totalMatic.toString());
      const tx = await contract.purchaseNode(typeId, { value: txValue });
      const receipt = await tx.wait();
      setTxHash(tx.hash);

      let mintedNodeId = null;
      for (const log of receipt.logs) {
        try {
          const parsedLog = contract.interface.parseLog(log);
          if (parsedLog.name === "NodePurchased") {
            mintedNodeId = parsedLog.args.nodeId.toString();
            break;
          }
        } catch {}
      }
      setPurchasedNodeId(mintedNodeId);

      try {
        // Deduct the off-chain requirement
        await prePurchaseNode({
          nodeId: typeId,
          assetKey: selectedAsset,
          offChainAmount: requiredBal
        });
        await refreshUserBalances();

        // Log the transaction
        await addTransaction({
          user_id: user.email,
          user_email: user.email,
          tx_hash: tx.hash,
          tx_type: 'purchase_node',
          asset: settings.CHAIN.nativeCurrency.symbol,
          amount: totalMatic,
          node_id: mintedNodeId,
          note: `Node purchase (type #${typeId})`
        });
      } catch (offChainBurnErr) {
        console.error('Off-chain burn failed AFTER on-chain purchase:', offChainBurnErr);
        alert('Warning: On-chain purchase succeeded, but off-chain burn failed. Please contact support.');
      }

      setStep(2);
      if (typeof loadOwnedNodes === 'function') {
        loadOwnedNodes();
      }
    } catch (err) {
      console.error("On-chain purchase error:", err);
      alert('On-chain purchase canceled or failed. No off-chain tokens burned.');
    } finally {
      setIsProcessingOnChain(false);
    }
  }

  function shortenTxHash(hash) {
    if (!hash) return '';
    return hash.slice(0, 10) + '...' + hash.slice(-10);
  }

  function handleModalClose() {
    onClose();
  }

  return (
    <>
      {isProcessingOnChain && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="spinner-border text-white" role="status" />
            <p className="mt-4 text-white">Purchasing Node on-chain...</p>
          </div>
        </div>
      )}

      {/* Modal container is now scrollable vertically on smaller screens */}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 bg-opacity-70">
        <div className="dark:bg-gray-800 bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg shadow-lg w-full max-w-md mx-4 relative max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
            <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              Buy Node: {nodeTitle}
            </h5>
            <button
              className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              onClick={handleModalClose}
              disabled={isProcessingOnChain}
            >
              &times;
            </button>
          </div>
          <div className="p-4">
            {step === 1 && (
              <>
                <div className="text-center mb-4">
                  <img
                    src={nodeImage}
                    alt={nodeTitle}
                    className="w-full max-w-sm rounded-lg mx-auto"
                  />
                  <p className="mt-2 text-sm dark:text-gray-200">
                    Lifespan: {nodeLifespan} days | Daily: {nodeDaily} {settings.LIQUIDITY_TOKEN}
                  </p>
                </div>

                <h6 className="text-md font-medium mb-2 dark:text-gray-200">Off-chain Requirement</h6>
                {Object.keys(requirementMap).length === 0 ? (
                  <p className="text-sm dark:text-gray-200">
                    No off-chain tokens required for this node.
                  </p>
                ) : (
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-1 dark:text-gray-200">
                      Asset to Burn:
                    </label>
                    <select
                      className="w-full border border-gray-300 rounded p-2 dark:bg-gray-700 dark:text-gray-100"
                      value={selectedAsset}
                      onChange={handleAssetChange}
                    >
                      {Object.entries(requirementMap).map(([key, amt]) => (
                        <option key={key} value={key}>
                          {key.toUpperCase()} (Need {Number(amt).toFixed(2)}, Have {Number(userBalances[key] || 0).toFixed(2)})
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <h6 className="text-md font-medium mb-2 dark:text-gray-200">On-chain Payment</h6>
                <p className="mb-4 dark:text-gray-200">
                  Price <strong>{maticCost} POL</strong>
                  {parseFloat(processingFee) > 0 && ` + ${processingFee} POL fee`}
                  {nodePolygonFee > 0 && ` + ${nodePolygonFee} POLYGON fee`}
                </p>

                <button
                  onClick={handleConfirmPurchase}
                  className="w-full bg-baseviolet text-white py-2 rounded hover:bg-hoverviolet transition disabled:opacity-60"
                  disabled={isProcessingOnChain}
                >
                  Confirm Purchase
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <h4 className="text-center text-lg font-semibold mb-4 dark:text-gray-200">
                  Purchase Complete!
                </h4>
                {txHash && (
                  <p className="text-center text-sm dark:text-gray-200">
                    On-chain Transaction Hash:
                    <br />
                    <a
                      href={`${settings.CHAIN.blockExplorerUrls[0]}/tx/${txHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {shortenTxHash(txHash)}
                    </a>
                  </p>
                )}
                {purchasedNodeId && (
                  <p className="text-center text-sm dark:text-gray-200">
                    Minted Node ID: {purchasedNodeId}
                  </p>
                )}
                <button
                  onClick={handleModalClose}
                  className="mt-4 w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition"
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BuyNodeModal;
