// src/components/dao/StandardProposalForm.js
import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseUnits } from 'ethers';
import { formatEther } from 'ethers';
import { ERC20_ABI } from "../../abis/erc20";
import governanceImg from '../../governance.png';
import StandardVotingABI from '../../abis/StandardVoting.json';
import SETTINGS from '../../config/daosettings';

const StandardProposalForm = ({ provider, selectedAccount, isConnected, setActiveTab }) => {
  const [subject, setSubject] = useState('');
  const [category, setCategory] = useState(SETTINGS.proposalCategories[0]);
  const [description, setDescription] = useState('');
  const [refLink, setRefLink] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);
  const [proposalFee, setProposalFee] = useState(0);
  const [fixedProposalTokenAmount, setFixedProposalTokenAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchContractDetails = async () => {
      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();

        const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
        const balance = await tokenContract.balanceOf(await signer.getAddress());
        setTokenBalance(balance.toString());

        const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
        const fetchedProposalFee = await daoContract.proposalFee();
        const fetchedFixedProposalTokenAmount = await daoContract.fixedProposalTokenAmount();

        setProposalFee(fetchedProposalFee.toString());
        setFixedProposalTokenAmount(fetchedFixedProposalTokenAmount.toString());
      } catch (error) {
        console.error('Error fetching contract details:', error);
      }
    };

    fetchContractDetails();
  }, [provider]);

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
      const approvalTx = await tokenContract.approve(SETTINGS.standardDaoContract, fixedProposalTokenAmount);
      await approvalTx.wait();
      setIsApproved(true);
    } catch (error) {
      console.error('Error approving tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!refLink) {
      alert("Please provide a Reference Material Link.");
      setIsLoading(false);
      return;
    }
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
      const feeInWei = parseUnits(proposalFee, 'wei');
      const tx = await daoContract.postProposal(subject, category, description, refLink, { value: feeInWei });
      await tx.wait();
      setSubject('');
      setCategory(SETTINGS.proposalCategories[0]);
      setDescription('');
      setRefLink('');
      setActiveTab("list");
    } catch (error) {
      console.error('Error posting proposal:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6 p-6 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-800">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">New Standard Proposal</h2>
      <p className="text-gray-700 dark:text-gray-300 mb-2">
        Your balance:{" "}
        <img src={governanceImg} alt="DAOToken" className="inline-block w-5 h-5 mr-2 align-text-bottom" />
        <span className="font-semibold">{tokenBalance} DAO</span>
      </p>
      <p className="text-gray-700 dark:text-gray-300 mb-2">
        Proposal Fee: <span className="font-semibold">{formatEther(proposalFee)} POL</span>
      </p>
      <p className="text-gray-700 dark:text-gray-300 mb-4">
        <span>Proposal Fee:</span>{" "}
        <img src={governanceImg} alt="DAOToken" className="inline-block w-5 h-5 mr-2 align-text-bottom" />
        <span className="font-semibold">{fixedProposalTokenAmount} DAO</span>
        <br />
        <small className="text-xs text-gray-500 dark:text-gray-400">
          *This fee is returned upon proposal completion, along with a 20% bonus in DAOTokens.
        </small>
      </p>
      
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Proposal Title:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value.slice(0, 50))}
            placeholder="Write a clear proposal title (max 50 characters)."
            maxLength="50"
            required
            className="mt-1 w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Category:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            className="mt-1 w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
          >
            {SETTINGS.proposalCategories.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Proposal Content:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Briefly describe your proposal here (max 1000 characters)."
            maxLength="1000"
            required
            className="mt-1 w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
          />
        </div>
        <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Reference Material Link:
        </label>
        <input
          type="url"
          value={refLink}
          onChange={(e) => setRefLink(e.target.value)}
          placeholder="https://..."
          required
          className="mt-1 w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
        />
      </div>

        {!isApproved ? (
          <button
            type="button"
            onClick={handleApprove}
            disabled={isLoading}
            className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded shadow transition-colors"
          >
            {isLoading ? 'Approving... please wait' : 'Approve Proposal'}
          </button>
        ) : (
          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded shadow transition-colors"
          >
            {isLoading ? 'Submitting... please wait' : 'Submit Proposal'}
          </button>
        )}
      </form>
    </div>
  );
};

export default StandardProposalForm;
