import React, { useEffect } from 'react';

// An array of feature navigation items with their IDs and labels
const featuresNav = [
  { id: 'feature-tvm', label: 'Tokens' },
  { id: 'feature-abstraction', label: 'Account Abstraction' },
  { id: 'feature-messaging', label: 'GetOnPonzi Race' },
  { id: 'feature-workchains', label: 'Workchains' },
  { id: 'feature-tsol', label: 'Extensions' },
  { id: 'feature-locklift', label: 'Network' },
  { id: 'feature-docs', label: 'Documentation' },
];

const SectorSection = () => {
  useEffect(() => {
    // Set up click listeners for the sidebar nav items (Desktop only)
    const navItems = document.querySelectorAll('.feature-nav li');
    navItems.forEach(li => {
      li.addEventListener('click', () => {
        navItems.forEach(x => x.classList.remove('active'));
        li.classList.add('active');
        // Hide all feature panels
        const panels = document.querySelectorAll('.feature-panel');
        panels.forEach(panel => {
          panel.classList.remove('block');
          panel.classList.add('hidden');
        });
        // Show the selected panel
        const featureId = li.getAttribute('data-feature');
        const panelEl = document.getElementById(featureId);
        if (panelEl) {
          panelEl.classList.remove('hidden');
          panelEl.classList.add('block');
        }
      });
    });
  }, []);

  return (
    <section className="py-12 px-4 dark:bg-bggrey">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-4 text-secondary">
          Blockchain for Developers
        </h2>
        <p className="text-center mb-10 text-gray-600">
          The Geton Blockchain has plenty of unique features that differentiate it from other blockchains.
        </p>
        {/* Desktop view */}
        <div className="hidden md:flex">
          {/* Sidebar Navigation */}
          <div className="w-1/4 pr-4 border-r border-gray-300">
            <ul className="feature-nav space-y-2">
              {featuresNav.map(item => (
                <li
                  key={item.id}
                  data-feature={item.id}
                  className="cursor-pointer p-2 rounded hover:bg-gray-200 active:bg-gray-300"
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
          {/* Feature Content Panels */}
          <div className="w-3/4 pl-4">
            <div id="feature-tvm" className="feature-panel block">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Tokens</h3>
              <p className="text-gray-600 mb-2">
                Geton Blockchain is built on the Threaded Virtual Machine (TVM). TVM provides a highly efficient and scalable way to handle account interactions via the Actor model. It isolates and parallelizes actors, allowing for efficient handling of complex distributed systems.
              </p>
              <p className="text-gray-600 mb-2">
                This makes the dynamic sharding protocol group accounts into shards, improving efficiency and enabling asynchronous contract execution.
              </p>
              <a href="/docs#tvm" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-abstraction" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Account Abstraction</h3>
              <p className="text-gray-600 mb-2">
                Simplify interaction with contracts by decoupling accounts from strict address usage. Users can design advanced workflows, reduce friction, and enjoy greater flexibility.
              </p>
              <a href="/docs#account-abstraction" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-messaging" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">GetOnPonzi Race</h3>
              <p className="text-gray-600 mb-2">
                By poking fun at the infamous "binary MLM schemes" and leveraging meme-fueled chaos, we present a comedic race where each token competes for degen glory. AI-driven characters cheer on your picks as the line between joke and reality blurs.
              </p>
              <a href="/docs#external-messaging" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-workchains" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Workchains</h3>
              <p className="text-gray-600 mb-2">
                Leverage multiple parallel workchains—each with its own tokenomics and rules—providing massive flexibility to scale dApps and solutions.
              </p>
              <a href="/docs#arbitrary-workchains" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-tsol" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Extensions</h3>
              <p className="text-gray-600 mb-2">
                T-Sol extends Solidity for Geton’s TVM, supporting extra features that simplify asynchronous execution and advanced concurrency.
              </p>
              <a href="/docs#t-sol" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-locklift" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Network</h3>
              <p className="text-gray-600 mb-2">
                Locklift is the official CLI for compiling, deploying, and testing smart contracts on Geton Blockchain. It streamlines local development and accelerates your workflow.
              </p>
              <a href="/docs#locklift" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div id="feature-docs" className="feature-panel hidden">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Documentation</h3>
              <p className="text-gray-600 mb-2">
                Our comprehensive documentation covers everything from basic wallet interaction to advanced contract patterns, helping you build robust dApps.
              </p>
              <a href="/docs" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
          </div>
        </div>
        {/* Mobile Horizontal Scrollable Panels */}
        <div className="block md:hidden mt-6">
          <div className="overflow-x-auto flex space-x-4 pb-4 scrollbar-hide">
            {/* Each mobile panel is given a minimum width so that they can be scrolled horizontally */}
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Tokens</h3>
              <p className="text-gray-600 mb-2">
                Geton Blockchain is built on the TVM for efficient account interactions.
              </p>
              <a href="/docs#tvm" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Account Abstraction</h3>
              <p className="text-gray-600 mb-2">
                Simplify contract interactions by decoupling accounts from strict addresses.
              </p>
              <a href="/docs#account-abstraction" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">GetOnPonzi Race</h3>
              <p className="text-gray-600 mb-2">
                A comedic race where each token competes for degen glory.
              </p>
              <a href="/docs#external-messaging" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Workchains</h3>
              <p className="text-gray-600 mb-2">
                Scale dApps with multiple parallel workchains and unique tokenomics.
              </p>
              <a href="/docs#arbitrary-workchains" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Extensions</h3>
              <p className="text-gray-600 mb-2">
                T-Sol extends Solidity for asynchronous execution and advanced concurrency.
              </p>
              <a href="/docs#t-sol" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Network</h3>
              <p className="text-gray-600 mb-2">
                Use Locklift, the CLI for deploying and testing smart contracts.
              </p>
              <a href="/docs#locklift" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
            <div className="flex-shrink-0 min-w-[80%] snap-start p-4 bg-gray-100 rounded shadow">
              <h3 className="text-2xl font-semibold text-secondary mb-2">Documentation</h3>
              <p className="text-gray-600 mb-2">
                Comprehensive docs covering everything from wallet interactions to advanced patterns.
              </p>
              <a href="/docs" className="text-blue-500 hover:underline">
                Read more →
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectorSection;
