// TapCoinLink.js
import React from 'react';

const TapCoinLink = () => (
  <a
    href="https://polygonscan.com/token/0xA6f0BAA04FcdB4ED733990972c022a7377f35264"
    target="_blank"
    rel="noopener noreferrer"
    className="text-current" // This ensures the link inherits the current text color
  >
    <strong>TapCoin</strong>
  </a>
);

export default TapCoinLink;
