import {React, useState }  from "react";
import { BrowserProvider, Contract, formatUnits, parseUnits, parseEther } from "ethers";
import { Link } from "react-router-dom";
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../config/staking";
// Optional fallback logo if an asset doesn’t define one
import logoToken from "../logoToken.svg";

function truncateAddress(address, chars = 10) {
  if (!address) return "";
  return address.slice(0, chars) + "…" + address.slice(-chars);
}

export default function StakingNavigation({ provider, account, isConnected, networkId, switchNetwork }) {
  // Filter only assets that are active for staking
  const activeAssets = SETTINGS.STAKES.filter((asset) => asset.status === "active");
  const [contractBalance, setContractBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState("0");

  async function getBalance() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
    const TokenBalance = await TokenContract.balanceOf(account);
    const TokenDecimals = await TokenContract.decimals();
    setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

    const ContractBalance = await TokenContract.balanceOf(SETTINGS.stakingContract);
    setContractBalance(formatUnits(ContractBalance, TokenDecimals));
  }

  return (
    <section className="py-12 px-4 dark:bg-gray-900 bg-gray-100 text-gray-800">
      <div className="max-w-6xl mx-auto text-center mb-10">
        <h2 className="text-3xl font-bold text-secondary mb-4">
          Select an Asset for Staking
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          Choose the asset you want to stake and manage your stake to earn rewards.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {activeAssets.map((asset) => (
          <div
            key={asset.contractAddress}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 flex flex-col transition transform hover:-translate-y-1 hover:shadow-2xl"
          >
            <div className="flex flex-col items-center">
              <img
                src={asset.logo || logoToken}
                alt={asset.tokenSymbol}
                className="w-16 h-16 object-contain mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-1">
                {asset.name} ({asset.symbol}) Staking
              </h3>
            </div>
            <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
              {asset.description}
            </p>
            <div className="mb-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <strong>Reward Pool:</strong>{" "}
                <span className="font-mono">{truncateAddress(asset.rewardPool)}</span>
              </p>
            </div>
            <div className="mb-4">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <strong>Staking Pool:</strong>{" "}
                <span className="font-mono">{truncateAddress(asset.stakingPool)}</span>
              </p>
            </div>
            <div className="mt-auto text-center">
              <Link
                to={`/stake/${asset.contractAddress}`}
                className="inline-block bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
              >
                Manage Stake
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
