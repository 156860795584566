import React from "react";
import { cn } from "../../lib/utils";

/**
 * Base Card wrapper with consistent styling.
 * Adjust background, border radius, shadows using your theme variables.
 */
export function Card({ className, children, ...props }) {
  return (
    <div
      className={cn(
        "rounded-lg shadow-md transition-colors",
        // Use theme variable for background & text color
        "bg-[var(--card-background)] text-[var(--text-color)]",
        // Default padding (optional)
        "p-3",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

/**
 * Optional CardContent wrapper for inner spacing / layout.
 */
export function CardContent({ className, children, ...props }) {
  return (
    <div
      className={cn("p-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
