const wshareAbi = [
    {
      "inputs": [
        { "internalType": "uint256", "name": "amount", "type": "uint256" }
      ],
      "name": "purchaseWSHARE",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    }
  ];
  
  export default wshareAbi;



