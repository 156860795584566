// src/components/TokenCard.js
import React, { useState, useEffect } from "react";
import { formatUnits } from "ethers";
import ConnectButton from "./ConnectButton";
import PurchaseDisclaimerModal from "./PurchaseDisclaimerModal";

export default function TokenCard({
  token,
  connected,
  amount,
  onAmountChange,
  onBuy,
  fetchAvailableSupply,
  fetchCurrentPrice,
}) {
  const [checked, setChecked] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [available, setAvailable] = useState(null);
  const [livePrice, setLivePrice] = useState(null);

  // Fetch available supply from contract and format it using token decimals
  useEffect(() => {
    async function loadSupply() {
      if (fetchAvailableSupply) {
        const supplyRaw = await fetchAvailableSupply(token);
        // Check if the value is valid (not "unknown")
        if (supplyRaw === "unknown" || typeof supplyRaw === "undefined") {
          setAvailable("unknown");
        } else {
          setAvailable(formatUnits(supplyRaw, token.decimals));
        }
      } else {
        setAvailable("Loading...");
      }
    }
    loadSupply();
  }, [token, fetchAvailableSupply]);

  // Fetch current price from contract
  useEffect(() => {
    async function loadCurrentPrice() {
      if (fetchCurrentPrice) {
        const price = await fetchCurrentPrice(token);
        setLivePrice(price);
      }
    }
    loadCurrentPrice();
  }, [token, fetchCurrentPrice]);

  return (
    <div className="rounded shadow p-8 flex flex-col justify-between overflow-hidden bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700">
      {/* Token Logo */}
      {token.logo && (
        <img
          src={token.logo}
          alt={`${token.name} logo`}
          className="w-12 h-12 object-contain mb-2"
        />
      )}

      {/* Title & Description */}
      <h3 className="text-xl font-semibold mb-1 text-secondary dark:text-primary">
        {token.name} ({token.symbol})
      </h3>
      <p className="text-sm text-[var(--text-color)] text-justify opacity-80 mb-2">
        {token.description}
      </p>

      {/* Price, Chain, Available Supply */}
      <div className="text-sm space-y-1 mb-3 text-[var(--text-color)] opacity-80 text-justify">
        <p>
          <strong>Price per token:</strong>{" "}
          ∼ {parseFloat(livePrice || token.stablePrice).toFixed(4)} {token.nativeSymbol}
        </p>
        <p>
          <strong>Chain:</strong> {token.chainName}
        </p>
        <p>
          <strong>Available:</strong>{" "}
          ∼ {available !== null && available !== undefined ? parseFloat(available).toFixed(4) : "Loading..."}{" "}
          {token.symbol}
        </p>
      </div>


      {/* Connected State */}
      {connected ? (
        <>
          {/* Amount Input */}
          <label className="block text-sm font-medium mb-1 dark:text-gray-200">
          Enter Number of Tokens to Purchase:
          </label>
          <input
            type="number"
            placeholder="e.g. 100"
            value={amount}
            onChange={(e) => onAmountChange(e.target.value)}
            className="border border-gray-300 rounded w-full p-2 text-sm mb-3"
          />

          {/* Terms & Conditions Checkbox */}
          <div className="flex items-start mb-3 gap-2">
            <input
              type="checkbox"
              id={`terms-${token.symbol}`}
              className="mt-1 w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <label
              htmlFor={`terms-${token.symbol}`}
              className="text-sm text-[var(--text-color)]"
            >
              I agree to the{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowDisclaimer(true);
                }}
                className="text-blue-500 hover:underline"
              >
                Terms &amp; Conditions
              </a>
            </label>
          </div>

          {/* Buy Now Button */}
          <button
            onClick={onBuy}
            disabled={!checked}
            className={`px-3 py-2 mt-2 rounded text-white font-medium text-sm transition-colors ${
              checked
                ? "bg-baseviolet hover:bg-hoverviolet"
                : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            Buy Now
          </button>
        </>
      ) : (
        <div>
          <p className="text-sm text-gray-500 mb-2">
            Please connect your wallet to process.
          </p>
          <div className="flex justify-center">
            <ConnectButton />
          </div>
        </div>
      )}

      {/* Purchase Disclaimer Modal */}
      {showDisclaimer && (
        <PurchaseDisclaimerModal
          isOpen={showDisclaimer}
          onClose={() => setShowDisclaimer(false)}
          onConfirm={() => {
            setChecked(true);
            setShowDisclaimer(false);
          }}
        />
      )}
    </div>
  );
}
