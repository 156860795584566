import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/Button';
import { CheckCircle } from 'lucide-react';
import AutoImageSwitcher from '../components/AutoImageSwitcher';
import DAOHolders from '../components/DAOHolders';

const fadeUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 }
};

const standardVotingImages = [
  "https://nodes.geton.global/dao/standard1.svg",
  "https://nodes.geton.global/dao/standard2.svg",
  "https://nodes.geton.global/dao/standard3.svg",
  "https://nodes.geton.global/dao/standard4.svg",
  "https://nodes.geton.global/dao/standard5.svg",
  "https://nodes.geton.global/dao/standard6.svg",

];

const weightedVotingImages = [
  "https://nodes.geton.global/dao/weighted1.svg",
  "https://nodes.geton.global/dao/weighted2.svg",
  "https://nodes.geton.global/dao/weighted3.svg",
  "https://nodes.geton.global/dao/weighted4.svg",
  "https://nodes.geton.global/dao/weighted5.svg",
  "https://nodes.geton.global/dao/weighted6.svg",
];

export default function DAOExplainer() {
  return (
    <section className="py-12 px-6 dark:bg-bggrey text-gray-800">
      <div className="max-w-6xl mx-auto text-center mb-10">
        <h2 className="text-3xl font-bold text-secondary mb-4">
          Empower Your Voice in the DAO
        </h2>
        <p className="text-gray-600 dark:text-gray-100 mb-8">
          Choose the voting mechanism that best suits you and shape the future of our community.
        </p>
      </div>

      {/* Standard DAO Voting Block */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center max-w-6xl mx-auto"
        variants={fadeUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.6 }}
      >
        <a href="/dao-standard" target="_blank" rel="noopener noreferrer">
          <div className="p-2 bg-baseviolet rounded-lg shadow-lg">
            <AutoImageSwitcher 
              images={standardVotingImages} 
              fixedHeight="h-64 md:h-80" 
              fixedWidth="w-full" 
            />
          </div>
        </a>
        <div className="space-y-4 text-center md:text-left dark:text-gray-400">
          <h3 className="text-2xl font-semibold text-secondary">
            Standard DAO Voting
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            Participate in our standard voting mechanism to have a direct influence on community decisions.
            Every member’s vote counts equally.
          </p>
          <ul className="space-y-2">
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span>Fair, one-vote-per-member process</span>
            </li>
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span>Transparent and democratic decision-making</span>
            </li>
          </ul>
          <div>
            <a 
              href="/dao-standard" 
              className="inline-block bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-md transition-colors duration-200"
            >
              Vote Now or Submit a Proposal
            </a>
          </div>
        </div>
      </motion.div>

      {/* Weighted DAO Voting Block */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-12 max-w-6xl mx-auto"
        variants={fadeUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <div className="order-2 md:order-1 space-y-4 text-center md:text-left dark:text-gray-400">
          <h3 className="text-2xl font-semibold text-secondary">
            Weighted DAO Voting
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            Engage in a weighted voting process where your influence is based on your stake.
            This method rewards long‑term commitment and ensures decisions reflect the community’s investment.
          </p>
          <ul className="space-y-2">
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span>Vote weight determined by token holdings</span>
            </li>
            <li className="flex flex-col md:flex-row items-start text-left md:justify-start">
              <CheckCircle className="text-green-400 mr-2" />
              <span>Encourages long‑term engagement and commitment</span>
            </li>
          </ul>
          <div>
            <a 
              href="/dao-weighted" 
              className="inline-block bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-md transition-colors duration-200"
            >
              Vote Now or Submit a Proposal
            </a>
          </div>
        </div>
        <a href="/dao-weighted" target="_blank" rel="noopener noreferrer">
        <div className="order-1 md:order-2 p-2 bg-baseviolet rounded-lg shadow-lg">
          <AutoImageSwitcher 
            images={weightedVotingImages} 
            fixedHeight="h-64 md:h-80" 
            fixedWidth="w-full" 
            />
          </div>
        </a>
      </motion.div>

      <div className="mt-10 text-center">
        <a href="https://openai.com/chatgpt" target="_blank" rel="noopener noreferrer">
          <Button size="lg">Learn More About DAO Governance</Button>
        </a>
      </div>
      {/* Weighted DAO Voting Block */}
     {/* <DAOHolders />*/}
    </section>
  );
}
