// src/components/FAQSection.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const faqItems = [
  {
    question: "How do I deposit tokens?",
    answer:
      "Navigate to the Dashboard, select your asset, click 'Deposit,' and confirm via MetaMask. Off-chain credit appears in your balance."
  },
  {
    question: "What is WaveShare governance?",
    answer:
      "WaveShare governance gives you direct influence over platform decisions. With only 100 tokens in circulation, every holder benefits from profit sharing and strategic oversight."
  },
  {
    question: "How do I participate in Geton Combat?",
    answer:
      "Join Geton Combat by completing missions, engaging in treasure activities, and inviting friends. Earn TapCoins that can be migrated to unlock further rewards across the Geton ecosystem."
  }
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (index) => {
    setActiveIndex(prev => (prev === index ? null : index));
  };

  return (
    <section className="py-12 px-4 mb-4 mt-5 container mx-auto dark:bg-bggrey text-gray-800">
      <div className="flex flex-col md:flex-row">
        {/* Left Column */}
        <div className="md:w-1/3 mb-6 md:mb-0">
          <h2 className="text-2xl font-bold mb-4 text-secondary">Frequently Asked Questions</h2>
          <p className="mb-3 mr-2 dark:text-gray-300">
            Find answers to common queries about how GetonGlobal works, its liquidity tools, and more.
          </p>
          <a href="https://t.me/metacoincommunity" target="_blank" rel="noopener noreferrer">
          <button 
              className="px-3 mt-1 py-2 w-72 border border-[#0088cc] text-[#0088cc] font-semibold rounded hover:bg-[#0088cc] hover:text-white">
              Join Our Telegram
          </button>
          </a>
        </div>

        <div className="text-center text-white">

      </div>
        {/* Right Column – Accordion */}
        <div className="md:w-2/3">
          {faqItems.map((item, index) => (
            <div key={index} className="mb-4 border border-gray-200 dark:border-gray-700 rounded">
              <button
                onClick={() => toggleItem(index)}
                className="w-full text-left px-4 py-3 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none flex justify-between items-center"
              >
                <span className="font-medium text-gray-800 dark:text-gray-200">
                  {item.question}
                </span>
                <span>
                  {activeIndex === index ? (
                    <svg className="w-5 h-5 transform rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  ) : (
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  )}
                </span>
              </button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="px-4 overflow-hidden"
                  >
                    <p className="py-3 text-gray-700 dark:text-gray-300">{item.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
