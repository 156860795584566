// src/config/ponzisettings.js

const ponzisettings = {
    tokens: [
      {
        name: "GetOnPonzi",
        symbol: "GETON",
        decimals: 18,
        logo: "/tokens/geton.png", // Replace with token logo URL
        contractAddress: "0x1234567890abcdef1234567890abcdef12345678", // Token contract address
        liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
        description: "A satirical meme token for the degen community.",
      },
      {
        name: "ReflexCoin",
        symbol: "RFX",
        decimals: 18,
        logo: "/tokens/geton.png", // Replace with token logo URL
        contractAddress: "0x1234567890abcdef1234567890abcdef12345678", // Token contract address
        liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
        description: "A satirical meme token for the degen community.",
      },
      {
        name: "GreedGrinder",
        symbol: "GRND",
        decimals: 18,
        logo: "/tokens/geton.png", // Replace with token logo URL
        contractAddress: "0x1234567890abcdef1234567890abcdef12345678", // Token contract address
        liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
        description: "A satirical meme token for the degen community.",
      },
      {
        name: "FomoFurnace",
        symbol: "FOMO",
        decimals: 18,
        logo: "/tokens/geton.png", // Replace with token logo URL
        contractAddress: "0x1234567890abcdef1234567890abcdef12345678", // Token contract address
        liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
        description: "A satirical meme token for the degen community.",
      },
      {
        name: "ChaosCrumbs",
        symbol: "CHC",
        decimals: 18,
        logo: "/tokens/geton.png", // Replace with token logo URL
        contractAddress: "0x1234567890abcdef1234567890abcdef12345678", // Token contract address
        liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
        description: "A satirical meme token for the degen community.",
      },
      {
        name: "TemptationToken",
        symbol: "TEMP",
        contractAddress: "0xabcdef123456789",
        description: "Speculate responsibly (or not).",
        logo: "/tokens/cc.png",
      },
    ],
  
    token: {
      name: "GetOnPonzi",
      symbol: "GETON",
      decimals: 18,
      logo: "/tokens/geton.png",
      contractAddress: "0x612a6dc8CF9A28101F292A03f9dafBA1d83Eb5ea", // Token contract address
      liquidityContract: "0xC4E16Bd59ab5c7aCE4162B6127CE5f05d2443eF0", // Liquidity contract address
    },
  
    ponziRaces: [
      {
        name: "Master’s Madness Run",
        token:"GetOnPonzi (GETON)",
        symbol: "GETON",
        botname: "GetOnPonziMaster",
        link: "https://t.me/MadnessMaster_bot",
        contractAddress: "0x999E2CC51eb746EDa1F522CF6949c7fc8297418D",
        tokenAddress: "0x612a6dc8CF9A28101F292A03f9dafBA1d83Eb5ea",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/MastersMadness.png",
        dexLink: "https://dexscreener.com/base/0x13b5a77b116a557efbc148fa2eaa80d81e1fed5b",
        description: "Ride the original Grandmaster’s Steed of Chaos meme to glory!",
      },
      {
        name: "Reflex Sprint",
        token:"ReflexCoin (RFX)",
        symbol: "RFX",
        botname: "Reflex Coin",
        link: "https://t.me/Reflex_Sprint_bot",
        contractAddress: "0xf3AaB1C72493EaBdd12C4b353Ff71a1cE9Da1545",
        tokenAddress: "0x34b40453164392b9A39eCa6435fF44b5f6144536",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/ReflexCoin.png",
        dexLink: "https://dexscreener.com/base/0xd72a801cc7ea5b544aa2b4dd331ed6f85076afbb",
        description: "Speed and impulse define this champ—blink, and you’ll miss it!",
      },
      {
        name: "Grinder’s Grindway",
        token:"GreedGrinder (GRND)",
        symbol: "GRND",
        botname: "Greed Grinder",
        link: "https://t.me/Grinders_Grindway_bot",
        contractAddress: "0x5Adb0544c2F8468c15A1844A0232b675781CecE8",
        tokenAddress: "0x3ded7609cAd18f8a369A9C12B5587AA196EED8B2",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/GreedGrinder.png",
        dexLink: "https://dexscreener.com/base/0xb1442a43278d301916db3b733c09c948727510be",
        description: "Nonstop hustle defines this race—grind harder, claim glory!",
      },
      {
        name: "Fomo Scarcity Sprint",
        token:"FomoFurnace (FOMO)",
        symbol: "FOMO",
        botname: "Fomo Furnace",
        link: "https://t.me/Fomo_Scarcity_Sprint_bot",
        contractAddress: "0x9163370440dce10dC07078b1845Ea2F65715691a",
        tokenAddress: "0x43Cd6E6820F2B1d3164Cfc3598ED6A89fa628E60",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/FomoFurnace.png",
        dexLink: "https://dexscreener.com/base/0xfb32ce17e11881e11ec85edd2b08831d5144978b",
        description: "A fiery racer fueled by FOMO—speed and scarcity collide!",
      },
      {
        name: "Chaos Circuit",
        token:"ChaosCrumbs (CHC)",
        symbol: "CHC",
        botname: "Chaos Crumbs",
        link: "https://t.me/Chaos_Circuit",
        contractAddress: "0xfC02CD7c1A1781128614874478F48EEC8947C59D",
        tokenAddress: "0x46D6C9d06FC513544349252e8Eb65d8DF7715365",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/ChaosCrumbs.png",
        dexLink: "https://dexscreener.com/base/0x000939dbc7defcfd0fa895a7edb6e29949b07ec9",
        description: "Tiny bites of madness fuel this scrappy underdog—nonstop!",
      },
      {
        name: "Temptation Pathway",
        token:"TemptationToken (TEMP)",
        symbol: "TEMP",
        botname: "Temptation Token",
        link: "https://t.me/Temptation_Pathway_bot",
        contractAddress: "0x0C5446Bbcd5aa35804a3FbA3F7067A64810f1c2b",
        tokenAddress: "0x729B3E061637D5360c16ea89b8999e1E48C2DEA1",
        devFee: "0.0003",
        betPrice: "10000",
        image: "/memes/TemptationToken.png",
        dexLink: "https://dexscreener.com/base/0x118e5572e617affdf659665d55064e47e803bb0b",
        description: "Luxury and exclusivity allure this diva of the tracks—stay inside!",
      },
    ],
  
    socialLinks: {
      telegram: "https://t.me/GetOnPonzi",
      twitter: "https://twitter.com/GetOnPonzi",
    },
  
    sliderImages: [
      {
        src: "/slider/ElephantOfEternalRiches.png",
        name: "Elephant of Eternal Riches",
        link: "https://t.me/ElephantOfEternalRiches_bot",
      },
      {
        src: "/slider/FoxyPyramidHustler.png",
        name: "Foxy Pyramid Hustler",
        link: "https://t.me/FoxyPyramidHustler_bot",
      },
      {
        src: "/slider/MLMMosquito.png",
        name: "Mosquito of MLM Mayhem",
        link: "https://t.me/MosquitoOfMLMMayhem_bot",
      },
      {
        src: "/slider/MonkeyMoneyFlipper.png",
        name: "Monkey Money Flipper",
        link: "https://t.me/MonkeyMoneyFlipper_bot",
      },
      {
        src: "/slider/TheHourglassProphet.png",
        name: "Hourglass Prophet",
        link: "https://t.me/TheHourglassProphet_bot",
      },
      {
        src: "/slider/WolfOfPonziStreet.png",
        name: "Wolf of Ponzi Street",
        link: "https://t.me/WolfOfPonziStreet_bot",
      },
      {
        src: "/slider/TheOracleOfPumpedDreams.png",
        name: "Oracle of Pumped Dreams",
        link: "https://t.me/TheOracleOfPumpedDreams_bot",
      },
      // Add more slider items as needed
    ],
  };
  
  export default ponzisettings;
  