// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// 1) Load Bootstrap first
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// 2) Load the Tailwind entry
import './styles/tailwind.css';

// 3) Then load your custom styles (App.css)
import './App.css';

// Reown appKit config, if needed
import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { bsc, polygon, base } from '@reown/appkit/networks';
import settings from './config/settings';

const projectId = '3676ba915a4f731f3e7694abf84e3cd1'
const networks = [bsc, polygon, base];
const metadata = {
  name: 'Geton',
  description: 'GetonGlobal - Sustainability by Crypto Renewal',
  url: 'https://geton.global',
  icons: ['https://nodes.geton.global/logos/logo.png']
};
createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true,
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
