// src/components/ShareholdingDashboard.js

import React, { useState, useEffect } from "react";
import { Contract, parseEther, formatEther } from "ethers";
import { Pie, Line } from "react-chartjs-2";
import "chart.js/auto";

import dynamicPriceShareABI from "../abis/WSHAREGovernanceABI.json";
import { Button } from "./ui/Button";
import { Card, CardContent } from "./ui/Card";
import Input from "./ui/Input";

// The "PurchaseWSHAREDisclaimerModal" from your snippet
import PurchaseWSHAREDisclaimerModal from "./PurchaseWSHAREDisclaimerModal";

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-900 p-4 rounded shadow-lg max-w-sm w-full relative">
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 float-right"
        >
          &times;
        </button>
        <div className="clear-both" />
        {children}
      </div>
    </div>
  );
}

const blockExplorerUrl = "https://polygonscan.com/tx/";
const CONTRACT_ADDRESS = "0x0dfaB0448dF1dfe6d075b0335121408D550947CA";

function ShareholdingDashboard({ signer }) {
  const [globalStats, setGlobalStats] = useState({
    currentPrice: "",
    lastPurchasePrice: "",
    totalVolumePurchased: "",
    totalHolders: ""
  });
  const [userStats, setUserStats] = useState({
    userShares: "",
    userSalesRewards: "",
    userSystemRewards: ""
  });

  // Modal states
  const [isPurchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [isClaimModalOpen, setClaimModalOpen] = useState(false);

  // Purchase input & disclaimers
  const [maticToSpend, setMaticToSpend] = useState("");
  const [walletBalance, setWalletBalance] = useState("");
  const [estimatedShares, setEstimatedShares] = useState("");
  const [tcChecked, setTcChecked] = useState(false);
  const [isDisclaimerOpen, setDisclaimerOpen] = useState(false);

  // Reinvest logic
  const [isReinvesting, setIsReinvesting] = useState(false);

  // Loader / success transaction
  const [isBuying, setIsBuying] = useState(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState(null);

  // Ethers contract instance
  const contract = signer
    ? new Contract(CONTRACT_ADDRESS, dynamicPriceShareABI, signer)
    : null;

  // Historical data from PriceHistory events (for line chart)
  const [historicalData, setHistoricalData] = useState([]);

  // On mount or whenever contract changes, load global/user stats and events.
  useEffect(() => {
    if (contract) {
      fetchGlobalStats();
      fetchUserStats();
      loadPriceEvents(); // Load event-based historical data
    }
  }, [contract]);

  // Also fetch POL balance from user’s wallet.
  useEffect(() => {
    async function fetchBalance() {
      if (!signer) return;
      try {
        const addr = await signer.getAddress();
        const provider = signer.provider;
        const bal = await provider.getBalance(addr);
        setWalletBalance(formatEther(bal));
      } catch (err) {
        console.error("Failed to fetch balance:", err);
      }
    }
    fetchBalance();
  }, [signer]);

  // 1. Load on-chain PriceHistory events
  async function loadPriceEvents() {
    if (!contract) return;
    try {
      const filter = contract.filters.PriceHistory();
      const startBlock = await contract.startBlock();
      const events = await contract.queryFilter(filter, startBlock, "latest");

      console.log("PriceHistory events found:", events.length);
      const tempData = events.map((e) => {
        const { timestamp, price, volume } = e.args;
        return {
          // Use Number() instead of toNumber()
          timestamp: Number(timestamp),
          price: parseFloat(formatEther(price)),
          volume: parseFloat(formatEther(volume))
        };
      });

      // Sort events by ascending timestamp.
      tempData.sort((a, b) => a.timestamp - b.timestamp);
      console.log("Parsed PriceHistory array =>", tempData);
      setHistoricalData(tempData);
    } catch (err) {
      console.error("Failed to load PriceHistory events:", err);
    }
  }

  // 2. Global stats
  async function fetchGlobalStats() {
    if (!contract) return;
    try {
      const [curPrice, lastPrice, totalVol, holders] =
        await contract.getGlobalStats();
      setGlobalStats({
        currentPrice: formatEther(curPrice),
        lastPurchasePrice: formatEther(lastPrice),
        totalVolumePurchased: formatEther(totalVol),
        totalHolders: holders.toString()
      });
    } catch (err) {
      console.error("Failed to fetch global stats:", err);
    }
  }

  // 3. User stats
  async function fetchUserStats() {
    if (!contract || !signer) return;
    try {
      const addr = await signer.getAddress();
      const [shares, sales, sys] = await contract.getUserStats(addr);
      setUserStats({
        userShares: formatEther(shares),
        userSalesRewards: formatEther(sales),
        userSystemRewards: formatEther(sys)
      });
    } catch (err) {
      console.error("Failed to fetch user stats:", err);
    }
  }

  // 4. Handle change in POL input to estimate WaveShare
  async function handleMaticChange(value) {
    setMaticToSpend(value);
    if (!contract) return;
    try {
      const curPriceWei = await contract.getCurrentPrice();
      const curPriceMatic = parseFloat(formatEther(curPriceWei));
      const typedMatic = parseFloat(value);
      if (typedMatic > 0 && curPriceMatic > 0) {
        const sharesApprox = typedMatic / curPriceMatic;
        setEstimatedShares(sharesApprox.toFixed(4));
      } else {
        setEstimatedShares("");
      }
    } catch (err) {
      console.error("Error fetching price:", err);
    }
  }

  // 5. Purchase WaveShare
  async function handlePurchase() {
    if (!maticToSpend || parseFloat(maticToSpend) <= 0) {
      alert("Enter a valid POL amount");
      return;
    }
    if (!tcChecked) {
      alert("Please agree to the Terms & Conditions first.");
      return;
    }
    if (!contract) return;

    const typedMatic = parseFloat(maticToSpend);
    const balFloat = parseFloat(walletBalance || "0");
    if (typedMatic > balFloat) {
      alert("Not enough POL in your wallet.");
      return;
    }

    try {
      setIsBuying(true);
      setPurchaseTxHash(null);

      const tx = await contract.buyShares({
        value: parseEther(maticToSpend)
      });
      await tx.wait();

      setPurchaseTxHash(tx.hash);

      // Refresh stats and event data
      setMaticToSpend("");
      setEstimatedShares("");
      await fetchGlobalStats();
      await fetchUserStats();
      await loadPriceEvents();

      setTcChecked(false); // reset T&C

      // Refresh wallet balance
      if (signer) {
        const addr = await signer.getAddress();
        const provider = signer.provider;
        const newBal = await provider.getBalance(addr);
        setWalletBalance(formatEther(newBal));
      }
    } catch (err) {
      console.error("Purchase failed or canceled:", err);
      alert("Purchase failed or canceled.");
    } finally {
      setIsBuying(false);
    }
  }

  // 6. Reinvest rewards (claim then buy)
  async function handleReinvest() {
    if (!contract) return;
    setIsReinvesting(true);
    try {
      const totalReward =
        parseFloat(userStats.userSalesRewards || "0") +
        parseFloat(userStats.userSystemRewards || "0");
      if (totalReward <= 0) {
        alert("No rewards to reinvest.");
        setIsReinvesting(false);
        return;
      }

      let tx = await contract.claimRewards();
      await tx.wait();
      await fetchUserStats();

      const addr = await signer.getAddress();
      const provider = signer.provider;
      const newBal = await provider.getBalance(addr);
      setWalletBalance(formatEther(newBal));

      const typedMatic = totalReward.toFixed(6);
      setIsBuying(true);
      setPurchaseTxHash(null);

      tx = await contract.buyShares({
        value: parseEther(typedMatic)
      });
      await tx.wait();
      setPurchaseTxHash(tx.hash);

      await fetchGlobalStats();
      await fetchUserStats();
      await loadPriceEvents();

      const finalBal = await provider.getBalance(addr);
      setWalletBalance(formatEther(finalBal));
    } catch (err) {
      console.error("Reinvest canceled or failed:", err);
      alert("Reinvest canceled or failed.");
    } finally {
      setIsBuying(false);
      setIsReinvesting(false);
    }
  }

  // 7. Claim rewards
  async function handleClaimRewards() {
    if (!contract) return;
    try {
      const tx = await contract.claimRewards();
      await tx.wait();
      alert("Rewards claimed!");
      setClaimModalOpen(false);
      await fetchUserStats();

      if (signer) {
        const addr = await signer.getAddress();
        const provider = signer.provider;
        const newBal = await provider.getBalance(addr);
        setWalletBalance(formatEther(newBal));
      }
    } catch (err) {
      console.error("Claim error:", err);
      alert("Claim failed or canceled.");
    }
  }

  function closePurchaseModal() {
    setPurchaseModalOpen(false);
    setMaticToSpend("");
    setEstimatedShares("");
    setPurchaseTxHash(null);
    setTcChecked(false);
  }

  // 8. Prepare chart data from on-chain historical events
  const lineChartData = {
    labels: historicalData.map((d) =>
      new Date(d.timestamp * 1000).toLocaleDateString()
    ),
    datasets: [
      {
        label: "WaveShare Price (POL)",
        data: historicalData.map((d) => d.price),
        yAxisID: "yPrice",
        borderColor: "#5261DD", // Tailwind baseviolet
        backgroundColor: "rgba(82, 97, 221, 1)",
        fill: false,
        tension: 0.2
      },
      {
        label: "Total Volume (POL)",
        data: historicalData.map((d) => d.volume),
        yAxisID: "yVolume",
        borderColor: "#00E898", // Tailwind basegreen
        backgroundColor: "rgba(0, 232, 152, 1)",
        fill: false,
        tension: 0.2
      }
    ]
  };
  
  const lineChartOptions = {
    responsive: true,
    scales: {
      yPrice: {
        type: "linear",
        position: "left",
        title: { display: true, text: "Price (POL)" }
      },
      yVolume: {
        type: "linear",
        position: "right",
        title: { display: true, text: "Volume (POL)" },
        grid: { drawOnChartArea: false }
      }
    }
  };
  
  // Calculate for the pie chart
  const userShareFloat = parseFloat(userStats.userShares) || 0;
  const restShareFloat = Math.max(100 - userShareFloat, 0);
  

  return (
    <section className="py-10 px-4 mb-4 container mx-auto dark:bg-bggrey text-gray-800">
      {/* Loader overlay for purchase or reinvest steps */}
      {(isBuying || isReinvesting) && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-2 text-white">
              {isReinvesting ? "Reinvesting rewards..." : "Processing purchase..."}
            </p>
          </div>
        </div>
      )}

      {/* Success overlay after purchase */}
      {purchaseTxHash && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="bg-white dark:bg-gray-900 p-4 rounded shadow-lg max-w-sm w-full relative">
            <button
              onClick={() => {
                setPurchaseTxHash(null);
                closePurchaseModal();
              }}
              className="text-gray-500 hover:text-gray-700 float-right"
            >
              &times;
            </button>
            <div className="clear-both" />
            <h2 className="text-lg font-semibold mb-4">Purchase Successful!</h2>
            <p className="text-sm mb-2">
              Thank you for purchasing WaveShare and welcome to the shareholding!
            </p>
            <p className="text-sm mb-2 break-all">
              <strong>Transaction Hash:</strong>
              <br />
              <a
                href={`${blockExplorerUrl}${purchaseTxHash}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {purchaseTxHash.slice(0, 10)}...{purchaseTxHash.slice(-10)}
              </a>
            </p>
            <Button
              className="mt-4"
              onClick={() => {
                setPurchaseTxHash(null);
                closePurchaseModal();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}

      {/* T&C Disclaimer Modal */}
      <PurchaseWSHAREDisclaimerModal
        isOpen={isDisclaimerOpen}
        onClose={() => setDisclaimerOpen(false)}
      />

      <div className="container mx-auto">
        <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
          WaveShare Dashboard
        </h2>
        <p className="text-center mb-10 text-gray-600">
          Manage your shareholding, see current price, claim rewards, and buy more WaveShare.
        </p>

        {/* Main Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Global Stats Card */}
          <Card className="flex flex-col">
            <CardContent>
              <h3 className="text-xl font-semibold mb-2 text-secondary">Global Stats</h3>
              <p>Current Price: {parseFloat(globalStats.currentPrice).toFixed(8)} POL</p>
              <p>Last Purchase Price: {parseFloat(globalStats.lastPurchasePrice).toFixed(8)} POL</p>
              <p>Total Volume Purchased: {parseFloat(globalStats.totalVolumePurchased).toFixed(2)} POL</p>
              <p>Total Holders: {globalStats.totalHolders}</p>
            </CardContent>
            <div className="p-3 mt-auto text-center">
              <Button onClick={() => setPurchaseModalOpen(true)}>Buy WaveShare</Button>
            </div>
          </Card>

          {/* My Stats Card */}
          <Card>
            <CardContent>
              <h3 className="text-xl font-semibold mb-2 text-secondary">My Stats</h3>
              <p>My WaveShare: {parseFloat(userStats.userShares).toFixed(2)} WaveShare</p>
              <p>Unclaimed Sales: {parseFloat(userStats.userSalesRewards).toFixed(2)} POL</p>
              <p>Unclaimed Fees: {parseFloat(userStats.userSystemRewards).toFixed(2)} POL</p>
              <div className="flex flex-col space-y-2 mt-4">
                <Button onClick={() => setClaimModalOpen(true)}>Claim Rewards</Button>
                <Button onClick={handleReinvest}>Reinvest Rewards</Button>
              </div>
            </CardContent>
          </Card>

          {/* Distribution Pie Card */}
          <Card className="flex flex-col items-center justify-center">
            <CardContent className="flex flex-col items-center">
              <h3 className="text-xl font-semibold mb-2 text-secondary">
                Share Distribution
              </h3>
              <div style={{ width: "200px", height: "200px" }}>
                <Pie
                  data={{
                    labels: ["My WaveShare", "Others"],
                    datasets: [
                      {
                        data: [userShareFloat, restShareFloat],
                        backgroundColor: ["#00E898", "#5261DD"], // Basegreen for "My WaveShare", Baseviolet for "Others"
                        borderColor: ["#00E898", "#5261DD"], // Darker shades for borders (Blue-600 and Gray-400)
                        borderWidth: 1
                      }
                    ]
                  }}
                />
              </div>
              <p className="text-sm mt-2 text-gray-600 dark:text-gray-300">
                You currently own {parseFloat(userStats.userShares).toFixed(2)} out of 100 total WaveShare.
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Additional Line Chart Card for Price & Volume History */}
        <div className="mt-8">
          <Card>
            <CardContent>
              <h3 className="text-xl font-semibold mb-4 text-secondary">
                WaveShare Price &amp; Volume History
              </h3>
              <div className="overflow-x-auto">
                <div className="min-w-[600px] sm:w-[600px] md:w-[800px] lg:w-full lg:h-[520px]">
                  <Line data={lineChartData} options={lineChartOptions} />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Purchase Modal */}
      <Modal isOpen={isPurchaseModalOpen && !purchaseTxHash} onClose={closePurchaseModal}>
        <h2 className="text-lg font-semibold mb-4">Buy WaveShare</h2>
        <p className="mb-2">Your Wallet Balance: {parseFloat(walletBalance).toFixed(2)}  POL</p>
        <Input
          type="number"
          placeholder="POL to spend"
          className="mb-2"
          value={maticToSpend}
          onChange={(e) => handleMaticChange(e.target.value)}
        />
        {estimatedShares && (
          <>
            <p className="text-sm mb-1 text-gray-700 dark:text-gray-300">
              Estimated WaveShare: {estimatedShares}
            </p>
            <p className="text-xs mb-2 text-gray-500">
              After purchase, total WaveShare ~{" "}
              {(
                parseFloat(userStats.userShares) +
                (parseFloat(estimatedShares) || 0)
              ).toFixed(4)}{" "}
              (
              {(
                ((parseFloat(userStats.userShares) +
                  (parseFloat(estimatedShares) || 0)) /
                  100) *
                100
              ).toFixed(2)}
              %)
            </p>
          </>
        )}
        <p className="text-xs text-gray-500 mb-4">
          Final price may change if others buy before your transaction is confirmed.
        </p>

        {/* Terms & Conditions Checkbox */}
        <div className="flex items-start mb-3 gap-2">
          <input
            type="checkbox"
            id="buy-terms"
            className="mt-1 w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            checked={tcChecked}
            onChange={(e) => setTcChecked(e.target.checked)}
          />
          <label htmlFor="buy-terms" className="text-sm text-[var(--text-color)]">
            I agree to the{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDisclaimerOpen(true);
              }}
              className="text-blue-500 hover:underline"
            >
              Terms &amp; Conditions
            </a>
          </label>
        </div>

        <Button
          onClick={handlePurchase}
          disabled={
            !tcChecked ||
            parseFloat(maticToSpend || "0") > parseFloat(walletBalance || "0")
          }
          className={`${
            !tcChecked ||
            parseFloat(maticToSpend || "0") > parseFloat(walletBalance || "0")
              ? "opacity-50 cursor-not-allowed bg-gray-400"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          Confirm Purchase
        </Button>
      </Modal>

      {/* Claim Rewards Modal */}
      <Modal isOpen={isClaimModalOpen} onClose={() => setClaimModalOpen(false)}>
        <h2 className="text-lg font-semibold mb-4">Claim Rewards</h2>
        <p className="mb-2">Sales Rewards: {userStats.userSalesRewards} POL</p>
        <p className="mb-4">System Fees: {userStats.userSystemRewards} POL</p>
        <Button onClick={handleClaimRewards}>Claim Now</Button>
      </Modal>
    </section>
  );
}

export default ShareholdingDashboard;
