// src/components/HeroSection.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <section className="flex items-center bg-gradient-to-r from-primary to-secondary min-h-[60vh] px-4 py-12">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          {/* Left Content */}
          <div className="w-full md:w-1/2 text-left">
            <h1 className="text-4xl md:text-5xl font-bold mb-3 text-white font-ubuntu">
              Sustainability Through Crypto Renewal
            </h1>
            <p className="mb-4 text-white">
              Renew – Recycle – Reward: Your Journey to Sustainable Crypto Growth Begins Here.
            </p>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start gap-4">
              <Link
                to="/nodes"
                className="bg-baseviolet hover:bg-violet-500 text-white font-semibold py-2 px-6 rounded no-underline transition text-center"
              >
                Renewal Rigs
              </Link>
              <button
                onClick={() => setIsModalOpen(true)}
                className="border border-white text-white hover:bg-basegreen hover:text-blue-600 font-semibold py-2 px-6 rounded no-underline transition text-center"
              >
                Explore Ecosystem
              </button>
            </div>
          </div>
          {/* Right Image */}
          <div className="w-full md:w-1/2 hidden md:block">
            <img
              src="https://nodes.geton.global/logos/ecosystem.svg"
              alt="Geton Ecosystem"
              className="mx-auto"
            />
          </div>
        </div>
      </section>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70">
          <div className="relative bg-white dark:bg-gray-800 p-0 rounded-lg max-w-3xl w-full mx-4">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute -top-2 -right-6 text-gray-700 hover:text-gray-900 text-2xl font-bold"
              aria-label="Close modal"
            >
              &times;
            </button>
            <div className="w-full">
              <iframe
                className="w-full h-64 md:h-96 rounded"
                src="https://www.youtube.com/embed/PODbLjWyd2E?si=bg9XoBBSXfYOutIC"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroSection;
