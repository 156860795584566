// src/services/auth.js
import axios from 'axios';
import settings from '../config/settings';

// Create an axios instance
const authAPI = axios.create({
  baseURL: settings.API_BASE_URL
});

export async function sendOTP(email) {
  return authAPI.post('/auth/send-otp', { email });
}

export async function verifyOTP(email, otp, rememberMe) {
  return authAPI.post('/auth/verify-otp', { email, otp, rememberMe });
}

