// src/pages/NodeStore.js
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import settings from '../config/settings';
import BuyNodeModal from '../components/modals/BuyNodeModal';
import { ethers, Contract, BrowserProvider } from 'ethers';
import BFSNodeABI from '../abis/BFSNode.json';
import { useAppKitProvider, useAppKitAccount, getAppKit } from '@reown/appkit/react';
import { addTransaction } from '../services/api';

function calcClaimableDays(node) {
  const nowSec = Math.floor(Date.now() / 1000);
  const endT = Math.min(nowSec, node.expT);
  const diff = endT - node.lastC;
  if (diff <= 0) return 0;
  const dayCount = Math.floor(diff / 86400);
  return dayCount > 0 ? dayCount : 0;
}

function NodeStore() {
  const { user } = useContext(UserContext);
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');

  const [activeTab, setActiveTab] = useState('store');
  // Use the node types array from settings for metadata and contract addresses
  const [nodeTypes] = useState(settings.NODE_TYPES || []);
  const [ownedNodes, setOwnedNodes] = useState([]);
  const [activeNodes, setActiveNodes] = useState([]);
  const [closedNodes, setClosedNodes] = useState([]);

  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeTypeFilter, setNodeTypeFilter] = useState("all");

  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const [isLoadingPortfolio, setIsLoadingPortfolio] = useState(false);
  const [isClaimingReward, setIsClaimingReward] = useState(false);
  const [claimTxHash, setClaimTxHash] = useState(null);

  // For the store tab, require a checkbox before enabling purchase
  const [checkedTerms, setCheckedTerms] = useState({}); // { [nodeId]: boolean }

  // Polygon mainnet chainId from settings (e.g. '0x89')
  const POLYGON_CHAINID_HEX = settings.CHAIN.chainId;

  // Filter nodes for active and expired tabs
  const activeNodesFiltered = ownedNodes.filter((n) =>
    !n.isClosed && (nodeTypeFilter === "all" || n.nType === Number(nodeTypeFilter))
  );
  const closedNodesFiltered = ownedNodes.filter((n) =>
    n.isClosed && (nodeTypeFilter === "all" || n.nType === Number(nodeTypeFilter))
  );

  useEffect(() => {
    if (activeTab === 'store') {
      // Reset store state when switching to Store tab
      setCheckedTerms({});
      setIsLoadingStore(true);
      const timeout = setTimeout(() => setIsLoadingStore(false), 500);
      return () => clearTimeout(timeout);
    } else if (activeTab === 'active' || activeTab === 'history') {
      checkPolygonAndLoadNodes();
    }
  }, [activeTab, isConnected, address]);

  async function checkPolygonAndLoadNodes() {
    if (!isConnected) {
      const appKit = getAppKit();
      if (!appKit) {
        console.error("Reown not found or not initialized.");
        return;
      }
      appKit.open();
      return;
    }
    const success = await ensurePolygonNetwork();
    if (success) {
      loadOwnedNodes();
    }
  }

  async function ensurePolygonNetwork() {
    if (!walletProvider) {
      console.error("No walletProvider from Reown");
      return false;
    }
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      if (network.chainId !== parseInt(POLYGON_CHAINID_HEX, 16)) {
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: POLYGON_CHAINID_HEX }],
        });
      }
      return true;
    } catch (err) {
      console.error("Failed to switch to Polygon:", err);
      alert("Please switch to Polygon mainnet to view your active renewal rigs.");
      return false;
    }
  }

  // Updated loadOwnedNodes: Loop over each node type, query its contract, and aggregate nodes.
  async function loadOwnedNodes() {
    if (!walletProvider) return;
    setIsLoadingPortfolio(true);
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      let arr = [];
      // Loop over each node type defined in settings.NODE_TYPES
      for (const nodeType of nodeTypes) {
        const contractAddress = nodeType.contractAddress;
        const contract = new Contract(contractAddress, BFSNodeABI, signer);
        const nodeIds = await contract.getUserNodes(address);
        for (let i = 0; i < nodeIds.length; i++) {
          const id = Number(nodeIds[i]);
          const info = await contract.nInfo(id);
          const nType = Number(info.nType);
          const expT = Number(info.expT);
          const mintT = Number(info.mintT);
          const lastC = Number(await contract.lastClaim(id));
          // Look up metadata from nodeTypes using nType; if not found, fallback to current nodeType
          const meta = nodeTypes.find((nt) => nt.id === nType) || nodeType;
          const daily = meta.dailyReward || 0;
          const lifespan = meta.lifespan || 30;
          const nowSec = Math.floor(Date.now() / 1000);
          const timeExpired = nowSec >= expT;
          const owner = await contract.nodeOwner(id);
          arr.push({
            nodeId: id,
            nType,
            mintT,
            expT,
            lastC,
            daily,
            lifespan,
            meta,
            timeExpired,
            isClosed: timeExpired,
            owner,
          });
        }
      }
      // Sort nodes so the newest (largest mintT) is first
      arr.sort((a, b) => b.mintT - a.mintT);
      setOwnedNodes(arr);
      setActiveNodes(arr.filter((n) => !n.isClosed));
      setClosedNodes(arr.filter((n) => n.isClosed));
    } catch (err) {
      console.error("loadOwnedNodes:", err);
    } finally {
      setIsLoadingPortfolio(false);
    }
  }

  async function handleClaimReward(node) {
    setIsClaimingReward(true);
    setClaimTxHash(null);
    try {
      const success = await ensurePolygonNetwork();
      if (!success) {
        setIsClaimingReward(false);
        return;
      }
      const ethersProvider = new BrowserProvider(walletProvider);
      await ethersProvider.send('eth_requestAccounts', []);
      const signer = await ethersProvider.getSigner();
      // Use the contract address from the node's metadata
      const contractAddress = node.meta.contractAddress;
      const contract = new Contract(contractAddress, BFSNodeABI, signer);
      const tx = await contract.claimReward(node.nodeId);
      await tx.wait();
      setClaimTxHash(tx.hash);
      // Reload nodes to update state
      await loadOwnedNodes();
      if (user?.id) {
        try {
          const claimedDays = calcClaimableDays(node);
          const claimed = claimedDays * node.daily;
          await addTransaction({
            user_id: user.id,
            user_email: user.email,
            tx_hash: tx.hash,
            tx_type: 'claim_reward',
            asset: settings.LIQUIDITY_TOKEN,
            amount: claimed,
            node_id: node.nodeId,
            note: node.timeExpired
              ? `final claim after expiry #${node.nodeId}`
              : `daily claim #${node.nodeId}`,
          });
        } catch (dbErr) {
          console.error("log claim_reward err:", dbErr);
        }
      }
    } catch (err) {
      console.error("Claim reward failed:", err);
      alert(`Claim error: ${err.reason || err.message}`);
    } finally {
      setIsClaimingReward(false);
    }
  }

  function openBuyModal(nTypeObj) {
    setSelectedNode(nTypeObj);
    setShowBuyModal(true);
  }

  function closeClaimModal() {
    setClaimTxHash(null);
  }

  function getLifespanProgressPercent(node) {
    const nowSec = Math.floor(Date.now() / 1000);
    const total = node.expT - node.mintT;
    const used = nowSec - node.mintT;
    let fraction = used / total;
    if (fraction > 1) fraction = 1;
    return Math.floor(fraction * 100);
  }

  function canClaimDaily(node) {
    const nowSec = Math.floor(Date.now() / 1000);
    const diff = nowSec - node.lastC;
    return diff >= 86400;
  }

  function hoursToNextClaim(node) {
    const nowSec = Math.floor(Date.now() / 1000);
    const diff = nowSec - node.lastC;
    const daySecs = 86400;
    if (diff >= daySecs) return { hours: 0, mins: 0 };
    const left = daySecs - diff;
    const hours = Math.floor(left / 3600);
    const mins = Math.floor((left % 3600) / 60);
    return { hours, mins };
  }

  return (
    <div className="relative container mx-auto py-6 px-4 dark:bg-bggrey text-gray-800 min-h-screen">
      {/* Overlays */}
      {isLoadingPortfolio && (
        <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-2 text-white">Loading Renewal Rigs ...</p>
          </div>
        </div>
      )}

      {isClaimingReward && (
        <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-2 text-white">Claiming Reward...</p>
          </div>
        </div>
      )}

      {claimTxHash && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75 bg-opacity-70">
          <div className="rounded-lg max-w-md w-full mx-4 bg-white">
            <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 dark:bg-gray-100">
              <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                Claim Successful
              </h5>
              <button
                className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                onClick={closeClaimModal}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              <p>Your claim was processed on-chain.</p>
              <p className="mt-2 break-all">
                <strong>Tx Hash:</strong>
                <a
                  href={`${settings.CHAIN.blockExplorerUrls[0]}/tx/${claimTxHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-baseviolet hover:underline"
                >
                  {claimTxHash.slice(0, 12)}...{claimTxHash.slice(-12)}
                </a>
              </p>
              <button
                className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                onClick={closeClaimModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Tabs */}
      <h2 className="text-2xl font-bold mb-4 text-secondary dark:text-primary">
        Renewal Rigs
      </h2>
      <ul className="flex space-x-4 border-b border-gray-200 dark:border-gray-700 mb-4">
        <li>
          <button
            className={`px-4 py-2 ${activeTab === 'store' ? 'border-b-2 border-baseviolet text-baseviolet' : 'text-gray-500'}`}
            onClick={() => setActiveTab('store')}
          >
            Store
          </button>
        </li>
        <li>
          <button
            className={`px-4 py-2 ${activeTab === 'active' ? 'border-b-2 border-baseviolet text-baseviolet' : 'text-gray-500'}`}
            onClick={() => setActiveTab('active')}
          >
            Active
          </button>
        </li>
        <li>
          <button
            className={`px-4 py-2 ${activeTab === 'history' ? 'border-b-2 border-baseviolet text-baseviolet' : 'text-gray-500'}`}
            onClick={() => setActiveTab('history')}
          >
            Expired
          </button>
        </li>
      </ul>

      {/* STORE TAB */}
      {activeTab === 'store' && (
        <>
          {isLoadingStore && (
            <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-black/75 bg-opacity-70">
              <div className="text-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
                <p className="mt-2 text-white">Loading Store...</p>
              </div>
            </div>
          )}
          <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-6">
            {nodeTypes.map((n) => {
              const checked = checkedTerms[n.id] || false;
              return (
                <div
                  key={n.id}
                  className="shadow-sm rounded p-4 flex flex-col text-center min-w-[300px] max-w-[320px] mx-auto dark:bg-gray-800 bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  {n.imgUrl && (
                    <img
                      src={n.imgUrl}
                      alt={n.title}
                      className="w-full h-52 mb-1.5 rounded"
                    />
                  )}
                  <h5 className="text-2xl mt-1 font-semibold text-gray-600 dark:text-gray-200">
                    {n.title}
                  </h5>
                  {n.contractAddress && (
                    <p className="text-xs text-gray-500 mt-1">
                      Contract:{" "}
                      <a
                        href={n.contractUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline ml-1"
                      >
                        {n.contractAddress.slice(0, 6)}...{n.contractAddress.slice(-4)}
                      </a>
                    </p>
                  )}
                  <p className="text-sm mt-0.5 mb-0.5 dark:text-gray-300">
                    Price: <span className="text-lg dark:text-gray-200">{n.costMatic} {settings.CHAIN.nativeCurrency.symbol}</span>
                  </p>
                  <p className="text-xs mt-1 mb-0.5 dark:text-gray-400">
                    Active BFS Structure Rewards:
                  </p>
                  <p className="text-sm mt-0.5 mb-0.5 dark:text-gray-200">
                    2 x First level: n x {n.levelOne} {settings.CHAIN.nativeCurrency.symbol}
                  </p>
                  <p className="text-sm mt-0.5 mb-0.5 dark:text-gray-200">
                    4 x Second level: n x {n.LevelTwo} {settings.CHAIN.nativeCurrency.symbol}
                  </p>
                  <p className="text-xs mt-1 mb-0.5 dark:text-gray-400">
                    Renewal Rig Liquidity Rewards:
                  </p>
                  <p className="text-sm mt-0.5 mb-0.5 dark:text-gray-200">
                    {n.lifespan} days x {n.dailyReward} {settings.LIQUIDITY_TOKEN}
                  </p>
                  <label className="flex items-start mb-2 mt-3 space-x-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checked}
                      onChange={() =>
                        setCheckedTerms((prev) => ({ ...prev, [n.id]: !checked }))
                      }
                    />
                    <span className="text-[10px] text-gray-600 dark:text-gray-400">
                      I agree that my {n.title} Renewal Rig will participate in the BFS Structure Rewards system, receiving rewards in {settings.CHAIN.nativeCurrency.symbol}, and will generate daily Liquidity Rewards in {settings.LIQUIDITY_TOKEN} for {n.lifespan} days.
                    </span>
                  </label>
                  <button
                    className={`px-3 py-2 mt-2 rounded text-white tc-btn text-sm font-medium transition-colors ${
                      checked
                        ? 'bg-baseviolet hover:bg-hoverviolet'
                        : 'bg-gray-400 cursor-not-allowed'
                    }`}
                    onClick={() => checked && openBuyModal(n)}
                    disabled={!checked}
                  >
                    Start {n.title} Renewal Rig
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}

      {/* ACTIVE TAB */}
      {activeTab === 'active' && (
        <div>
          <h4 className="text-xl font-semibold mb-4 text-secondary dark:text-primary">
            Active Renewal Rigs
          </h4>
          <div className="flex flex-col sm:flex-row sm:justify-end items-center mb-4">
            <label className="text-sm mr-2 dark:text-gray-200">Node Type:</label>
            <select
              value={nodeTypeFilter}
              onChange={(e) => setNodeTypeFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded text-sm"
            >
              <option value="all">All</option>
              {nodeTypes.map((nt) => (
                <option key={nt.id} value={nt.id}>
                  {nt.title}
                </option>
              ))}
            </select>
          </div>
          {activeNodesFiltered.length === 0 ? (
            <p>No active renewal rigs.</p>
          ) : (
            <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-6">
              {activeNodesFiltered.map((node) => {
                const dailyProg = getLifespanProgressPercent(node);
                const dayCount = calcClaimableDays(node);
                const claimable = dayCount * node.daily;
                let label;
                if (dayCount > 0) {
                  label = `Claim ${claimable} ${settings.LIQUIDITY_TOKEN}`;
                } else {
                  const { hours, mins } = hoursToNextClaim(node);
                  label = `Claim in ${hours}h ${mins}m`;
                }
                return (
                  <div
                    key={node.nodeId}
                    className="min-w-[300px] max-w-[320px] mx-auto shadow-sm rounded p-4 text-center dark:bg-gray-800 bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    {node.meta?.imgUrl && (
                      <img
                        src={node.meta.imgUrl}
                        alt={node.meta.title ?? `NodeType${node.nType}`}
                        className="w-full h-52 mb-2 rounded"
                      />
                    )}
                    <h6 className="text-sm font-medium dark:text-gray-200">ID: {node.nodeId}</h6>
                    <p className="text-xs text-gray-500 mt-1">
                      Owner: {node.owner.slice(0, 6)}...{node.owner.slice(-4)}
                    </p>
                    <h5 className="text-lg font-semibold text-secondary dark:text-primary">
                      {node.meta?.title || `NodeType${node.nType}`}
                    </h5>
                    <p className="text-sm mt-1 dark:text-gray-200">Daily: {node.daily} {settings.LIQUIDITY_TOKEN}</p>
                    <p className="text-sm dark:text-gray-200">
                      Expires: {new Date(node.expT * 1000).toLocaleString()}
                    </p>
                    <div className="mt-2">
                      <div className="w-full bg-gray-200 rounded h-2">
                        <div
                          className="bg-green-500 h-2 rounded"
                          style={{ width: `${dailyProg}%` }}
                        ></div>
                      </div>
                      <small className="text-xs block mt-1 dark:text-gray-200">
                        {dailyProg}% of total lifespan
                      </small>
                    </div>
                    <button
                      className={`mt-3 px-3 py-2 text-white text-sm rounded ${
                        dayCount > 0 ? 'bg-baseviolet hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
                      }`}
                      onClick={() => dayCount > 0 && handleClaimReward(node)}
                      disabled={dayCount === 0}
                    >
                      {label}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* HISTORY TAB */}
      {activeTab === 'history' && (
        <div>
          <h4 className="text-xl font-semibold mb-4 text-secondary dark:text-primary">
            Expired Renewal Rigs
          </h4>
          <div className="flex flex-col sm:flex-row sm:justify-end items-center mb-4">
            <label className="text-sm mr-2 dark:text-gray-200">Node Type:</label>
            <select
              value={nodeTypeFilter}
              onChange={(e) => setNodeTypeFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded text-sm"
            >
              <option value="all">All</option>
              {nodeTypes.map((nt) => (
                <option key={nt.id} value={nt.id}>
                  {nt.title}
                </option>
              ))}
            </select>
          </div>
          {closedNodesFiltered.length === 0 ? (
            <p>No closed renewal rigs.</p>
          ) : (
            <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-6">
              {closedNodesFiltered.map((node) => {
                const dayCount = calcClaimableDays(node);
                const claimable = dayCount * node.daily;
                let label;
                if (dayCount > 0) {
                  label = `Final Claim ${claimable} ${settings.LIQUIDITY_TOKEN}`;
                } else {
                  label = 'No more rewards';
                }
                return (
                  <div
                    key={node.nodeId}
                    className="min-w-[300px] max-w-[320px] mx-auto shadow-sm rounded p-4 text-center dark:bg-gray-800 bg-[var(--card-background)] hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    {node.meta?.imgUrl && (
                      <img
                        src={node.meta.imgUrl}
                        alt={node.meta.title ?? `NodeType${node.nType}`}
                        className="w-full h-52 mb-2 rounded"
                      />
                    )}
                    <h6 className="text-sm font-medium dark:text-gray-200">ID: {node.nodeId}</h6>
                    <h5 className="text-lg font-semibold text-secondary dark:text-primary">
                      {node.meta?.title || `NodeType${node.nType}`}
                    </h5>
                    <p className="text-sm text-red-500">Node Expired</p>
                    <p className="text-xs mb-2 dark:text-gray-200">
                      Expired at: {new Date(node.expT * 1000).toLocaleString()}
                    </p>
                    {dayCount > 0 && (
                      <button
                        className="bg-baseviolet hover:bg-basehover text-sm px-3 py-2 rounded"
                        onClick={() => handleClaimReward(node)}
                      >
                        {label}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* BUY MODAL */}
      {showBuyModal && selectedNode && (
        <BuyNodeModal node={selectedNode} onClose={() => setShowBuyModal(false)} loadOwnedNodes={loadOwnedNodes} />
      )}
    </div>
  );
}

export default NodeStore;
