// src/context/UserContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { sendOTP, verifyOTP } from '../services/auth';
import { getDashboardData } from '../services/api'; // We'll call getDashboardData here
import useIdleTimer from '../hooks/useIdleTimer';
import InactivityModal from '../components/modals/InactivityModal';

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  // Off-chain balances stored here
  const [userBalances, setUserBalances] = useState({});

  // Inactivity states
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [timeLeftMs, setTimeLeftMs] = useState(0);

  // On mount, retrieve user from localStorage
  useEffect(() => {
    const savedUser = localStorage.getItem('dappUser');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  // Whenever user changes, store in localStorage or remove
  useEffect(() => {
    if (user) {
      localStorage.setItem('dappUser', JSON.stringify(user));
    } else {
      localStorage.removeItem('dappUser');
      setUserBalances({});
    }
  }, [user]);

  /**
   * -------------- BALANCES LOGIC --------------
   * We'll call getDashboardData() and read data.offChain to fill userBalances.
   */
  async function refreshUserBalances() {
    if (!user?.token) return;
    try {
      // Example: you already have getDashboardData() which returns { data.offChain: {...} }
      const { data } = await getDashboardData();
      if (data.offChain) {
        setUserBalances(data.offChain); 
      }
    } catch (error) {
      console.error('Failed to fetch dashboard data for balances:', error);
    }
  }

  // Optionally, whenever user logs in, fetch the balances once
  useEffect(() => {
    if (user?.token) {
      refreshUserBalances();
    }
  }, [user]);

  // OTP login flow
  async function requestOTP(email) {
    try {
      setLoading(true);
      await sendOTP(email);
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
      throw err;
    }
  }

  async function loginWithOTP(email, code, rememberMe = false) {
    try {
      setLoading(true);
      // Include the rememberMe flag in your request
      const result = await verifyOTP(email, code, rememberMe);
      setUser({ email, token: result.data.token });
      setLoading(false);
  
      // After successful login, fetch balances
      refreshUserBalances();
  
      return result.data;
    } catch (err) {
      setLoading(false);
      throw err;
    }
  }
  

  function logout() {
    setUser(null);
    localStorage.removeItem('dappUser');
    setUserBalances({});
    window.location.reload();
  }

  // -------------- Idle Timer Integration --------------
  const onTimeout = useCallback(() => {
    if (user?.token) {
      logout();
    }
  }, [user]);

  const onShowWarning = useCallback((msLeft) => {
    if (user?.token) {
      setShowInactivityModal(true);
      setTimeLeftMs(msLeft);
    }
  }, [user]);

  const onHideWarning = useCallback(() => {
    setShowInactivityModal(false);
  }, []);

  useIdleTimer({
    onTimeout,
    onShowWarning,
    onHideWarning,
    isActive: !!user?.token
  });

  function handleStayActive() {
    setShowInactivityModal(false);
  }

  function handleLogoutNow() {
    logout();
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        userBalances,         // <--- Provide balances to children
        refreshUserBalances,  // <--- Provide refresh function
        requestOTP,
        loginWithOTP,
        logout,
      }}
    >
      {children}

      <InactivityModal
        show={showInactivityModal}
        secondsLeft={timeLeftMs}
        onStayActive={handleStayActive}
        onLogout={handleLogoutNow}
      />
    </UserContext.Provider>
  );
}
