import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { BrowserProvider } from "ethers"; // Ethers v6 import
import TokenPurchaseModal from "./TokenPurchaseModal";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register required components
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const DualAxisChart = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  // Default to light mode font color: text-gray-900 (#1a202c)
  const [fontColor, setFontColor] = useState("#1a202c");

  // On mount, detect if dark mode is active (Tailwind sets "dark" on <html>)
  useEffect(() => {
    const isDarkMode = document.documentElement.classList.contains("dark");
    setFontColor(isDarkMode ? "#f7fafc" : "#1a202c");
  }, []);

  // Function to generate smooth data points
  const generateSmoothData = (start, end, totalPoints, curveType) => {
    const data = [];
    for (let i = 0; i <= totalPoints; i++) {
      const progress = i / totalPoints;
      if (curveType === "price") {
        data.push(100 * (1 - Math.exp(-5 * progress))); // Smooth increasing curve
      } else if (curveType === "reward") {
        data.push(100 * Math.exp(-5 * progress)); // Smooth decreasing curve
      }
    }
    return data;
  };

  // Network parameters for Base Mainnet
  const BASE_NETWORK_PARAMS = {
    chainId: "0x2105", // Base Mainnet
    chainName: "Base",
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  };

  // Generate smooth data for 500 X-axis points
  const totalPoints = 500;
  const labels = Array.from({ length: totalPoints + 1 }, (_, i) => (i / 5).toFixed(1)); // X-axis labels
  const priceData = generateSmoothData(0, 100, totalPoints, "price");
  const rewardData = generateSmoothData(0, 100, totalPoints, "reward");

  const data = {
    labels,
    datasets: [
      {
        label: "Price Curve (Initial to Target)",
        data: priceData,
        borderColor: "#3B82F6",
        backgroundColor: "#3B82F6",
        yAxisID: "y-axis-price",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: "Reward Curve (100% to 0%)",
        data: rewardData,
        borderColor: "#1BC47D",
        backgroundColor: "#1BC47D",
        yAxisID: "y-axis-reward",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: { color: fontColor },
      },
      title: {
        display: true,
        text: "Price and Reward Curves",
        color: fontColor,
      },
    },
    scales: {
      x: {
        ticks: { color: fontColor },
        title: { display: true, text: "Progress (%)", color: fontColor },
      },
      "y-axis-price": {
        position: "left",
        title: { display: true, text: "Price Curve (%)", color: "#3B82F6" },
        ticks: { color: "#3B82F6" },
      },
      "y-axis-reward": {
        position: "right",
        title: { display: true, text: "Reward Curve (%)", color: "#1BC47D" },
        ticks: { color: "#1BC47D" },
        grid: { drawOnChartArea: false },
      },
    },
  };

  // Check Network and Switch if Necessary
  const checkAndSwitchNetwork = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask to use this feature!");
      return;
    }
  
    try {
      const provider = new BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();
  
      // If not Base Network, prompt user to switch
      if (network.chainId !== parseInt(BASE_NETWORK_PARAMS.chainId, 16)) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
        });
      }
  
      setIsModalOpen(true);
      setIsClicked(true);
    } catch (error) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [BASE_NETWORK_PARAMS],
        });
      } else {
        console.error(error);
        alert("Failed to switch network. Please try again.");
      }
    }
  };

  return (
    <div className="max-w-xl mx-auto text-center py-10 px-4 mb-3 container">
      <h2 className="py-8 text-3xl font-bold text-center mt-3 text-secondary">
        DPMC @ GetonGlobal
      </h2>
      <p className="text-sm text-secondary dark:text-gray-100 mb-10">
        Dynamic Price Modeling Concept presented by the Price Curve and Reward Curve.
        <br />
        The Price Curve increases from 0% to 100%, while the Reward Curve decreases from 20% to 0%, 
        reflecting mirrored performance dynamics.
      </p>
      <div className="w-full h-96">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default DualAxisChart;
