// src/components/PonziRaceCard.js
import React, { useState, useEffect } from "react";
import { BrowserProvider, JsonRpcProvider, Contract, parseEther, formatEther } from "ethers";
import { useAppKitProvider, useAppKitAccount } from "@reown/appkit/react";
import ConnectButton from "./ConnectButton"; // Ensure this component is implemented or imported correctly
import ponziRaceABI from "../abis/PonziRace.json";
import erc20ABI from "../abis/ERC20.json";

function PonziRaceCard({ raceConfig }) {
  // Reown hooks
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  // Local state
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");

  // Public, read-only provider for bet data
  const [publicProvider] = useState(
    () => new JsonRpcProvider(raceConfig.rpcUrl || "https://mainnet.base.org")
  );

  // On-chain references
  const [ponziRaceContract, setPonziRaceContract] = useState(null);
  const [erc20Contract, setErc20Contract] = useState(null);

  // Display states
  const [status, setStatus] = useState("");
  const [ethBalance, setEthBalance] = useState("0.000000");
  const [tokenBalance, setTokenBalance] = useState("0.000000");
  const [currentBetsCount, setCurrentBetsCount] = useState(0);
  const [maxBets, setMaxBets] = useState(0);
  const [isApproved, setIsApproved] = useState(false);

  // Public data: fetch bet count & maxBets
  useEffect(() => {
    async function fetchPublicData() {
      try {
        const readOnlyContract = new Contract(
          raceConfig.contractAddress,
          ponziRaceABI,
          publicProvider
        );
        const fetchedMaxBets = await readOnlyContract.maxBets();
        setMaxBets(Number(fetchedMaxBets));

        const countBN = await readOnlyContract.getCurrentBetsLength();
        setCurrentBetsCount(Number(countBN));
      } catch (err) {
        console.error("Public fetch bet data error:", err);
        setStatus("⚠️ Failed to fetch bet data from public RPC.");
        setTimeout(fetchPublicData, 5000);
      }
    }
    fetchPublicData();
  }, [raceConfig.contractAddress, publicProvider]);

  // Set up user provider if connected
  useEffect(() => {
    if (isConnected && walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      setProvider(ethersProvider);
      setWalletAddress(address);
    } else {
      setProvider(null);
      setWalletAddress("");
    }
  }, [isConnected, walletProvider, address]);

  // Initialize contracts when provider and wallet address are available
  useEffect(() => {
    async function initUserContracts() {
      if (!provider || !walletAddress) return;
      try {
        const tempSigner = await provider.getSigner();
        setSigner(tempSigner);

        // Create Race Contract with signer
        const ponzi = new Contract(raceConfig.contractAddress, ponziRaceABI, tempSigner);
        setPonziRaceContract(ponzi);

        // Create Token Contract with signer
        const erc20 = new Contract(raceConfig.tokenAddress, erc20ABI, tempSigner);
        setErc20Contract(erc20);

        // Refresh maxBets from user contract
        const fetchedMaxBets = await ponzi.maxBets();
        setMaxBets(Number(fetchedMaxBets));

        // Check user allowance
        const allowance = await erc20.allowance(walletAddress, raceConfig.contractAddress);
        setIsApproved(allowance.gte(parseEther(raceConfig.betPrice)));

        // Refresh bet count from user contract
        const betCountBN = await ponzi.getCurrentBetsLength();
        setCurrentBetsCount(Number(betCountBN));

        setStatus(`🚀 “${raceConfig.name}” is ready for your bets!`);
      } catch (err) {
        console.error("Error initializing contracts:", err);
        setStatus("🏁 Your Downline is getting ready for action!");
      }
    }
    initUserContracts();
  }, [provider, walletAddress, raceConfig]);

  // Fetch user ETH balance
  useEffect(() => {
    if (provider && walletAddress) {
      fetchEthBalance();
    }
  }, [provider, walletAddress]);

  // Fetch token balance if token contract is available
  useEffect(() => {
    if (erc20Contract && walletAddress) {
      fetchTokenBalance();
    }
  }, [erc20Contract, walletAddress]);

  async function fetchEthBalance() {
    try {
      const balance = await provider.getBalance(walletAddress);
      setEthBalance(parseFloat(formatEther(balance)).toFixed(6));
    } catch (err) {
      console.error("Error fetching ETH balance:", err);
      setStatus("⚠️ Failed to fetch ETH balance.");
    }
  }

  async function fetchTokenBalance() {
    try {
      const rawBal = await erc20Contract.balanceOf(walletAddress);
      const formattedBal = parseFloat(formatEther(rawBal)).toFixed(6);
      setTokenBalance(formattedBal);
    } catch (err) {
      console.error("Error fetching token balance:", err);
      setTokenBalance("0.000000");
    }
  }

  // Approve contract to spend tokens
  async function approveContract() {
    if (!erc20Contract) {
      setStatus("🚫 Token contract not available. Connect your wallet first.");
      return;
    }
    try {
      const approvalAmount = "10000"; // 1 bet amount
      const tx = await erc20Contract.approve(raceConfig.contractAddress, parseEther(approvalAmount));
      setStatus(
        <>
          🔄 Approving your tokens... <br />
          TX Hash:{" "}
          <a
            href={`https://basescan.org/tx/${tx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-400 underline"
          >
            {tx.hash.slice(0, 9)}...{tx.hash.slice(-9)}
          </a>
        </>
      );
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setStatus(
          <>
            ✅ Approval successful for {approvalAmount} tokens! <br />
            TX Hash:{" "}
            <a
              href={`https://basescan.org/tx/${tx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-400 underline"
            >
              {tx.hash.slice(0, 9)}...{tx.hash.slice(-9)}
            </a>
            <br />
            Place your bet now.
          </>
        );
        setIsApproved(true);
      } else {
        setStatus("⚠️ Approval transaction might have failed on-chain.");
      }
    } catch (err) {
      if (err.code === 4001 || err.code === "ACTION_REJECTED") {
        setStatus("⚠️ Approval failed: user rejected action");
      } else {
        setStatus("⚠️ Approval failed: unknown error. Please try again.");
      }
    }
  }

  // Place bet
  async function placeBet(option) {
    if (!ponziRaceContract || !signer) {
      setStatus("🚫 Contract or signer not set. Connect your wallet first.");
      return;
    }
    try {
      const devFeeWei = parseEther(raceConfig.devFee || "0.0003");
      const tx = await ponziRaceContract.placeBet(option, { value: devFeeWei });
      setStatus(
        <>
          🎲 Placing your bet... <br />
          TX Hash:{" "}
          <a
            href={`https://basescan.org/tx/${tx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-400 underline"
          >
            {tx.hash.slice(0, 9)}...{tx.hash.slice(-9)}
          </a>
        </>
      );
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setStatus(
          <>
            🎉 Bet placed successfully! <br />
            TX Hash:{" "}
            <a
              href={`https://basescan.org/tx/${tx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-400 underline"
            >
              {tx.hash.slice(0, 9)}...{tx.hash.slice(-9)}
            </a>
            <br />
            Refreshing the page...
          </>
        );
        setTimeout(() => window.location.reload(), 3000);
      } else {
        setStatus("⚠️ Bet transaction might have failed on-chain.");
      }
    } catch (err) {
      console.error("Bet error:", err);
      if (err.code === 4001) {
        setStatus("🛑 Bet canceled by user!");
      } else {
        setStatus("⚠️ Bet failed. Possibly need more approval or check token/ETH balance.");
      }
    }
  }

  // Determine balance color based on ETH balance vs dev fee
  const balanceColor =
    walletAddress && parseFloat(ethBalance) < parseFloat(raceConfig.devFee)
      ? "text-red-500"
      : "text-green-500";

  return (
    <div className="p-4 m-4 rounded-lg border border-gray-700 shadow-lg bg-gray-800 text-gray-200 max-w-xs w-full">
      {raceConfig.image && (
        <img
          src={raceConfig.image}
          alt={`${raceConfig.name} Logo`}
          className="mx-auto mb-2 w-56 rounded-lg"
        />
      )}

      <p className="text-center text-sm font-normal mt-2 text-[#e922ff]">
        <a
          href={raceConfig.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#e922ff] no-underline"
        >
          {raceConfig.botname}
        </a>
      </p>

      <h3 className="text-center text-lg font-bold">{raceConfig.name}</h3>

      {raceConfig.description && (
        <p className="text-center font-bold mt-2 text-[#e922ff] text-base">
          {raceConfig.description}
        </p>
      )}

      <div className="text-xs italic mt-2">
        <span>Compensation Plan: </span>
        <a
          href={`https://basescan.org/address/${raceConfig.contractAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-400 underline font-bold"
        >
          {raceConfig.contractAddress.slice(0, 8)}...{raceConfig.contractAddress.slice(-8)}
        </a>
      </div>

      <div className="text-xs italic">
        <span>Pyramid Token: </span>
        <a
          href={`https://basescan.org/token/${raceConfig.tokenAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-400 underline font-bold"
        >
          {raceConfig.tokenAddress.slice(0, 9)}...{raceConfig.tokenAddress.slice(-9)}
        </a>
        <br />
        <span>Pyramid Token Balance: </span>
        <strong>
          {tokenBalance} {raceConfig.symbol}
        </strong>
        <br />
        <span>Bet Price: </span>
        <strong>
          {raceConfig.betPrice} {raceConfig.symbol}
        </strong>
        <br className="my-1" />
        <span>ETH Balance: </span>
        <strong className={balanceColor}>{ethBalance} ETH</strong>
        <br />
        <span>Bet Placement Fee: </span>
        <strong>{raceConfig.devFee} ETH</strong>
        {raceConfig.dexLink && (
          <div className="mt-2 text-center">
            <a
              href={raceConfig.dexLink}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-xl text-green-400 no-underline"
            >
              🚀 Buy / Swap on DEX
            </a>
          </div>
        )}
      </div>

      <p className="mt-2 text-sm">
        <strong>Wallet: </strong>
        {walletAddress
          ? `${walletAddress.slice(0, 9)}...${walletAddress.slice(-9)}`
          : "Not connected"}
      </p>
      <p className="mt-1 text-sm">
        <span>Active Downlines on Stage: </span>
        <strong className="font-semibold">
          {currentBetsCount} / {maxBets}
        </strong>
        <br />
        <strong className="text-violet-500">
          Every <span className="text-yellow-500">'th</span> Downline Unlocks the Cycle!
        </strong>
      </p>

      {/* Connect or Approve / Bet */}
      {!isConnected ? (
        <div className="flex justify-center mt-4">
          <ConnectButton />
        </div>
      ) : !isApproved ? (
        <button
          onClick={approveContract}
          className="w-full mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Approve Bet
        </button>
      ) : (
        <div className="mt-4">
          <p className="text-yellow-300 text-sm mb-2">
            Who will dominate the Ponzi race?
            <br />
            Choose your scheme - <b>Binary</b> or <b>Unilevel</b> - and see who reaches the top!
          </p>
          <div className="flex gap-3 justify-center">
            <button
              onClick={() => placeBet(1)}
              className="text-white px-4 py-2 rounded transition-colors bg-green-600 hover:bg-green-700 animate-pulse"
            >
              Binary Plan
            </button>
            <button
              onClick={() => placeBet(0)}
              className="text-white px-4 py-2 rounded transition-colors bg-teal-500 hover:bg-teal-600 animate-pulse"
              style={{ animationDelay: "0.75s" }}
            >
              Unilevel Plan
            </button>
          </div>
        </div>
      )}

      <div className="mt-4 text-sm">
        <strong>Status:</strong> {status}
      </div>
    </div>
  );
}

export default PonziRaceCard;
