import React, { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';

function OTPModal({ show, onClose }) {
  const { requestOTP, loginWithOTP, loading } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [rememberMe, setRememberMe] = useState(false); // New flag for persistent login

  async function handleRequestOTP() {
    if (!email) return;
    try {
      await requestOTP(email);
      setStep(2);
    } catch (error) {
      alert('Failed to send OTP. Check console.');
      console.error(error);
    }
  }

  async function handleVerifyOTP() {
    if (!otp) return;
    try {
      // Pass the rememberMe flag along with email and OTP
      await loginWithOTP(email, otp, rememberMe);
      alert('Logged in successfully!');
      onClose();
    } catch (error) {
      alert('OTP verification failed.');
      console.error(error);
    }
  }

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/75 bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
          <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-100">OTP Login</h5>
          <button onClick={onClose} className="text-gray-600 dark:text-gray-300 focus:outline-none">
            ×
          </button>
        </div>
        <div className="p-4">
          {step === 1 && (
            <div>
              <label className="block mb-2">Email:</label>
              <input
                type="email"
                className="w-full p-2 border rounded mb-3 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <button
                onClick={handleRequestOTP}
                className="w-full bg-secondary text-white py-2 rounded hover:bg-primary transition"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Request OTP'}
              </button>
            </div>
          )}
          {step === 2 && (
            <div>
              <label className="block mb-2">OTP Code:</label>
              <input
                type="text"
                className="w-full p-2 border rounded mb-3 focus:outline-none"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                autoFocus
              />
              {/*<div className="mb-3 flex items-center">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  Remember me (stay logged for 24 h).
                </label> 
              </div>*/}
              <button
                onClick={handleVerifyOTP}
                className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition"
                disabled={loading}
              >
                {loading ? 'Verifying...' : 'Verify OTP'}
              </button>
            </div>

          )}
        </div>
      </div>
    </div>
  );
}

export default OTPModal;
